import { intArraysEqual } from '../pages/common/utils';
import { getOrCreateApiGateway } from '../server/apiGateway';
import type { ScheduleFilter, SummaryRecord } from '../types';
import { getOrCreateAnalytics } from '../utils/analytics';
import history from '../utils/history';
import { getOrCreateLogger } from '../utils/logger';
import { type AuthStore } from './authStore';
import NavinaCache from './cache';
import { observable, action, configure, autorun, runInAction } from 'mobx';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import { useContext, createContext } from 'react';

configure({ enforceActions: 'always' });

type ClinicsDepartmentsProvidersType = {
	clinics: { id: number; name: string }[];
	departments: { id: number; name: string; clinicId: number }[];
	providers: {
		id: number;
		username: string;
		clinicId: number;
		firstName: string;
		lastName: string;
		departmentIds: string[];
	}[];
	insuranceGroups: {
		id: number;
		dataSourceId: number;
		name: string;
	}[];
};

type SummariesResult = SummaryRecord[];

const getSummariesListSchedule = async (
	startDate: number,
	endDate: number,
	scheduleFilter: ScheduleFilter,
	patientLookup?: string,
): Promise<SummariesResult> => {
	const response = await getOrCreateApiGateway().getSummariesListSchedule(
		startDate,
		endDate,
		scheduleFilter,
		patientLookup,
	);

	if (Array.isArray(response?.data)) {
		return response.data;
	}

	return null;
};

const getSummariesListCurrentDay = async (
	startDate: number,
	endDate: number,
	scheduleFilter: ScheduleFilter,
	patientLookup?: string,
): Promise<SummariesResult | null> => {
	const response = await getOrCreateApiGateway().getSummariesListCurrentDay(
		startDate,
		endDate,
		scheduleFilter,
		patientLookup,
	);

	if (Array.isArray(response?.data)) {
		return response.data;
	}

	return null;
};

export enum TimeSpanType {
	today = 'Today',
	week = 'This week',
	month = 'Month',
	custom = 'Custom',
	none = 'None',
}

class ScheduleStore {
	@observable currentDate: string | undefined = undefined;
	@observable currentDateSummaryRecords: SummaryRecord[] = [];
	@observable summaryRecords: SummaryRecord[] = [];
	@observable startDate: number | undefined = undefined;
	@observable endDate: number | undefined = undefined;
	@observable patientLookup: string | undefined = undefined;
	@observable timeSpanType: TimeSpanType = TimeSpanType.today;
	@observable customDate = new Date();
	@observable currentPage = 0;
	@observable loading = false;
	@observable isErrorState = false;
	@observable isEmptySchedule = false;

	clinicsDepartmentsProvidersSource: ClinicsDepartmentsProvidersType = {
		clinics: [],
		departments: [],
		providers: [],
		insuranceGroups: [],
	};

	@observable clinicsDepartmentsProviders: ClinicsDepartmentsProvidersType = this.clinicsDepartmentsProvidersSource;
	@observable selectedClinicId: number | undefined = undefined;
	@observable selectedDepartmentId: number | undefined = undefined;
	@observable selectedProviderIds: number[] = [];
	@observable selectedInsuranceFilterGroups: number[] = [];

	constructor(authStore: AuthStore | undefined, shouldLoad: boolean) {
		if (!authStore) {
			getOrCreateLogger().error('no authStore, scheduleStore initialization failed');
			return;
		}

		const parsedQueries = QueryString.parse(history.location.search);
		const queryTimeSpan = parsedQueries['timeSpan'];

		if (queryTimeSpan) {
			try {
				runInAction((): void => {
					const queryTimeSpanType = TimeSpanType[(queryTimeSpan as string).toLowerCase()];
					if (queryTimeSpanType) {
						this.setTimeSpanType(queryTimeSpanType);
						if (queryTimeSpanType === TimeSpanType.custom) {
							const customDate = new Date(parsedQueries['customDate'] as string);
							if (customDate.toString() !== 'Invalid Date') {
								this.setCustomDate(customDate);
							}
						}
					}
				});
			} catch (err) {
				console.error('Unrecognized TimeSpan in query: ', queryTimeSpan, err);
			}
		}

		const analytics = getOrCreateAnalytics();

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (NavinaCache.exist('selectedClinicId')) {
				const id = parseInt(NavinaCache.get('selectedClinicId'), 10);
				this.setSelectedClinicId(id);
			}

			if (NavinaCache.exist('selectedDepartmentId')) {
				const id = parseInt(NavinaCache.get('selectedDepartmentId'), 10);
				this.setSelectedDepartmentId(id);
			}

			if (NavinaCache.exist('selectedProviderIds')) {
				const ids: number[] = NavinaCache.getArray('selectedProviderIds').map((str) => parseInt(str, 10));
				this.setSelectedProviderIds(ids);
			}

			if (NavinaCache.exist('selectedInsuranceFilterGroups')) {
				const ids: number[] = NavinaCache.getArray('selectedInsuranceFilterGroups').map((str) => parseInt(str, 10));
				this.setSelectedInsuranceFilterGroups(ids);
			}
		});

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (!shouldLoad) {
				console.log('Should not load schedule store');
				return;
			}

			getOrCreateApiGateway()
				.getClinicsDepartmentsProviders()
				.then((response): void => {
					if (response.data) {
						this.setClinicsDepartmentsProviders(response.data);
						this.clinicsDepartmentsProvidersSource = response.data;
						this.filterResults();
					}
				});
		});

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (!this.currentDate) {
				return;
			}

			if (!shouldLoad) {
				console.log('Should not load schedule store');
				return;
			}

			getSummariesListCurrentDay(
				moment(this.currentDate, 'DD/MM/YYYY').startOf('day').unix(),
				moment(this.currentDate, 'DD/MM/YYYY').endOf('day').unix(),
				{
					selectedClinicId: this.selectedClinicId,
					selectedDepartmentId: this.selectedDepartmentId,
					selectedProviderIds: this.selectedProviderIds,
					selectedInsuranceFilterGroups: this.selectedInsuranceFilterGroups,
				} as ScheduleFilter,
			)
				.then((result: SummariesResult | null): void => {
					if (result) {
						this.setCurrentDateSummaryRecords(result);
					}
				})
				.catch((err): void => {
					getOrCreateLogger().error('Error loading currentDate summaries list', err);
					this.setCurrentDateSummaryRecords([]);
					this.setLoading(false);
					this.setIsErrorState(true);
					console.log(err);
				});
		});

		autorun(
			(): void => {
				if (!authStore.getToken) {
					console.log('no token, user not yet authenticated');
					return;
				}

				if (!shouldLoad) {
					console.log('Should not load schedule store');
					return;
				}

				const logExtras = { startDate: this.startDate, endDate: this.endDate, patientLookup: this.patientLookup };

				this.setLoading(true);

				getSummariesListSchedule(
					this.startDate,
					this.endDate,
					{
						selectedClinicId: this.selectedClinicId,
						selectedDepartmentId: this.selectedDepartmentId,
						selectedProviderIds: this.selectedProviderIds,
						selectedInsuranceFilterGroups: this.selectedInsuranceFilterGroups,
					} as ScheduleFilter,
					this.patientLookup,
				)
					.then((result): void => {
						if (result) {
							this.setSummaryRecords(result);
							console.log('Summaries list success', { totalCount: result.length, ...logExtras });
							this.setLoading(false);
							this.setIsErrorState(false);
							this.setIsEmptySchedule(false);
						} else if (this.clinicsDepartmentsProvidersSource.providers.length === 0) {
							this.setLoading(false);
							this.setIsErrorState(false);
							this.setIsEmptySchedule(true);
							analytics.track(analytics.idsNames.NoAccessPageView);
						}
					})
					.catch((err): void => {
						getOrCreateLogger().error('Error loading summaries list', err);
						console.log('Summaries list error', logExtras);
						this.setSummaryRecords([]);
						this.setLoading(false);
						this.setIsErrorState(true);
						console.log(err);
					});
			},
			{ delay: 500 },
		);

		autorun((): void => {
			const now = moment();
			const unixCustomTime = moment.parseZone(this.customDate.toDateString() + ' 00:00');

			switch (this.timeSpanType) {
				case TimeSpanType.today:
					this.setDatesSpan(now.startOf('day').unix(), now.endOf('day').unix());
					break;
				case TimeSpanType.week:
					this.setDatesSpan(now.startOf('week').unix(), now.endOf('week').unix());
					break;
				case TimeSpanType.month:
					this.setDatesSpan(now.startOf('month').unix(), now.endOf('month').unix());
					break;
				case TimeSpanType.custom: {
					const unixCustomTime = moment.parseZone(this.customDate.toDateString() + ' 00:00');
					this.setDatesSpan(unixCustomTime.startOf('day').unix(), unixCustomTime.endOf('day').unix());
					break;
				}
				default:
					this.setTimeSpanType(TimeSpanType.none);
			}
		});
	}

	@action setClinicsDepartmentsProviders = (cdp: ClinicsDepartmentsProvidersType): void => {
		this.clinicsDepartmentsProviders = cdp;

		// Make sure the current selected Clinic / Department / Provider are within our permission boundaries
		if (this.selectedClinicId) {
			if (!cdp.clinics.find((clinic): boolean => clinic.id === this.selectedClinicId)) {
				this.setSelectedClinicId(undefined, true);
			}
		}

		if (this.selectedDepartmentId) {
			if (!cdp.departments.find((department): boolean => department.id === this.selectedDepartmentId)) {
				this.setSelectedDepartmentId(undefined, true);
			}
		}
		// todo: take care of SelectedProvider and Providers
	};

	@action setCurrentDate = (currentDate: number): void => {
		this.currentDate = moment.unix(currentDate).format('DD/MM/YYYY');
	};

	updateCache = (): void => {
		NavinaCache.set('selectedClinicId', this.selectedClinicId);
		NavinaCache.set('selectedDepartmentId', this.selectedDepartmentId);
		NavinaCache.set('selectedProviderIds', this.selectedProviderIds);
		NavinaCache.set('selectedInsuranceFilterGroups', this.selectedInsuranceFilterGroups);
	};

	@action setSelectedClinicId = (selectedClinicId: typeof this.selectedClinicId, manuallyUpdate = false): void => {
		if (this.selectedClinicId !== selectedClinicId) {
			this.selectedClinicId = selectedClinicId;

			if (manuallyUpdate) {
				this.filterResults();
				this.updateCache();
			}
		}
	};

	@action setSelectedDepartmentId = (
		selectedDepartmentId: typeof this.selectedDepartmentId,
		manuallyUpdate = false,
	): void => {
		if (this.selectedDepartmentId !== selectedDepartmentId) {
			this.selectedDepartmentId = selectedDepartmentId;

			if (manuallyUpdate) {
				this.filterResults();
				this.updateCache();
			}
		}
	};

	@action setSelectedProviderIds = (
		selectedProviderIds: typeof this.selectedProviderIds,
		manuallyUpdate = false,
	): void => {
		if (!intArraysEqual(this.selectedProviderIds, selectedProviderIds)) {
			this.selectedProviderIds = selectedProviderIds;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	@action setSelectedInsuranceFilterGroups = (
		selectedInsuranceFilterGroups: typeof this.selectedInsuranceFilterGroups,
		manuallyUpdate = false,
	): void => {
		if (!intArraysEqual(this.selectedInsuranceFilterGroups, selectedInsuranceFilterGroups)) {
			this.selectedInsuranceFilterGroups = selectedInsuranceFilterGroups;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	@action setCurrentDateSummaryRecords = (currentDateSummaryRecords: typeof this.currentDateSummaryRecords): void => {
		this.currentDateSummaryRecords = currentDateSummaryRecords;
	};

	@action setDatesSpan = (startDate: typeof this.startDate, endDate: typeof this.endDate): void => {
		this.startDate = startDate;
		this.endDate = endDate;
		this.patientLookup = undefined;
	};

	@action setPatientLookup = (patientLookup: typeof this.patientLookup): void => {
		if (!patientLookup) {
			this.patientLookup = undefined;
			this.setTimeSpanType(TimeSpanType.today);
			return;
		}
		this.patientLookup = patientLookup;
		this.timeSpanType = TimeSpanType.none;
		this.startDate = undefined;
		this.endDate = undefined;
	};

	@action setLoading = (loading: typeof this.loading): void => {
		this.loading = loading;
	};

	@action setSummaryRecords = (summaryRecords: typeof this.summaryRecords): void => {
		this.summaryRecords = summaryRecords;
	};

	@action setIsErrorState = (isErrorState: typeof this.isErrorState): void => {
		this.isErrorState = isErrorState;
	};

	@action setIsEmptySchedule = (isEmptySchedule: typeof this.isEmptySchedule): void => {
		this.isEmptySchedule = isEmptySchedule;
	};

	@action setTimeSpanType = (timeSpanType: typeof this.timeSpanType): void => {
		this.timeSpanType = timeSpanType;
	};

	@action setCustomDate = (customDate: Date): void => {
		this.customDate = customDate;
	};

	@action setCurrentPage = (currentPage: typeof this.currentPage): void => {
		this.currentPage = currentPage;
	};

	filterResults = (): void => {
		// TODO - can extract to config
		const hideEmptyDepartments = true;
		const hideFullNamelessProviders = true;

		let departments = [...this.clinicsDepartmentsProvidersSource.departments];
		let providers = [...this.clinicsDepartmentsProvidersSource.providers];
		let insuranceFilterGroups = [...this.clinicsDepartmentsProvidersSource.insuranceGroups];

		if (hideFullNamelessProviders) {
			providers = providers.filter((provider): boolean => Boolean(provider.firstName || provider.lastName));
		}

		if (hideEmptyDepartments) {
			departments = departments.filter(
				(department): boolean =>
					providers.filter((provider): boolean => provider.departmentIds.includes(department.id.toString())).length > 0,
			);
		}

		if (this.selectedClinicId) {
			departments = departments.filter((department): boolean => department.clinicId === this.selectedClinicId);

			providers = providers.filter((provider): boolean => provider.clinicId === this.selectedClinicId);

			insuranceFilterGroups = insuranceFilterGroups.filter(
				(insuranceFilterGroup): boolean => insuranceFilterGroup.dataSourceId === this.selectedClinicId,
			);
		}

		if (this.selectedDepartmentId) {
			const departmentIdString = String(this.selectedDepartmentId);
			providers = providers.filter((provider): boolean => provider.departmentIds.includes(departmentIdString));
		}

		this.setClinicsDepartmentsProviders({
			clinics: this.clinicsDepartmentsProvidersSource.clinics,
			departments,
			providers,
			insuranceGroups: insuranceFilterGroups,
		});

		// Reset the current selected department or provider if not appears on new <option> elements
		if (!departments.find((department): boolean => department.id === this.selectedDepartmentId)) {
			this.setSelectedDepartmentId(undefined, true);
		}

		const filteredProviders: number[] = providers
			.filter((provider): boolean => this.selectedProviderIds.includes(provider.id))
			.map((provider) => provider.id);

		this.setSelectedProviderIds(filteredProviders);

		const filteredInsuranceFilterGroups: number[] = insuranceFilterGroups
			.filter((group): boolean => this.selectedInsuranceFilterGroups.includes(group.id))
			.map((insuranceFilterGroup) => insuranceFilterGroup.id);

		this.setSelectedInsuranceFilterGroups(filteredInsuranceFilterGroups);
	};
}

export const ScheduleStoreContext = createContext<ScheduleStore>(null);
export const useScheduleStore = (): ScheduleStore => useContext(ScheduleStoreContext);

export default ScheduleStore;
