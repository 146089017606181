import { type ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';

export interface CoreContainerProps extends ComponentProps<typeof Container> {
	isBackgroundDisabled?: boolean;
	onBackgroundClick?: VoidFunction;
}

const Container = styled.div<{
	readonly $margin?: string;
	readonly $padding?: string;
	readonly width?: string;
	readonly height?: string;
	readonly background?: string;
}>`
	background: ${(props) => props.background || 'inherit'};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.$margin || 'initial'};
	padding: ${(props) => props.$padding || 'none'};
	width: ${(props) => props.width || 'auto'};
	height: ${(props) => props.height || 'auto'};
`;

export default forwardRef<HTMLDivElement, CoreContainerProps>(function CoreSimpleContainer(props, ref) {
	return <Container ref={ref} {...props} />;
});
