import { NavinaLayout } from './LayoutType';
import { Suspense } from 'react';

const EmptyLayout = ({ component: Component, ...props }: NavinaLayout) => {
	return (
		<Suspense fallback={<div />}>
			<Component {...props} />
		</Suspense>
	);
};
export default EmptyLayout;
