import colors from '../Colors';
import { MAX_WIDTH_LOWER_WIDTH } from '../Resolutions';
import styled from 'styled-components';

const SvgWithSmallerSize = styled.svg<{
	readonly width?: string;
	readonly height?: string;
	readonly $minWidth?: string;
	readonly $minHeight?: string;
}>`
	width: ${(props) => props.width || '11px'};
	height: ${(props) => props.height || '8px'};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		width: ${(props) => props.$minWidth || '9px'};
		height: ${(props) => props.$minHeight || '7px'};
	}
`;

const SingleExpanderIcon = ({
	color = colors.primary,
	height = '24',
	width = '24',
	$minWidth = '9',
	$minHeight = '7',
	isNewIcon = false,
}: {
	color?: string;
	height?: string;
	width?: string;
	$minWidth?: string;
	$minHeight?: string;
	isNewIcon?: boolean;
}) => {
	// TODO: When/If everything moves to the new icon, remove this if and set default height and width to 8 and 11
	if (isNewIcon) {
		return (
			<SvgWithSmallerSize
				width={width}
				height={height}
				$minWidth={$minWidth}
				$minHeight={$minHeight}
				viewBox='0 0 11 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 1.75614L1.48659 0.418213L5.24329 4.59233L9 0.418213L10.4866 1.75614L5.24329 7.58203L0 1.75614Z'
					fill={color}
				/>
			</SvgWithSmallerSize>
		);
	}

	return (
		<svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M17 9.5L12 14.5L7 9.5' stroke={color} strokeWidth='2' />
		</svg>
	);
};

export default SingleExpanderIcon;
