export default function FamilyHistoryIcon() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='13' y='11' width='9' height='3' fill='#78F5CE' />
			<rect x='10' y='29' width='9' height='3' fill='#78F5CE' />
			<path d='M22 8L13 8' stroke='#322B5F' strokeWidth='2' />
			<path d='M10 27L19 27' stroke='#322B5F' strokeWidth='2' />
			<path d='M22 12H16' stroke='#322B5F' strokeWidth='2' />
			<path d='M10 31H16' stroke='#322B5F' strokeWidth='2' />
			<path
				d='M23 7V8.21738C23 10.8117 21.9918 13.3044 20.1883 15.1692L16 19.5L11.8117 23.8308C10.0082 25.6956 9 28.1883 9 30.7826V32'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<path
				d='M9 7V8.21738C9 10.8117 10.0082 13.3044 11.8117 15.1692L16 19.5L20.1883 23.8308C21.9918 25.6956 23 28.1883 23 30.7826V32'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<circle cx='4' cy='17' r='3' stroke='#322B5F' strokeWidth='2' />
			<circle cx='6.5' cy='20.5' r='3.5' fill='#78F5CE' />
			<circle cx='6.5' cy='20.5' r='2.5' stroke='#322B5F' strokeWidth='2' />
		</svg>
	);
}
