import { CommonConfiguration } from '../../config/CommonConfig';
import type { NavinaConfiguration } from '../../config/NavinaConfiguration';
import { EPIC_CLIENT_ID_STAGING, OLD_EPIC_CLIENT_ID_STAGING, OLD_EPIC_CONNECTION_NAMES } from '../../pages/epic/consts';

const Auth0ClientID = 'A0VRgegcbjM2Ita19rIbUAslskBbt2AA';

export const StagingConfiguration = {
	...CommonConfiguration,
	ApiUrl: 'https://nmf3ldhlpg.execute-api.us-east-1.amazonaws.com/default/',
	Auth0ClientID,
	NavinaAuth0AuthURL: (redirectUri: string = CommonConfiguration.Auth0LoginRedirect) =>
		`${CommonConfiguration.NavinaAuth0BaseURL}authorize?response_type=id_token%20token%20code&client_id=${Auth0ClientID}&prompt=none&redirect_uri=${redirectUri}&nonce=${CommonConfiguration.Auth0Nonce}&scope=offline_access`,
	MixpanelKey: '23b903e5ed56e30ad0a7f802d407502d',
	EpicClientId: (connectionName: string) =>
		OLD_EPIC_CONNECTION_NAMES.includes(connectionName.toLowerCase())
			? OLD_EPIC_CLIENT_ID_STAGING
			: EPIC_CLIENT_ID_STAGING,
	WebSocketServerApiId: 'bmpwutvebf',
	WebSocketServerStage: 'staging',
} as const satisfies NavinaConfiguration;
