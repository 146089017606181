import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function OrgDashboardSvg() {
	return (
		<svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_d_3393_108866)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M18.8683 15C17.0954 14.9999 15.5677 14.9998 14.3444 15.1643C13.0334 15.3406 11.7767 15.738 10.7574 16.7574C9.73804 17.7767 9.34055 19.0334 9.16429 20.3444C8.99982 21.5677 8.99991 23.0954 9 24.8683V32.1317C8.99991 33.9046 8.99982 35.4323 9.16429 36.6556C9.34055 37.9666 9.73804 39.2233 10.7574 40.2427C11.7767 41.262 13.0334 41.6595 14.3444 41.8357C15.5677 42.0002 17.0954 42.0001 18.8683 42H34.1317C35.9046 42.0001 37.4323 42.0002 38.6556 41.8357C39.9666 41.6595 41.2233 41.262 42.2427 40.2427C43.262 39.2233 43.6595 37.9666 43.8357 36.6556C44.0002 35.4323 44.0001 33.9046 44 32.1317V24.8683C44.0001 23.0954 44.0002 21.5677 43.8357 20.3444C43.6595 19.0334 43.262 17.7767 42.2427 16.7574C41.2233 15.738 39.9666 15.3406 38.6556 15.1643C37.4323 14.9998 35.9046 14.9999 34.1317 15H18.8683ZM11 25C11 21.2288 11 19.3432 12.1716 18.1716C13.3432 17 15.2288 17 19 17H34C37.7712 17 39.6569 17 40.8284 18.1716C42 19.3432 42 21.2288 42 25L42 31.0007H35.1181L33.0798 26.9241L29.1488 31.8092L26.0698 24.625L21.882 31.0007L11 31.0001V25ZM11.0009 33.0001C11.0101 36.1143 11.1085 37.7654 12.1716 38.8284C13.3432 40 15.2288 40 19 40H34C37.7712 40 39.6569 40 40.8284 38.8284C41.8914 37.7654 41.99 36.1146 41.9991 33.0007H33.882L32.9203 31.0773L28.8513 36.1923L25.9302 29.3764L23.1181 33.0007L11.0009 33.0001Z'
					fill='#453B87'
				/>
				<path
					d='M11.0009 33.0001C11.0101 36.1143 11.1085 37.7654 12.1716 38.8284C13.3432 40 15.2288 40 19 40H34C37.7712 40 39.6569 40 40.8284 38.8284C41.8914 37.7654 41.99 36.1146 41.9991 33.0007H33.882L32.9203 31.0773L28.8513 36.1923L25.9302 29.3764L23.1181 33.0007L11.0009 33.0001Z'
					fill='#9AF9DB'
				/>
				<path
					d='M12.1716 18.1716C11 19.3432 11 21.2288 11 25V31.0001L21.882 31.0007L26.0698 24.625L29.1488 31.8092L33.0798 26.9241L35.1181 31.0007H42L42 25C42 21.2288 42 19.3432 40.8284 18.1716C39.6569 17 37.7712 17 34 17H19C15.2288 17 13.3432 17 12.1716 18.1716Z'
					fill='url(#paint0_linear_3393_108866)'
				/>
			</g>
			<path
				opacity='0.17'
				d='M48.9541 25.9079L46.0905 26.1489L43.49 27.3721L43.2491 24.5084L42.0259 21.9079L44.8895 21.667L47.49 20.4438L47.7309 23.3075L48.9541 25.9079Z'
				fill='url(#paint1_linear_3393_108866)'
			/>
			<path
				opacity='0.3'
				d='M49.6963 13.5981L47.5486 13.7788L45.5982 14.6962L45.4175 12.5484L44.5001 10.5981L46.6479 10.4174L48.5982 9.5L48.7789 11.6477L49.6963 13.5981Z'
				fill='url(#paint2_linear_3393_108866)'
			/>
			<path
				opacity='0.3'
				d='M34.6963 22.9433L32.5486 23.124L30.5982 24.0414L30.4175 21.8936L29.5001 19.9433L31.6479 19.7626L33.5982 18.8452L33.7789 20.9929L34.6963 22.9433Z'
				fill='url(#paint3_linear_3393_108866)'
				fillOpacity='0.41'
			/>
			<path
				d='M41.3681 9.5236C41.7961 9.15192 42.4641 9.33093 42.6489 9.8668L43.7965 13.1945C43.8299 13.2914 43.8816 13.3809 43.9488 13.4583L46.2569 16.1159C46.6285 16.5439 46.4495 17.212 45.9137 17.3968L42.586 18.5443C42.4891 18.5777 42.3996 18.6294 42.3222 18.6966L39.6646 21.0047C39.2366 21.3764 38.5685 21.1974 38.3837 20.6615L37.2361 17.3338C37.2027 17.2369 37.151 17.1474 37.0839 17.07L34.7758 14.4124C34.4041 13.9844 34.5831 13.3163 35.119 13.1315L38.4467 11.984C38.5435 11.9506 38.6331 11.8989 38.7104 11.8317L41.3681 9.5236Z'
				fill='url(#paint4_linear_3393_108866)'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M45.6115 12.3223L47.767 14.8043C49.0679 16.3022 48.4414 18.6405 46.5658 19.2873L43.4581 20.359L40.9761 22.5145C39.4782 23.8154 37.1399 23.1888 36.4931 21.3133L35.4214 18.2056L33.2659 15.7236C31.965 14.2257 32.5916 11.8873 34.4671 11.2406L37.5748 10.1689L40.0568 8.01335C41.5547 6.71247 43.8931 7.33903 44.5398 9.21457L45.6115 12.3223ZM42.6491 9.86659C42.4643 9.33072 41.7962 9.1517 41.3682 9.52338L38.7106 11.8315C38.6332 11.8987 38.5437 11.9504 38.4468 11.9838L35.1191 13.1313C34.5833 13.3161 34.4042 13.9842 34.7759 14.4122L37.084 17.0698C37.1512 17.1472 37.2029 17.2367 37.2363 17.3336L38.3838 20.6613C38.5686 21.1971 39.2367 21.3762 39.6647 21.0045L42.3224 18.6964C42.3997 18.6292 42.4893 18.5775 42.5861 18.5441L45.9138 17.3966C46.4497 17.2118 46.6287 16.5437 46.257 16.1157L43.9489 13.458C43.8817 13.3807 43.83 13.2911 43.7966 13.1943L42.6491 9.86659Z'
				fill='#EFECFB'
			/>
			<defs>
				<filter
					id='filter0_d_3393_108866'
					x='5'
					y='12'
					width='43'
					height='35'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='1' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix type='matrix' values='0 0 0 0 0.0831428 0 0 0 0 0.0477431 0 0 0 0 0.229167 0 0 0 0.1 0' />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3393_108866' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3393_108866' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_3393_108866'
					x1='26.5'
					y1='15'
					x2='26.5'
					y2='42'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E4DDFF' />
					<stop offset='1' stopColor='#F0ECFF' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_3393_108866'
					x1='55.5208'
					y1='29.3913'
					x2='42.1592'
					y2='21.677'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_3393_108866'
					x1='54.6213'
					y1='16.2106'
					x2='44.6001'
					y2='10.4249'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_3393_108866'
					x1='39.6213'
					y1='25.5558'
					x2='29.6001'
					y2='19.7701'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_3393_108866'
					x1='45.0714'
					y1='-3.47362'
					x2='38.3355'
					y2='21.6652'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default function OrgDashboardIcon(props: Partial<CustomIconComponentProps>) {
	return <Icon component={OrgDashboardSvg} {...props} />;
}
