import { v4 as uuidv4 } from 'uuid';

export enum OverlayProtocolTypes {
	// FromNavinaToAddonEvents
	'addon-change-frame-src' = 'addon-change-frame-src',
	'addon-sso-login-succeed' = 'addon-sso-login-succeed',
	'addon-cart-load' = 'addon-cart-load',
	'addon-open-page-full-screen' = 'addon-open-page-full-screen',
	'addon-push-diagnoses-request' = 'addon-push-diagnoses-request',
	'addon-reload-diagnoses' = 'addon-reload-diagnoses',
	'addon-sso-login-completed' = 'addon-sso-login-completed',
	'addon-update-overlay-notifications' = 'addon-update-overlay-notifications',
	// FromAddonToNavinaEvent
	'navina-add-diagnoses' = 'navina-add-diagnoses',
	'navina-push-diagnoses-result' = 'navina-push-diagnoses-result',
	'navina-inform-encounter-status' = 'navina-inform-encounter-status',
	// FromAddonToIframeEvent
	'iframe-select-tab' = 'iframe-select-tab',
	'iframe-update-url' = 'iframe-update-url',
	'iframe-unload-url' = 'iframe-unload-url',
}

export enum OpenSummaryProtocolTypes {
	'open-summary' = 'open-summary',
}

class AddonCommunicator {
	callbacks: { [eventListenerId: string]: (event: MessageEvent) => any } = {};

	constructor() {
		this.callbacks = {};
		this.startListening();
	}

	addCallback = (callback: (event: MessageEvent) => void): string => {
		const eventListenerId = uuidv4();
		this.callbacks[eventListenerId] = callback;
		return eventListenerId;
	};

	removeCallback = (eventListenerId: string): void => {
		const { [eventListenerId]: _removedCallback, ...restCallbacks } = this.callbacks;
		this.callbacks = restCallbacks;
	};

	startListening = (): void => {
		const addonOrigin = new URLSearchParams(window.location.search).get('addonOrigin');
		console.log('>> Start listening');

		window.addEventListener('message', (event): void => {
			if (event.origin === addonOrigin && event.data.destination === 'to-navina') {
				console.log('Message arrived to Navina:', event);
			}
			Object.values(this.callbacks).forEach((callback) => callback(event));
		});
	};

	sendToAddon = (eventType: OverlayProtocolTypes | OpenSummaryProtocolTypes, params: any = {}): void => {
		const message = {
			destination: 'to-addon',
			type: eventType,
			params: params || {},
		};
		console.log('>> Message to addon:', message);
		window.parent.postMessage(message, '*');
	};
}

let maybeAddonCommunicator: AddonCommunicator | null = null;

function createAddonCommunicator(): AddonCommunicator {
	return new AddonCommunicator();
}

export function getOrCreateAddonCommunicator(): AddonCommunicator {
	if (maybeAddonCommunicator === null) {
		maybeAddonCommunicator = createAddonCommunicator();
	}
	return maybeAddonCommunicator;
}
