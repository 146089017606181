export default function ImagingAndTestsIcon() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<mask id='path-1-inside-1' fill='white'>
				<rect x='2' y='4' width='28' height='24' rx='0.5' />
			</mask>
			<rect x='2' y='4' width='28' height='24' rx='0.5' stroke='#322B5F' strokeWidth='4' mask='url(#path-1-inside-1)' />
			<path
				d='M15.8521 5V12.6479M15.8521 12.6479L19.7042 16.5M15.8521 12.6479L12 16.5'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<path
				d='M7.16488 23.959C6.54454 23.7613 6.31647 22.851 6.17963 22.2809C5.02561 17.3432 4.50562 14.4251 6.17963 11C6.99611 9.33111 8.00873 7.84024 8.38732 8.01494C8.76591 8.18965 7.71224 10.3137 8.12276 13.4538C8.27785 14.6537 8.47398 16.1479 9.52765 17.5133C10.6862 19.0167 12.0136 19.2374 11.9999 20.1937C11.9999 20.681 11.416 21.1775 10.2666 22.1476C8.64731 23.5314 7.83083 24.1751 7.16488 23.959Z'
				fill='#78F5CE'
			/>
			<path
				d='M24.8351 23.959C25.4555 23.7613 25.6835 22.851 25.8204 22.2809C26.9744 17.3432 27.4944 14.4251 25.8204 11C25.0039 9.33111 23.9913 7.84024 23.6127 8.01494C23.2341 8.18965 24.2878 10.3137 23.8772 13.4538C23.7222 14.6537 23.526 16.1479 22.4723 17.5133C21.3138 19.0167 19.9864 19.2374 20.0001 20.1937C20.0001 20.681 20.584 21.1775 21.7334 22.1476C23.3527 23.5314 24.1692 24.1751 24.8351 23.959Z'
				fill='#78F5CE'
			/>
			<path
				d='M22.1507 22.3423C21.3401 21.5314 20.9318 21.1241 20.6796 20.6575C20.1232 19.5747 19.8976 18.283 20.0431 17.0137L20.5595 9.68186C20.5946 9.1935 20.7853 8.74225 21.0898 8.42709C22.6381 6.82455 24.572 10.1568 25.141 11.426C25.3992 12.0221 25.7595 13.1219 25.9277 15.751C26.0564 17.8185 26.0132 19.8969 25.7986 21.9535V22.0017C25.5944 23.2977 25.12 23.7569 24.7598 23.9124C23.8591 24.3574 22.6982 22.8947 22.1507 22.3423Z'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<path
				d='M9.84926 22.3423C10.6599 21.5314 11.0682 21.1241 11.3204 20.6575C11.8768 19.5747 12.1024 18.283 11.9569 17.0137L11.4405 9.68186C11.4054 9.1935 11.2147 8.74225 10.9102 8.42709C9.36188 6.82455 7.42797 10.1568 6.85895 11.426C6.60075 12.0221 6.24047 13.1219 6.07234 15.751C5.94355 17.8185 5.98681 19.8969 6.20144 21.9535V22.0017C6.4056 23.2977 6.87997 23.7569 7.24025 23.9124C8.14094 24.3574 9.30179 22.8947 9.84926 22.3423Z'
				stroke='#322B5F'
				strokeWidth='2'
			/>
		</svg>
	);
}
