export default function BuddyCodingConnecterIcon({
	width = '36',
	height = '36',
	color,
	strokeColor = color,
}: {
	width?: string;
	height?: string;
	color: string;
	strokeColor?: string;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.73 29.2383V11.54L8.01 11.27L12.51 6.77L6.73 1L1 6.75L5.36 11.1L5.77 11.52V29.75C5.77 30.1478 5.92804 30.5294 6.20934 30.8107C6.49064 31.092 6.87218 31.25 7.27 31.25H25.48L25.91 31.67L30.27 36.04L36.05 30.27L30.27 24.5L25.92 28.85L25.5 29.25H7.23047M7.23047 29.25C7.36308 29.25 7.6367 29.1562 7.73047 29.25H7.23047Z'
				fill={color}
				stroke={strokeColor}
				strokeMiterlimit='10'
				strokeWidth='0.5px'
			/>
		</svg>
	);
}
