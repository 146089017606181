import type { ComponentProps } from 'react';

export default function InfoIcon({ width = 16, ...props }: ComponentProps<'svg'>) {
	return (
		// TODO: need to add the newer svg
		<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 7V12H7L7 7H9ZM7 6V4L9 4V6H7Z'
				fill='#ADA7D7'
			/>
		</svg>
	);
}
