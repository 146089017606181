import { getOrCreateApiTraceServer } from '../server/apiTrace';

export enum LogLevel {
	error,
	warn,
	info,
	log,
	debug,
	trace,
}

type ConsoleLogger = (message?: any, ...optionalParams: any[]) => void;

type LoggerMethodParams = [message: any, extras?: any];

class Logger {
	username: string | undefined = undefined;

	setUsername = (username: string | undefined): void => {
		this.username = username;
	};

	getConsoleLogger = (logLevel: LogLevel): ConsoleLogger => {
		switch (logLevel) {
			case LogLevel.debug:
				return console.debug;
			case LogLevel.info:
				return console.info;
			case LogLevel.trace:
				return console.log;
			case LogLevel.log:
				return console.log;
			case LogLevel.warn:
				return console.warn;
			case LogLevel.error:
				return console.error;
			default:
				return console.log;
		}
	};

	send = async (logLevel: LogLevel, message: any, extras: any = {}): Promise<void> => {
		const log = this.getConsoleLogger(logLevel);

		log(`${logLevel} - ${message}`, extras);

		await getOrCreateApiTraceServer()
			.log(logLevel, message, this.username, extras)
			.catch((err): void => {
				console.error('Log failed - ', err, message);
			});
	};

	error = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.error, ...args);

	warn = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.warn, ...args);

	info = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.info, ...args);

	log = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.log, ...args);

	debug = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.debug, ...args);

	trace = (...args: LoggerMethodParams): Promise<void> => this.send(LogLevel.trace, ...args);
}

let logger: Logger | null = null;

export function getOrCreateLogger(): Logger {
	if (logger === null) {
		logger = new Logger();
	}
	return logger;
}
