import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function FileManagerSvg() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='31' height='31' viewBox='0 0 31 31' fill='none'>
			<rect width='31' height='31' rx='4' fill='black' fillOpacity='0.06' />
			<path
				d='M7 12C7 10.8954 7.89543 10 9 10H22C22.5523 10 23 10.4477 23 11V20C23 21.6569 21.6569 23 20 23H10C8.34315 23 7 21.6569 7 20V12Z'
				fill='#CFCBE6'
			/>
			<path
				d='M25 16C25 14.1144 25 13.1716 24.4142 12.5858C23.8284 12 22.8856 12 21 12H17.386C16.7156 12 16.3804 12 16.1234 11.8219C15.8664 11.6439 15.7487 11.33 15.5133 10.7022L15.4733 10.5955C15.0025 9.34003 14.7671 8.7123 14.2532 8.35615C13.7393 8 13.0688 8 11.728 8H11C9.11438 8 8.17157 8 7.58579 8.58579C7 9.17157 7 10.1144 7 12V17C7 19.8284 7 21.2426 7.87868 22.1213C8.75736 23 10.1716 23 13 23H19C21.8284 23 23.2426 23 24.1213 22.1213C25 21.2426 25 19.8284 25 17V16Z'
				fill='#9189C2'
			/>
		</svg>
	);
}

export default function FileManagerIcon(props: Partial<CustomIconComponentProps>) {
	return <Icon component={FileManagerSvg} {...props} />;
}
