export enum TicketForms {
	CONTACT_US = 5960325857565,
	FEEDBACK = 6069013709597,
}

export enum Positions {
	RIGHT = 'right',
	LEFT = 'left',
	TOP = 'top',
	BOTTOM = 'bottom',
}

interface IFieldPrefill {
	id: string;
	value: string;
}

export interface IZESettings {
	offsetX: number;
	offsetY: number;
	horizontalPosition: Positions;
	verticalPosition: Positions;
	formTitle: string;
	attachments: boolean;
	fieldsPrefill: IFieldPrefill[];
	ticketForms: TicketForms[];
}

const mutationObserverConfig = { childList: true, subtree: true } as const;

export const observeDocument = (callback: (mutations: MutationRecord[]) => void): MutationObserver => {
	const observer = new MutationObserver((mutations) => callback(mutations));
	observer.observe(document.body, mutationObserverConfig);
	return observer;
};

export const setZESettings = (settings: IZESettings): void => {
	const readOnlyFields = {};

	settings.fieldsPrefill.forEach((prefill): void => {
		if (prefill?.value) {
			readOnlyFields[prefill.id] = { readOnly: true };
		}
	});

	if (!('zE' in window) || !(typeof window.zE === 'function')) {
		throw Error('window.zE is not defined');
	}

	if (Object.keys(readOnlyFields).length > 0) {
		window.zE('webWidget', 'prefill', readOnlyFields);
	}

	window.zE('webWidget', 'updateSettings', {
		webWidget: {
			position: {
				horizontal: settings.horizontalPosition,
				vertical: settings.verticalPosition,
			},
			offset: {
				horizontal: `${settings.offsetX}px`,
				vertical: `${settings.offsetY}px`,
			},
			contactForm: {
				title: {
					'*': settings.formTitle,
				},
				attachments: settings.attachments,
				fields: settings.fieldsPrefill.map((prefill) => ({
					id: prefill.id,
					prefill: { '*': prefill.value },
				})),
				ticketForms: settings.ticketForms.map((ticketForm) => ({ id: ticketForm })),
			},
		},
	});
};

export const onZEUserEvent = (callback: (userEvent) => void): void => {
	if ('zE' in window && typeof window.zE === 'function') {
		window.zE('webWidget:on', 'userEvent', (userEvent): void => callback(userEvent));
	}
};

export const hideZE = (): void => {
	if (
		'zE' in window &&
		(typeof window.zE === 'object' || typeof window.zE === 'function') &&
		'hide' in window.zE &&
		typeof window.zE.hide === 'function'
	) {
		window.zE.hide();
	}
};

export const activateZE = (): void => {
	if (
		'zE' in window &&
		(typeof window.zE === 'object' || typeof window.zE === 'function') &&
		'activate' in window.zE &&
		typeof window.zE.activate === 'function'
	) {
		window.zE.activate();
	}
};
