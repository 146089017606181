import { CoreText, type CoreTextType, type TextColorType } from '.';
import { MAX_WIDTH_LOWER_WIDTH } from '.';
import colors from './Colors';
import type { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

interface CoreDataEntityProps extends Partial<ComponentProps<typeof Container>> {
	name?: string;
	value?: string;
	margin?: string;
	padding?: string;
	maxWidth?: string;
	$minWidth?: string;
	direction?: 'KeyThenValue' | 'ValueThenKey';
	valueTextType?: CoreTextType;
	nameTextType?: CoreTextType;
	valueTextColor?: TextColorType;
	nameTextColor?: TextColorType;
	allowShrink?: boolean;
	isLink?: boolean;
	postfix?: ReactNode;
	cursor?: string;
	id?: string;
	whitespace?: 'nowrap' | 'initial' | 'normal';
	overflow?: 'hidden' | 'initial';
	nameDynamicWhiteSpace?: boolean;
	opacity?: string | number;
	nameWhiteSpace?: 'nowrap' | 'normal';
	valueMinHeight?: string;
}

const Container = styled.div<{
	readonly $margin?: string;
	readonly $padding?: string;
	readonly $maxWidth: string;
	readonly $minWidth: string;
	readonly direction: 'KeyThenValue' | 'ValueThenKey';
	readonly $allowShrink: boolean;
	readonly cursor?: string;
	readonly $position?: string;
	readonly $alignSelf?: string;
	readonly $flex?: string | number;
	readonly justifyContent?: string;
}>`
	display: flex;
	flex-direction: ${(props) => (props.direction === 'ValueThenKey' ? 'column' : 'column-reverse')};
	margin: ${(props) => props.$margin || 'initial'};
	padding: ${(props) => props.$padding || 'none'};
	max-width: ${(props) => props.$maxWidth || 'none'};
	flex-shrink: ${(props) => (props.$allowShrink ? 1 : 0)};
	cursor: ${(props) => props.cursor || 'inherit'};
	min-width: ${(props) => props.$minWidth || 0};
	${(props) => props.$flex && `flex: ${props.$flex}`};
	${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
	position: ${(props) => props.$position || 'static'};
	${(props) => props.$alignSelf && `align-self: ${props.$alignSelf}`};

	&:hover .mark-on-hover {
		cursor: default;
		border-radius: 6px;
		background-color: ${colors.authInputBackground};
		outline: 6px solid ${colors.authInputBackground};
	}
`;

const CoreTextWhiteSpaceWrapper = styled(CoreText)`
	white-space: nowrap;
	${(props) => props.opacity && `opacity: ${props.opacity}`};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		white-space: normal;
	}
`;

export default function CoreDataEntity({
	name,
	value,
	maxWidth,
	$minWidth,
	whitespace = 'nowrap',
	overflow = 'hidden',
	direction = 'ValueThenKey',
	valueTextColor = 'primary',
	valueTextType = 'body1',
	nameTextType = 'captionRegular',
	nameTextColor = 'tertiary',
	allowShrink = false,
	isLink = false,
	id,
	cursor,
	postfix,
	children,
	$position,
	nameDynamicWhiteSpace,
	nameWhiteSpace = 'nowrap',
	valueMinHeight,
	$alignSelf,
	...other
}: CoreDataEntityProps) {
	const marginValue = direction === 'ValueThenKey' ? '4px 0 0 0' : '0 0 4px 0';

	return (
		<Container
			id={id}
			className='core-data-entity'
			$maxWidth={maxWidth}
			$minWidth={$minWidth}
			direction={direction}
			$allowShrink={allowShrink}
			cursor={cursor}
			$position={$position}
			$alignSelf={$alignSelf}
			{...other}
		>
			{children ? (
				<div className='core-data-entity-text-wrapper'>
					<CoreText
						type={valueTextType}
						color={isLink ? 'accentLightGreen' : valueTextColor}
						maxWidth='100%'
						overflow={overflow}
						whiteSpace={whitespace}
						$minHeight={valueMinHeight}
						opacity={other.opacity ? String(other.opacity) : '1'}
					>
						{children}
					</CoreText>
					{postfix}
				</div>
			) : (
				<div className='core-data-entity-text-wrapper'>
					<CoreText
						type={valueTextType}
						color={valueTextColor}
						text={value}
						maxWidth='100%'
						overflow={overflow}
						whiteSpace={whitespace}
						$minHeight={valueMinHeight}
						opacity={other.opacity ? String(other.opacity) : '1'}
					/>
					{postfix}
				</div>
			)}

			{name && nameDynamicWhiteSpace ? (
				<CoreTextWhiteSpaceWrapper
					type={nameTextType}
					color={nameTextColor}
					text={name}
					$margin={marginValue}
					opacity={other.opacity ? String(other.opacity) : '1'}
				/>
			) : (
				<CoreText
					type={nameTextType}
					color={nameTextColor}
					text={name}
					$margin={marginValue}
					whiteSpace={nameWhiteSpace}
					opacity={other.opacity ? String(other.opacity) : '1'}
				/>
			)}
		</Container>
	);
}
