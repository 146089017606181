import type { ConfigurationName, NavinaConfiguration } from './NavinaConfiguration';
import { JsonServerConfiguration } from './application-configurations/JsonServerConfiguration';
import { LocalServerConfiguration } from './application-configurations/LocalServerConfiguration';
import { ProductionConfiguration } from './application-configurations/ProductionConfiguration';
import { StagingConfiguration } from './application-configurations/StagingConfiguration';
import { TestConfiguration } from './application-configurations/TestConfiguration';

const CONFIGURATION_NAME = process.env.REACT_APP_CONFIGURATION_NAME;

if (!CONFIGURATION_NAME) {
	throw Error('process.env.REACT_APP_CONFIGURATION_NAME is not defined or empty');
}

export const configurations = {
	test: TestConfiguration,
	production: ProductionConfiguration,
	staging: StagingConfiguration,
	development: StagingConfiguration,
	jsonServer: JsonServerConfiguration,
	local: LocalServerConfiguration,
} as const satisfies Record<ConfigurationName, NavinaConfiguration>;

const config = configurations[CONFIGURATION_NAME];

export default config;
