import { colors } from './Colors';
import { type ComponentType, forwardRef, type ComponentProps } from 'react';
import styled from 'styled-components';

interface CoreSmallButtonProps extends Partial<ComponentProps<typeof Container>> {
	Icon: ComponentType<{ readonly color?: string }>;
	onClick?: VoidFunction;
	isPressed?: boolean;
	color?: string;
	isVisible?: boolean;
}

const Container = styled.div<{
	readonly $margin?: string;
	readonly $padding?: string;
	readonly $isVisible: boolean;
	readonly $isPressed: boolean;
}>`
	background-color: ${(props) => (props.$isPressed ? colors.userProfileBackground : colors.dark)};
	box-shadow: ${(props) => (props.$isPressed ? 'inset 2px -2px 4px rgba(0, 0, 0, 0.25)' : 'inherited')};
	border-radius: 20px;
	height: 30px;
	width: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: ${(props) => props.$margin || 'initial'};
	padding: ${(props) => props.$padding || 'none'};
	cursor: pointer;
	display: ${(props) => (props.$isVisible ? 'block' : 'none')};
	pointer-events: ${(props) => (props.$isVisible ? 'all' : 'none')};
`;

export default forwardRef<HTMLDivElement, CoreSmallButtonProps>(function CoreSmallButton(
	{ Icon, children, onClick, color, isPressed = false, isVisible = true, ...other },
	ref,
) {
	return (
		<Container ref={ref} $isPressed={isPressed} $isVisible={isVisible} onClick={onClick} {...other}>
			{children && isVisible ? children : <Icon color={color} />}
		</Container>
	);
});
