export default function ClinicalSummaryTabIcon({
	width = '26',
	height = '28',
	greenDotOn = true,
}: {
	width?: string;
	height?: string;
	greenDotOn?: boolean;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.91248 13.7031V21.2719C7.91248 23.0029 9.31579 24.4063 11.0469 24.4063V24.4063C12.778 24.4063 14.1813 23.0029 14.1813 21.2719V16.481C14.1813 14.9468 15.425 13.7031 16.9591 13.7031V13.7031C18.4933 13.7031 19.737 14.9468 19.737 16.481V19.4664'
				stroke='#322B5F'
				strokeWidth='1.2'
			/>
			{greenDotOn && <ellipse cx='19.7367' cy='21.9363' rx='3.37844' ry='3.29327' fill='#78F5CE' />}
			<path
				d='M22.5151 21.9363C22.5151 23.4094 21.2857 24.6296 19.7367 24.6296C18.1877 24.6296 16.9583 23.4094 16.9583 21.9363C16.9583 20.4632 18.1877 19.2431 19.7367 19.2431C21.2857 19.2431 22.5151 20.4632 22.5151 21.9363Z'
				stroke='#322B5F'
				strokeWidth='1.2'
			/>
			<path
				d='M5.37844 4.64664H2.82332C2.36861 4.64664 2 5.01525 2 5.46996V8.21318C2 11.2452 4.45794 13.7031 7.48996 13.7031V13.7031C10.522 13.7031 12.9799 11.2452 12.9799 8.21318V5.46995C12.9799 5.01525 12.6113 4.64664 12.1566 4.64664H9.60148M5.37844 4.64664V6.29327M5.37844 4.64664V3M9.60148 4.64664V6.29327M9.60148 4.64664V3'
				stroke='#322B5F'
				strokeWidth='1.2'
			/>
		</svg>
	);
}
