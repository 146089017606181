import history from '../../../utils/history';
import { useNavinaData } from '../../../utils/useNavinaData';
import { ReactComponent as NavinaLogo } from './Logo.svg';
import styled from 'styled-components';

interface LogoProps {
	readonly width?: string;
	readonly height?: string;
}

const LogoContainer = styled.div<{ readonly $clickable: boolean } & LogoProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

	& > svg {
		width: ${({ width }) => width || 'auto'};
		height: ${({ height }) => height || 'auto'};
	}
`;

function Logo({ width = 'auto', height = '44px' }: LogoProps) {
	const { isFullScreen } = useNavinaData();
	const forwardToHomePage: boolean = isFullScreen;

	const handleLogoClick = (): void => {
		if (forwardToHomePage) {
			history.push('/');
		}
	};

	return (
		<LogoContainer $clickable={forwardToHomePage} width={width} height={height} onClick={handleLogoClick}>
			<NavinaLogo />
		</LogoContainer>
	);
}

export default Logo;
