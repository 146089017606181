export default function LabsIcon() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M25.3552 7.10662L15.8188 17.696L18.2533 19.8884L27.7896 9.29897L25.3552 7.10662Z' fill='#78F5CE' />
			<path
				d='M14 31C15.8571 31 17.6775 30.4829 19.2573 29.5065C20.8371 28.5302 22.1137 27.1332 22.9443 25.4721C23.7748 23.8111 24.1264 21.9516 23.9596 20.1019C23.7928 18.2523 23.1143 16.4857 22 15'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<path
				d='M24.4768 12C25.6821 13.2954 26.6144 14.8264 27.2166 16.4995C27.8189 18.1725 28.0784 19.9525 27.9795 21.7307C27.8805 23.5089 27.4251 25.2479 26.6411 26.8416C25.8571 28.4354 24.7609 29.8503 23.4195 31H27.9795H4'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			<path d='M15 22L11 18' stroke='#322B5F' strokeWidth='2' />
			<path
				d='M16.5923 19.646L16.5916 19.6452L13.3911 16.4251L13.3906 16.4246C13.273 16.3064 13.1764 16.1628 13.1086 16.0007C13.0408 15.8385 13.0036 15.6624 13.0002 15.4827C12.9969 15.303 13.0275 15.1253 13.0895 14.96C13.1514 14.7949 13.2428 14.6469 13.3564 14.5235L13.3567 14.5231L21.4373 5.74011C21.4374 5.73993 21.4376 5.73976 21.4377 5.73958C21.8631 5.27913 22.4337 5.01943 23.0248 5H23.0762C23.6638 5.00048 24.2373 5.23862 24.674 5.67896L24.6748 5.67971L26.2715 7.28613C26.2716 7.28625 26.2717 7.28636 26.2718 7.28647C26.7208 7.73929 26.9875 8.3693 26.9996 9.0408C27.0117 9.71252 26.7678 10.3537 26.3342 10.8251L26.3331 10.8262L18.2795 19.6102C18.2794 19.6103 18.2793 19.6104 18.2792 19.6105C18.1633 19.7368 18.0268 19.8345 17.8792 19.9005L18.2871 20.8135L17.8792 19.9005C17.7321 19.9662 17.5757 19.9996 17.4188 20C17.1189 19.9991 16.8219 19.8775 16.5923 19.646Z'
				stroke='#322B5F'
				strokeWidth='2'
			/>
		</svg>
	);
}
