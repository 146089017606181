export default function EstimatedRafScoreIcon() {
	return (
		<svg width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.5295 4.5881C22.3863 6.31319 23.1224 11.383 22.8844 13.779C20.7419 14.4978 11.6735 8.18204 12.3876 2.43173C13.5779 2.43173 16.6728 2.863 19.5295 4.5881Z'
				fill='#78F5CE'
			/>
			<path
				d='M22.0976 8.82718C22.6932 10.1472 23.0013 11.5698 23.0029 13.008C23.0048 15.6699 21.9518 18.2332 20.0568 20.1792C18.4532 21.8348 17.7201 22.7702 17.5368 24.4697H6.4762C6.2746 22.7614 5.5186 21.7777 3.98368 20.21C2.98007 19.1916 2.19979 17.9907 1.68902 16.6782C1.17826 15.3657 0.947378 13.9683 1.01005 12.5688C1.14024 9.97449 2.25879 7.51606 4.15376 5.65935C6.04873 3.80265 8.58847 2.67666 11.2917 2.49475C12.7964 2.39473 14.3064 2.59523 15.7261 3.08356C17.1457 3.57189 18.4441 4.33741 19.5391 5.33165C20.6313 6.31772 21.5019 7.5072 22.0976 8.82718Z'
				stroke='#322B5F'
				strokeWidth='1.2'
				strokeMiterlimit='10'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.11245 5.65951C6.66779 5.96938 6.24732 6.31376 5.85779 6.68556C4.94991 7.55213 4.19351 8.58386 3.68742 9.7008H4.80022C5.23656 8.87119 5.83385 8.09083 6.54824 7.40894C6.72982 7.23562 6.91818 7.06943 7.11245 6.91109V5.65951ZM3 12.7052C3.00224 12.1995 3.06354 11.6968 3.17656 11.203H4.20701C4.07439 11.7001 4.00266 12.2039 4.00001 12.7052H3ZM8.53086 5.95459V4.83149C9.4304 4.40129 10.3893 4.11329 11.3649 4.012V5.0183C10.3934 5.13455 9.42839 5.46344 8.53086 5.95459ZM12.7833 4C13.7654 4.08593 14.7249 4.36373 15.6217 4.78779V5.90897C14.7277 5.42246 13.7622 5.10355 12.7833 5.00451V4ZM17.0401 5.6142V6.86979C17.2452 7.03902 17.4434 7.21744 17.6336 7.40415C18.3289 8.08628 18.908 8.86831 19.3299 9.7008H20.4353C19.9476 8.58717 19.2174 7.5571 18.334 6.69035C17.9346 6.29845 17.5009 5.93699 17.0401 5.6142ZM20.0987 12.7052C20.0962 12.2035 20.0276 11.6998 19.9004 11.203H20.9286C21.0376 11.6971 21.0966 12.1998 21.0987 12.7052H20.0987Z'
				fill='#322B5F'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.5943 12.6583L19.6421 0L12.1384 12.1546C11.3419 12.2451 10.7266 12.8449 10.7266 13.5714C10.7266 14.3604 11.4523 15 12.3476 15C13.2429 15 13.9686 14.3604 13.9686 13.5714C13.9686 13.2242 13.828 12.9059 13.5943 12.6583Z'
				fill='#322B5F'
			/>
		</svg>
	);
}
