export default function HospitalNotes() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='6' y='10' width='20' height='9' fill='#78F5CE' />
			<mask id='path-2-inside-1' fill='white'>
				<rect x='5' y='4' width='23' height='26' rx='0.5' />
			</mask>
			<rect x='5' y='4' width='23' height='26' rx='0.5' stroke='#322B5F' strokeWidth='4' mask='url(#path-2-inside-1)' />
			<mask id='path-3-inside-2' fill='white'>
				<rect x='11' y='2' width='11' height='6' rx='0.5' />
			</mask>
			<rect
				x='11'
				y='2'
				width='11'
				height='6'
				rx='0.5'
				fill='#78F5CE'
				stroke='#322B5F'
				strokeWidth='4'
				mask='url(#path-3-inside-2)'
			/>
			<path d='M10 22H16' stroke='#322B5F' strokeWidth='2' />
			<path d='M10 25H21' stroke='#322B5F' strokeWidth='2' />
			<path
				d='M15 11H18V12V13H19H20V16H19H18V17V18H15V17V16H14H13V13L14 13H15V12V11Z'
				stroke='#322B5F'
				strokeWidth='2'
			/>
		</svg>
	);
}
