import HasPermission from '../../components/user-permissions/HasPermission';
import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { useAuthStore } from '../../stores/authStore';
import PopoverItem from './PopoverItem';
import PopoverItemSmall from './PopoverItemSmall';
import FileManagerIcon from './icons/FileManagerIcon';
import OrgDashboardIcon from './icons/OrgDashboardIcon';
import PatientPortraitIcon from './icons/PatientPortraitIcon';
import QualityOfCareIcon from './icons/QualityOfCareIcon';
import { Typography, theme } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden;
`;

const TitleContainer = styled.div`
	width: 100%;
	padding: 10px 14px;
`;

type PopoverContentProps = {
	selectedPage: string;
};

function PopoverContent({ selectedPage }: PopoverContentProps) {
	const { token } = theme.useToken();
	const authStore = useAuthStore();

	const isQualityClinic = useFeatureFlag('configuration_OrganizationQuality', false) || authStore.isAdmin;

	return (
		<Container>
			<TitleContainer>
				<Typography.Text strong style={{ color: token.colorPrimaryText, fontSize: token.fontSizeSM }}>
					Navina Applications
				</Typography.Text>
			</TitleContainer>
			<PopoverItem
				icon={<PatientPortraitIcon />}
				isSelected={selectedPage === 'patient-portrait'}
				title='Patient Portraits'
				description='Concise summaries and actionable insights for your patients'
				link='/'
				analyticsExtras={{ actionId: 'AppsMenuSchedule', SourcePage: selectedPage }}
			/>
			{isQualityClinic && (
				<HasPermission to='quality_tool'>
					<PopoverItem
						icon={<QualityOfCareIcon />}
						isSelected={selectedPage === 'quality'}
						title='Quality Workspace'
						description='Assess, manage and track patient care gaps'
						link='/quality'
						analyticsExtras={{ actionId: 'AppsMenuQuality', SourcePage: selectedPage }}
					/>
				</HasPermission>
			)}
			<HasPermission to='hcc_dashboard'>
				<PopoverItem
					icon={<OrgDashboardIcon />}
					isSelected={selectedPage === 'dashboard'}
					title='Navina Dashboard'
					description='Insights and performance tracking for your value-based care journey'
					link='/qs'
					analyticsExtras={{ actionId: 'AppMenuReporting', SourcePage: selectedPage }}
				/>
			</HasPermission>
			<HasPermission to='files_manager'>
				<PopoverItemSmall
					icon={<FileManagerIcon />}
					isSelected={selectedPage === 'files'}
					title='File manager'
					link='/files'
					analyticsExtras={{ actionId: 'AppMenuFileManager', SourcePage: selectedPage }}
				/>
			</HasPermission>
		</Container>
	);
}

export default PopoverContent;
