import { getOrCreateApiGateway } from '../server/apiGateway';
import type { AddonReportData } from '../types';
import { action, autorun, observable } from 'mobx';


const ADDON_PING_INTERVAL_TIME = 400;
const TOTAL_ADDON_PING_ATTEMPTS = 10;

type AddonResponseDataType = {
	version: number;
	emr: string;
};

class AddonStore {
	// Addon
	@observable version: number | undefined = undefined;
	@observable emr: string | undefined = undefined;

	// Can communicate?
	@observable installed: boolean | undefined = undefined;

	// Extra analytics
	@observable extraAnalytics: any = {};

	// Loaders
	@observable addonLoading = false;
	@observable apiLoading = false;
	@observable reportReadyToBeSent = false;

	// Total responses
	@observable responsesFromAddons: Event[] = [];

	constructor() {
		autorun((): void => {
			document.addEventListener('navina-frontend-healthcheck-plugin-data', (event): void => {
				if (this.responsesFromAddons.length <= 0) {
					this.receivedResponseFromAddon(event as CustomEvent);
				}

				// count to detect 2 plugins or more
				this.setResponsesFromAddons([...this.responsesFromAddons, event]);
			});
			this.detectAddon();
		});
	}

	@action setVersion = (version: typeof this.version): void => {
		this.version = version;
	};

	@action setEmr = (emr: typeof this.emr): void => {
		this.emr = emr;
	};

	@action setInstalled = (installed: typeof this.installed): void => {
		this.installed = installed;
		if (this.isReadyToBeSent()) {
			this.setReportReadyToBeSend(true);
		}
	};

	@action setExtraAnalytics = (extraAnalytics: typeof this.extraAnalytics): void => {
		this.extraAnalytics = extraAnalytics;
	};

	@action setAddonLoading = (addonLoading: typeof this.addonLoading): void => {
		this.addonLoading = addonLoading;

		if (this.isReadyToBeSent()) {
			this.setReportReadyToBeSend(true);
		}
	};

	@action setApiLoading = (apiLoading: typeof this.apiLoading): void => {
		this.apiLoading = apiLoading;

		if (this.isReadyToBeSent()) {
			this.setReportReadyToBeSend(true);
		}
	};

	@action setReportReadyToBeSend = (readyToBeSent: typeof this.reportReadyToBeSent): void => {
		this.reportReadyToBeSent = readyToBeSent;
	};

	@action setResponsesFromAddons = (responsesFromAddons: typeof this.responsesFromAddons): void => {
		this.responsesFromAddons = responsesFromAddons;
	};

	// Detect injected addon
	@action detectAddon = (): void => {
		if (!this.addonLoading) {
			this.setAddonLoading(true);
			this.sendEventInvokeAttempt(TOTAL_ADDON_PING_ATTEMPTS, TOTAL_ADDON_PING_ATTEMPTS, (): void => {
				// Failed callback
				this.setInstalled(false);
				this.setAddonLoading(false);
			});
		}
	};

	@action submitReportToSlack = (username?: string, identifier?: string): void => {
		let { emr } = this;
		if (this.responsesFromAddons.length > 1) {
			emr = `${emr} (🆘 ${this.responsesFromAddons.length})`;
		}

		getOrCreateApiGateway().addonHealthSubmitReport({
			...this.toJson(),
			emr,
			identifier,
			username,
		});
	};

	// Invoke with delay X attempts
	// Inspired by: https://stackoverflow.com/a/3583740/12595469
	private sendEventInvokeAttempt = (i, originMax, failedCallback): void => {
		window.setTimeout((): void => {
			if (this.addonLoading) {
				// if still addonLoading, dispatch new attempts
				// console.log('▶ Addon communicate attempt', originMax - i + 1, 'out of', originMax);
				document.dispatchEvent(new Event('navina-frontend-healthcheck-invoke'));
			} else {
				// otherwise already succeeded
				return;
			}

			if (--i) {
				this.sendEventInvokeAttempt(i, originMax, failedCallback);
			} else {
				failedCallback();
			}
		}, ADDON_PING_INTERVAL_TIME);
	};

	// Addon sent event as response
	private receivedResponseFromAddon = (customEvent: CustomEvent): void => {
		const addonData = customEvent.detail as AddonResponseDataType;

		console.log('▶ Response from addon', addonData);
		if (addonData) {
			const { version, emr, ...extraAnalytics } = addonData;
			this.setInstalled(true);

			this.setVersion(version);
			this.setEmr(emr);
			this.setExtraAnalytics(extraAnalytics);
		}

		this.setAddonLoading(false);
	};

	toJson = (): AddonReportData => {
		return {
			installed: this.installed,
			version: this.version,
			emr: this.emr,
			...this.extraAnalytics,
		};
	};

	isStillLoading = (): boolean => this.apiLoading || this.addonLoading;

	isReadyToBeSent = (): boolean => !this.isStillLoading() && this.installed !== undefined;
}

let addonStore: AddonStore | null = null;

function createAddonStore(): AddonStore {
	return new AddonStore();
}

export function useAddonStore(): AddonStore {
	if (addonStore === null) {
		addonStore = createAddonStore();
	}
	return addonStore;
}
