export default function DocumentsTabIcon({
	width = '26',
	height = '28',
	greenDotOn = true,
}: {
	width?: string;
	height?: string;
	greenDotOn?: boolean;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			{greenDotOn && <path d='M12.75 5H21.5V13.125L12.75 5Z' fill='#78F5CE' />}
			<path
				d='M21.5 13.75V6C21.5 5.44772 21.0523 5 20.5 5H12.75M21.5 13.75V21.5C21.5 22.0523 21.0523 22.5 20.5 22.5H5C4.44772 22.5 4 22.0523 4 21.5V6C4 5.44772 4.44772 5 5 5H12.75M21.5 13.75L12.75 5'
				stroke='#322B5F'
				strokeWidth='1.2'
			/>
			<path d='M7.75 12.5H15.25' stroke='#322B5F' strokeWidth='1.2' />
			<path d='M7.75 16.25H15.25' stroke='#322B5F' strokeWidth='1.2' />
		</svg>
	);
}
