import { MAX_WIDTH_LOWER_WIDTH } from '.';
import { getOrCreateAnalytics } from '../utils/analytics';
import colors from './Colors';
import InfoIcon from './icons/InfoIcon';
import { Popover, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	/* Higher specificity to override global style which is set by the .in-app-explanation-icon-wrapper from main.css */
	&[class] {
		transition: 0.1s ease-in;
		width: fit-content;
		height: fit-content;
		cursor: default;
		display: inline-block;
	}

	&:hover {
		transform: scale(1.2);
	}
`;

const StyledSvg = styled.svg<{ readonly $size: 'small' | 'large' }>`
	height: ${(props) => (props.$size === 'small' ? 28 : 35)}px;
	width: ${(props) => (props.$size === 'small' ? 28 : 35)}px;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		height: 28px;
		width: 28px;
	}
`;

interface InAppExplanationProps extends ComponentProps<typeof Container> {
	id?: string;
	size: 'small' | 'large';
	color: 'white' | 'background' | 'primary';
	PopupContentComponent?: any;
	IconState?: 'question mark' | 'info';
	explanationText?: string;
}

export default observer(function InAppExplanation({
	id,
	size,
	color,
	PopupContentComponent,
	IconState = 'question mark',
	explanationText = '',
	...props
}: InAppExplanationProps) {
	const analytics = getOrCreateAnalytics();

	const handleOpenChange = (visible: boolean): void => {
		analytics.track(analytics.idsNames.InAppExplanationPopupView, { actionId: visible ? 'Open' : 'Close', id });
	};

	let iconContent = (
		<StyledSvg viewBox='0 0 37 37' fill='none' xmlns='http://www.w3.org/2000/svg' id={id} $size={size}>
			<g filter={`url('#filter0_d${id}')`}>
				<circle
					cx='18.5'
					cy='18.5'
					r='10.5'
					fill={color === 'white' ? 'white' : color === 'background' ? colors.background : colors.primary}
				/>
			</g>
			<path
				d='M17.8183 21.06C17.8183 20.6773 17.879 20.3413 18.0003 20.052C18.131 19.7627 18.2897 19.5153 18.4763 19.31C18.663 19.1047 18.9057 18.8713 19.2043 18.61C19.6057 18.2553 19.8997 17.9473 20.0863 17.686C20.2823 17.4153 20.3803 17.0887 20.3803 16.706C20.3803 16.1927 20.1797 15.782 19.7783 15.474C19.377 15.1567 18.8263 14.998 18.1263 14.998C16.969 14.998 16.0777 15.376 15.4523 16.132L14.7103 15.572C15.5037 14.6013 16.6657 14.116 18.1963 14.116C19.1763 14.116 19.9557 14.3353 20.5343 14.774C21.1223 15.2127 21.4163 15.8053 21.4163 16.552C21.4163 16.9347 21.351 17.2753 21.2203 17.574C21.099 17.8633 20.945 18.1107 20.7583 18.316C20.581 18.5213 20.343 18.7593 20.0443 19.03C19.643 19.394 19.3443 19.7207 19.1483 20.01C18.9523 20.29 18.8543 20.64 18.8543 21.06H17.8183ZM18.3363 24.07C18.131 24.07 17.9583 24 17.8183 23.86C17.6783 23.7107 17.6083 23.538 17.6083 23.342C17.6083 23.1367 17.6783 22.9687 17.8183 22.838C17.9583 22.698 18.131 22.628 18.3363 22.628C18.551 22.628 18.7283 22.698 18.8683 22.838C19.0083 22.9687 19.0783 23.1367 19.0783 23.342C19.0783 23.538 19.0037 23.7107 18.8543 23.86C18.7143 24 18.5417 24.07 18.3363 24.07Z'
				fill={color === 'primary' ? 'white' : colors.tertiary}
			/>
			<defs>
				<filter
					id={`filter0_d${id}`}
					x='4'
					y='4'
					width='29'
					height='29'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
					<feOffset />
					{color === 'primary' ? (
						<>
							<feGaussianBlur stdDeviation='1.5' />
							<feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0' />
						</>
					) : (
						<>
							<feGaussianBlur stdDeviation='1.5' />
							<feColorMatrix type='matrix' values='0 0 0 0 0.196078 0 0 0 0 0.168627 0 0 0 0 0.372549 0 0 0 0.6 0' />
						</>
					)}
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
				</filter>
			</defs>
		</StyledSvg>
	);

	if (IconState === 'info') {
		iconContent = <InfoIcon />;
	}

	let tooltip: ReactElement;
	if (PopupContentComponent) {
		tooltip = (
			<Popover content={PopupContentComponent} onOpenChange={handleOpenChange} trigger='click'>
				{iconContent}
			</Popover>
		);
	} else {
		tooltip = (
			<Tooltip title={explanationText} onOpenChange={handleOpenChange} placement='right'>
				{iconContent}
			</Tooltip>
		);
	}

	return (
		<Container {...props} data-appcues-id={id} className='in-app-explanation-icon-wrapper'>
			{tooltip}
		</Container>
	);
});
