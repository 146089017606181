import Colors from './Colors';
import { Tooltip } from '@material-ui/core';
import type { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { type ComponentProps } from 'react';
import styled from 'styled-components';

interface WithMaxWidthAndTextAlignStyleProps {
	readonly $maxWidth?: string;
	readonly $textAlign?: string;
}

const StyledTooltip = styled((props: ComponentProps<typeof Tooltip> & WithMaxWidthAndTextAlignStyleProps) => (
	<Tooltip classes={{ popper: props.className }} {...props} />
))`
	& .MuiTooltip-tooltip {
		background-color: ${Colors.dark};
		color: ${Colors.white};
		font-size: 12px;
		font-family: 'Inter', sans-serif;
		margin: 4px 0;
		box-shadow: 0 4px 12px rgba(87, 96, 134, 0.2);
		max-width: ${(props) => props.$maxWidth};
		text-align: ${(props) => props.$textAlign};
	}
`;

const StyledTooltipSpan = styled.span`
	display: inline-block;
`;

interface CoreTooltipProps extends TooltipProps, WithMaxWidthAndTextAlignStyleProps {}

const CoreTooltip = ({ children, $maxWidth = '220px', $textAlign = 'initial', ...props }: CoreTooltipProps) => (
	<StyledTooltip $maxWidth={$maxWidth} $textAlign={$textAlign} {...props}>
		<StyledTooltipSpan>{children}</StyledTooltipSpan>
	</StyledTooltip>
);

export default CoreTooltip;
