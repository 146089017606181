export default function GoogleLogo() {
	return (
		<svg width='60' height='20' viewBox='0 0 60 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0)'>
				<path
					d='M7.49385 7.12465V9.26471H12.3119C12.2374 10.268 11.8505 11.2166 11.212 11.9615C10.7292 12.4849 10.1479 12.8947 9.50596 13.1645C8.86398 13.4343 8.17564 13.5579 7.48564 13.5274C6.78413 13.5264 6.08982 13.3776 5.44306 13.0896C4.79629 12.8017 4.20996 12.3804 3.71814 11.8502C3.22632 11.3201 2.83881 10.6916 2.57815 10.0013C2.31748 9.31103 2.18885 8.57273 2.19973 7.82931C2.19103 7.08661 2.32155 6.34947 2.58371 5.66064C2.84587 4.97181 3.23446 4.345 3.72693 3.81657C4.2194 3.28813 4.80596 2.86858 5.45259 2.58226C6.09922 2.29593 6.79305 2.14852 7.49385 2.14858C8.84913 2.12978 10.1564 2.68023 11.13 3.67968L12.5499 2.17467C11.8886 1.47029 11.0999 0.914617 10.2311 0.540916C9.36231 0.167214 8.43131 -0.016826 7.49385 -0.000184982C5.5247 -0.00039656 3.63461 0.820867 2.23227 2.28602C0.829927 3.75117 0.0280381 5.74246 0 7.82931C0.0280381 9.91615 0.829927 11.9074 2.23227 13.3726C3.63461 14.8377 5.5247 15.659 7.49385 15.6588C8.45042 15.7004 9.40444 15.5265 10.293 15.1486C11.1815 14.7707 11.9843 14.1974 12.6484 13.4665C13.8241 12.1044 14.4514 10.3095 14.3967 8.46437C14.4025 8.01517 14.3668 7.56645 14.29 7.12465H7.49385Z'
					fill='#4285F4'
				/>
				<path
					d='M20.4295 5.38492C19.7994 5.3732 19.1736 5.4958 18.5891 5.74543C18.0046 5.99507 17.4734 6.36665 17.027 6.83811C16.5807 7.30957 16.2283 7.8713 15.9907 8.48992C15.7532 9.10855 15.6354 9.77147 15.6443 10.4393C15.6443 11.7844 16.1484 13.0744 17.0458 14.0256C17.9432 14.9767 19.1604 15.5111 20.4295 15.5111C21.6986 15.5111 22.9158 14.9767 23.8132 14.0256C24.7106 13.0744 25.2147 11.7844 25.2147 10.4393C25.2236 9.77147 25.1058 9.10855 24.8683 8.48992C24.6307 7.8713 24.2783 7.30957 23.8319 6.83811C23.3856 6.36665 22.8544 5.99507 22.2699 5.74543C21.6854 5.4958 21.0596 5.3732 20.4295 5.38492ZM20.4295 13.5015C20.0616 13.4927 19.699 13.4059 19.3632 13.2462C19.0275 13.0866 18.7252 12.8573 18.4743 12.5719C18.2234 12.2866 18.029 11.9508 17.9024 11.5846C17.7758 11.2183 17.7197 10.8289 17.7373 10.4393C17.7083 10.0458 17.7564 9.65029 17.8785 9.27748C18.0006 8.90466 18.1942 8.56265 18.447 8.27293C18.6999 7.9832 19.0065 7.75202 19.3476 7.59392C19.6888 7.43581 20.0571 7.3542 20.4295 7.3542C20.8018 7.3542 21.1702 7.43581 21.5113 7.59392C21.8525 7.75202 22.1591 7.9832 22.412 8.27293C22.6648 8.56265 22.8584 8.90466 22.9805 9.27748C23.1026 9.65029 23.1507 10.0458 23.1217 10.4393C23.1393 10.8289 23.0832 11.2183 22.9566 11.5846C22.83 11.9508 22.6356 12.2866 22.3847 12.5719C22.1338 12.8573 21.8315 13.0866 21.4957 13.2462C21.16 13.4059 20.7974 13.4927 20.4295 13.5015Z'
					fill='#EA4335'
				/>
				<path
					d='M43.8879 6.51585H43.814C43.4964 6.14748 43.1077 5.85584 42.6743 5.66071C42.2409 5.46558 41.7731 5.37152 41.3024 5.38492C40.0809 5.43482 38.9275 5.99381 38.0929 6.94032C37.2582 7.88683 36.8099 9.14431 36.8455 10.4393C36.8121 11.7337 37.2611 12.9898 38.0953 13.9359C38.9295 14.8819 40.0817 15.4415 41.3024 15.4937C41.7743 15.5049 42.243 15.4081 42.6764 15.2099C43.1098 15.0118 43.4979 14.7169 43.814 14.3453H43.8879V15.05C43.8879 16.9813 42.9111 18.0165 41.3434 18.0165C40.8192 18.003 40.3107 17.8246 39.8824 17.504C39.4542 17.1833 39.1256 16.7349 38.9385 16.2157L37.1163 17.0161C37.4587 17.905 38.0442 18.6651 38.7975 19.1984C39.5509 19.7317 40.4375 20.0139 41.3434 20.0087C43.8058 20.0087 45.8742 18.4776 45.8742 14.789V5.6459H43.8879V6.51585ZM41.483 13.4754C41.1259 13.459 40.7756 13.3667 40.4529 13.204C40.1301 13.0413 39.8415 12.8115 39.6042 12.5284C39.3669 12.2452 39.1856 11.9143 39.0713 11.5554C38.9569 11.1966 38.9118 10.817 38.9385 10.4393C38.9071 10.0592 38.9488 9.67638 39.0612 9.31393C39.1735 8.95147 39.3541 8.61696 39.592 8.33065C39.8299 8.04434 40.1201 7.8122 40.4452 7.6483C40.7702 7.48439 41.1233 7.39212 41.483 7.37709C41.8415 7.39572 42.1928 7.49039 42.5162 7.65549C42.8396 7.82058 43.1285 8.05276 43.3658 8.33828C43.603 8.62379 43.7838 8.95684 43.8974 9.31773C44.0111 9.67861 44.0553 10.06 44.0274 10.4393C44.0588 10.8193 44.0171 11.2022 43.9047 11.5647C43.7924 11.9271 43.6118 12.2616 43.3739 12.5479C43.136 12.8342 42.8458 13.0664 42.5207 13.2303C42.1957 13.3942 41.8426 13.4865 41.483 13.5015V13.4754Z'
					fill='#4285F4'
				/>
				<path
					d='M31.0999 5.38492C30.4698 5.3732 29.844 5.4958 29.2595 5.74543C28.675 5.99507 28.1438 6.36665 27.6974 6.83811C27.2511 7.30957 26.8987 7.8713 26.6611 8.48992C26.4236 9.10855 26.3058 9.77147 26.3147 10.4393C26.3147 11.7844 26.8188 13.0744 27.7162 14.0256C28.6136 14.9767 29.8308 15.5111 31.0999 15.5111C32.369 15.5111 33.5862 14.9767 34.4836 14.0256C35.381 13.0744 35.8851 11.7844 35.8851 10.4393C35.894 9.77147 35.7762 9.10855 35.5387 8.48992C35.3011 7.8713 34.9487 7.30957 34.5024 6.83811C34.056 6.36665 33.5248 5.99507 32.9403 5.74543C32.3558 5.4958 31.73 5.3732 31.0999 5.38492ZM31.0999 13.5015C30.732 13.4927 30.3694 13.4059 30.0336 13.2462C29.6979 13.0866 29.3956 12.8573 29.1447 12.5719C28.8938 12.2866 28.6994 11.9508 28.5728 11.5846C28.4462 11.2183 28.3901 10.8289 28.4077 10.4393C28.3787 10.0458 28.4268 9.65029 28.5489 9.27748C28.671 8.90466 28.8646 8.56265 29.1174 8.27293C29.3703 7.9832 29.6769 7.75202 30.0181 7.59392C30.3592 7.43581 30.7276 7.3542 31.0999 7.3542C31.4723 7.3542 31.8406 7.43581 32.1817 7.59392C32.5229 7.75202 32.8295 7.9832 33.0824 8.27293C33.3352 8.56265 33.5288 8.90466 33.6509 9.27748C33.773 9.65029 33.8211 10.0458 33.7921 10.4393C33.8097 10.8289 33.7536 11.2183 33.627 11.5846C33.5004 11.9508 33.306 12.2866 33.0551 12.5719C32.8042 12.8573 32.5019 13.0866 32.1662 13.2462C31.8304 13.4059 31.4678 13.4927 31.0999 13.5015Z'
					fill='#FBBC05'
				/>
				<path d='M47.5157 0.20874H49.5759V15.4936H47.5157V0.20874Z' fill='#34A853' />
				<path
					d='M55.937 13.5015C55.4518 13.5165 54.9732 13.3792 54.5613 13.1068C54.1495 12.8343 53.8229 12.439 53.6224 11.9704L60 9.17784L59.7866 8.60368C59.4991 7.68416 58.9504 6.88132 58.2158 6.3051C57.4811 5.72889 56.5963 5.40747 55.6826 5.38489C55.0719 5.3922 54.4689 5.5303 53.9098 5.7909C53.3507 6.0515 52.8471 6.4292 52.4293 6.90132C52.0115 7.37344 51.688 7.93022 51.4783 8.53821C51.2687 9.1462 51.1771 9.79283 51.2093 10.4393C51.1949 11.1035 51.307 11.764 51.539 12.3813C51.7709 12.9986 52.118 13.5599 52.5593 14.0317C53.0007 14.5036 53.5273 14.8762 54.1077 15.1274C54.688 15.3786 55.3102 15.5032 55.937 15.4936C56.7232 15.4983 57.4982 15.2966 58.1928 14.9065C58.8874 14.5163 59.4801 13.9499 59.9179 13.2579L58.2763 12.1096C58.0315 12.5364 57.6866 12.8889 57.2753 13.1328C56.864 13.3766 56.4001 13.5037 55.9288 13.5015H55.937ZM55.8139 7.28136C56.1849 7.25798 56.5541 7.35117 56.8754 7.54926C57.1966 7.74734 57.4555 8.04151 57.6197 8.39489L53.3105 10.2827C53.2778 9.90896 53.3177 9.53212 53.4277 9.17536C53.5377 8.81861 53.7154 8.4895 53.9499 8.20832C54.1845 7.92713 54.4709 7.69983 54.7914 7.54041C55.112 7.38099 55.46 7.29283 55.8139 7.28136Z'
					fill='#EA4335'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='60' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
