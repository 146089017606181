import Colors from '../Colors';
import { CoreTooltip } from '../index';
import { type SyntheticEvent, useState } from 'react';

export default function InformationIcon({
	width = 16,
	height = 16,
	idleFillColor = Colors.tertiary,
	hoverFillColor = Colors.docLabel,
	tooltipTitle,
	handleOnOpen = function noop(_e: SyntheticEvent) {},
	...props
}) {
	const [infoTooltipIsOpen, setInfoTooltipIsOpen] = useState(false);

	const handleOnOpenEvent = (e): void => {
		setInfoTooltipIsOpen(true);
		handleOnOpen(e);
	};

	return (
		<CoreTooltip
			title={tooltipTitle}
			placement='top'
			onOpen={handleOnOpenEvent}
			onClose={(): void => setInfoTooltipIsOpen(false)}
			{...props}
		>
			<svg
				width={width}
				height={height}
				fill={infoTooltipIsOpen ? hoverFillColor : idleFillColor}
				xmlns='http://www.w3.org/2000/svg'
			>
				<path fillRule='evenodd' clipRule='evenodd' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9 7v5H7V7h2ZM7 6V4h2v2H7Z' />
			</svg>
		</CoreTooltip>
	);
}
