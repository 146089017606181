import { CoreText } from '.';
import { capitalizeFirstLetter } from '../utils/strings';
import CoreInvisibleColon from './CoreInvisibleColon';
import type { ReactNode } from 'react';
import styled from 'styled-components';

type CoreValueEntityProps = {
	field: string | ReactNode;
	subField?: string;
	value?: string;
	children?: ReactNode;
};

const FieldValueTable = styled.table`
	border: 0;
	display: flex;

	&,
	> tbody {
		inline-size: 100%;
		max-inline-size: 100%;
	}
`;

const TableRow = styled.tr`
	max-inline-size: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const TableCell = styled.td<{ readonly $width?: string; readonly span?: number }>`
	vertical-align: top;
	max-inline-size: 100%;
	inline-size: ${(props) => props.$width || 'auto'};
	border: 0;
`;

export default function CoreValueEntity({ field, subField, value, children }: CoreValueEntityProps) {
	return (
		<FieldValueTable>
			<tbody>
				<TableRow>
					<TableCell $width='18%'>
						<CoreText type='body1'>
							{typeof field === 'string' ? capitalizeFirstLetter(field) : field}
							<CoreInvisibleColon />
						</CoreText>
					</TableCell>
					{subField && (
						<TableCell>
							<CoreText type='body1'>{capitalizeFirstLetter(subField)}</CoreText>
						</TableCell>
					)}
					<TableCell span={!subField ? 2 : 1}>{value ? <CoreText type='body1'>{value}</CoreText> : children}</TableCell>
				</TableRow>
			</tbody>
		</FieldValueTable>
	);
}
