import { featureFlag } from '../../../config/FeatureFlag';
import { useState } from 'react';

export function useFeatureFlag<T = any>(key: string, defaultValue: T): T {
	const [state, setState] = useState<T>(defaultValue);

	const { client } = featureFlag();
	if (client) {
		client
			.waitForInitialization()
			.then((): void => {
				const value = featureFlag().getFeatureValue<T>(key, defaultValue);
				setState(value);
			})
			.catch((err): void => console.error(`error initializing LDClient`, err));
	}

	return state;
}
