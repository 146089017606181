export default function CompanionButtonIcon({ width = '20', height = '16' }: { width?: string; height?: string }) {
	return (
		<svg width={width} height={height} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.48861 9.78134L0.655273 8.01067L2.48861 6.25067L4.33305 8.01067L2.48861 9.78134ZM2.01083 8.01067L2.48861 8.53334L2.9775 8.064L2.48861 7.60534L2.01083 8.01067Z'
				fill='#322B5F'
			/>
			<path d='M2.97741 9.15198H2.01074V12.8533H2.97741V9.15198Z' fill='#322B5F' />
			<path
				d='M7.04464 14.1547L5.2002 12.384L7.04464 10.6667L8.88909 12.384L7.04464 14.1547ZM6.56686 12.384L7.04464 12.8427L7.52242 12.384L7.04464 11.9254L6.56686 12.384Z'
				fill='#322B5F'
			/>
			<path d='M5.8663 11.9254H2.01074V12.8534H5.8663V11.9254Z' fill='#322B5F' />
			<path
				d='M18.1777 9.86664H10.7111C10.4429 9.86759 10.1796 9.93606 9.94762 10.0652C9.71561 10.1944 9.52294 10.3797 9.38885 10.6026L10.8333 11.9893V11.4666H18.0555V14.1333H10.8333V12.8L9.17773 14.368C9.20839 14.7387 9.38303 15.0848 9.66705 15.3377C9.95106 15.5906 10.3237 15.7318 10.7111 15.7333H18.1777C18.5873 15.7333 18.9802 15.5771 19.2698 15.299C19.5595 15.021 19.7222 14.6439 19.7222 14.2506V11.3493C19.7222 11.1546 19.6822 10.9618 19.6046 10.7819C19.527 10.602 19.4132 10.4386 19.2698 10.3009C19.1264 10.1632 18.9562 10.054 18.7688 9.9795C18.5814 9.90499 18.3806 9.86664 18.1777 9.86664Z'
				fill='#322B5F'
			/>
			<path
				d='M18.1779 0.266663H1.82232C1.41271 0.266663 1.01988 0.422872 0.730236 0.700926C0.440597 0.97898 0.277879 1.3561 0.277879 1.74933V4.65066C0.272403 4.84814 0.308999 5.04461 0.385423 5.22803C0.461848 5.41145 0.576491 5.57795 0.722323 5.71733L1.94455 4.544V1.86666H18.0557V4.53333H3.06677L4.73343 6.13333H18.1779C18.5875 6.13333 18.9803 5.97712 19.27 5.69907C19.5596 5.42101 19.7223 5.04389 19.7223 4.65066V1.74933C19.7223 1.3561 19.5596 0.97898 19.27 0.700926C18.9803 0.422872 18.5875 0.266663 18.1779 0.266663V0.266663Z'
				fill='#322B5F'
			/>
		</svg>
	);
}
