import moment from 'moment';
import styled from 'styled-components';

type CoreDateProps = {
	date: Date;
	fallbackText?: string;
	showDay?: boolean;
};

const Container = styled.span`
	text-align: center;
	min-width: 55px;
	display: inline-block;
`;

const DateWrapper = styled.span`
	display: inline-block;
	font-size: 1em;
	white-space: nowrap;
	& > * {
		display: inline-block;
	}

	& > span ~ span {
		margin-inline-start: 0.1em;
	}
`;

const DateEntity = styled.span<{ $minWidth: number; $maxWidth: number; $letterSpacing: number }>`
	min-width: ${(props) => props.$minWidth}em;
	max-width: ${(props) => props.$maxWidth}em;
	letter-spacing: ${(props) => props.$letterSpacing}em;
`;

const CoreDate = ({ date, fallbackText = '-', showDay = false }: CoreDateProps) => {
	// Check if invalid date
	if (Number.isNaN(date.getTime())) {
		return <Container>{fallbackText}</Container>;
	}

	const momentDate = moment(date);
	const day = momentDate.format('DD');
	const month = momentDate.format('MM');
	const year = momentDate.format('YYYY');

	return (
		<Container>
			<DateWrapper>
				<DateEntity $minWidth={1.4} $maxWidth={1.4} $letterSpacing={montserratFont.getLetterSpacing(month)}>
					{month}
				</DateEntity>
				<span>/</span>
				{showDay && (
					<>
						<DateEntity $minWidth={1.4} $maxWidth={1.4} $letterSpacing={montserratFont.getLetterSpacing(day)}>
							{day}
						</DateEntity>
						<span>/</span>
					</>
				)}
				<DateEntity $minWidth={2.4} $maxWidth={2.4} $letterSpacing={montserratFont.getLetterSpacing(year)}>
					{year}
				</DateEntity>
			</DateWrapper>
		</Container>
	);
};

export default CoreDate;

class MontserratFont {
	readonly montserratFontDigitsWidth = {
		0: 19,
		1: 13.5,
		2: 17,
		3: 17,
		4: 20,
		5: 17,
		6: 18,
		7: 18,
		8: 19,
		9: 18,
	} as const;

	widestDigit: number = Object.keys(this.montserratFontDigitsWidth).reduce((acc, digit) => {
		if (this.montserratFontDigitsWidth[digit] > acc) {
			return this.montserratFontDigitsWidth[digit];
		}
		return acc;
	}, 0);

	getLetterSpacing = (number: string): number => {
		const maxWidth = this.widestDigit * number.length;
		const maxWidthEm = 0.9;
		const numberWidth = number.split('').reduce((acc, digit) => acc + this.montserratFontDigitsWidth[digit], 0);

		return Math.max(maxWidthEm - numberWidth / maxWidth, 0);
	};
}

const montserratFont = new MontserratFont();
