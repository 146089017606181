export default function MobilePhone() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='12' y='6' width='9' height='14' fill='#78F5CE' />
			<path d='M15 26H18' stroke='#322B5F' strokeWidth='2' />
			<path d='M9 23H24' stroke='#322B5F' strokeWidth='2' />
			<path
				d='M24 16.5V28C24 29.1046 23.1046 30 22 30H11C9.89543 30 9 29.1046 9 28V5C9 3.89543 9.89543 3 11 3H22C23.1046 3 24 3.89543 24 5V17.3182'
				stroke='#322B5F'
				strokeWidth='2'
			/>
		</svg>
	);
}
