type NoteIconProps = {
	cursor?: string;
	color?: string;
};

export default function NoteIcon({ cursor = 'inherited', color = '#322B5F' }: NoteIconProps) {
	return (
		<svg cursor={cursor} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.3267 15.0789C14.3267 15.7951 13.7439 16.3416 13.0482 16.3416H2.93303C2.21854 16.3416 1.6546 15.7762 1.6546 15.0789V4.90232C1.6546 4.20506 2.23741 3.63966 2.93303 3.63966H8.10344L9.73914 2.00013L2.933 2C1.31607 2 0 3.30038 0 4.90229V15.0789C0 16.6996 1.31607 18 2.933 18H13.067C14.6839 18 16 16.6996 16 15.0789V8.23795L14.3643 9.87749V15.0789L14.3267 15.0789Z'
				fill={color}
			/>
			<path
				d='M17.654 2.57108L15.4426 0.380294C15.2069 0.144875 14.8807 0 14.5544 0C14.2099 0 13.9018 0.126805 13.6661 0.362091L5.50922 8.50987C5.29174 8.7271 5.16479 9.01684 5.14672 9.32461L5.0017 11.6602C4.98362 12.0223 5.11044 12.3844 5.36419 12.6379C5.59988 12.8733 5.92608 13 6.25242 13H6.32487L8.68135 12.8554C8.98949 12.8373 9.27953 12.7105 9.49701 12.4933L17.6358 4.36363C17.8715 4.12821 17.9983 3.80237 17.9983 3.47639C18.0164 3.13234 17.8896 2.80649 17.654 2.57108ZM12.4517 3.82043L14.21 5.57673L10.1315 9.63251L8.37327 7.87621L12.4517 3.82043ZM8.4639 11.2983L6.59688 11.4069L6.70562 9.54196L7.23122 9.01695L8.98949 10.7732L8.4639 11.2983ZM15.3157 4.43604L13.5756 2.69792L14.5544 1.72025L16.3127 3.47655L15.3157 4.43604Z'
				fill={color}
			/>
			<rect
				x='13.5253'
				y='2.74985'
				width='1.56958'
				height='2.5477'
				transform='rotate(-44.1744 13.5253 2.74985)'
				fill='#78F5CE'
			/>
		</svg>
	);
}
