import { type AuthStore, useAuthStore } from '../../stores/authStore';

type PermissionType =
	| 'hcc_dashboard'
	| 'quality_tool'
	| 'files_manager'
	| 'debug_panel'
	| 'view_only'
	| 'quality_files_ingestion_tool';

type HasPermissionProps = {
	to: PermissionType;
	children: any;
	allowAlsoToAdmin?: boolean;
};

function handlePotentialStringBoolean(value: string | boolean): boolean {
	if (typeof value === 'string') {
		return value.toLowerCase() === true.toString();
	}
	return value;
}

export function checkIfHasPermission(authStore: AuthStore, to: PermissionType, allowAlsoToAdmin = true): boolean {
	return handlePotentialStringBoolean(authStore.permissions[to]) || (allowAlsoToAdmin && authStore.isAdmin);
}

// By default, we allow admins to view all components (allowAlsoToAdmin = true)
const HasPermission = ({ to, allowAlsoToAdmin = true, children }: HasPermissionProps) => {
	const authStore = useAuthStore();
	return <>{checkIfHasPermission(authStore, to, allowAlsoToAdmin) && children}</>;
};

export default HasPermission;
