import { CoreText, CoreTooltip } from './';
import Colors from './Colors';
import CoreExpanderIcon from './CoreExpanderIcon';
import type { TextColorType, CoreTextType } from './CoreTextTypes';
import { MAX_WIDTH_LOWER_WIDTH } from './Resolutions';
import InformationIcon from './icons/InformationIcon';
import { TableSortLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StylesProvider } from '@material-ui/core/styles';
import TableContainer from '@mui/material/TableContainer';
import type { SyntheticEvent, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

export type CoreTableColumnType = {
	name: string;
	headerStyle?: object;
	renderer: (data: any) => ReactNode;
	width?: string;
	vertical?: string;
	padding?: string;
	sortable?: boolean;
	columnDescription?: ReactElement;
	informationTooltipMaxWidth?: string;
	isDataExist?: boolean;
	notAvailableTooltipMessage?: string;
	displayDocItem?: string;
	columnType?: 'string' | 'date';
	sortOrder?: 'asc' | 'desc';
};

type CoreTableProps = {
	columns: CoreTableColumnType[];
	rows: any[];
	showHeader?: boolean;
	$hideHrLines?: boolean;
	stickyHeader?: boolean;
	headerId?: string;
	headerColor?: TextColorType;
	headerType?: CoreTextType;
	markOnHover?: boolean;
	$showBoxShadow?: boolean;
	$showBorderBottom?: boolean;
	columnOrderedBy?: string;
	handleSortRequest?: (sortBy: string) => void;
	handleOnOpenInfoTooltipEvent?: (event: SyntheticEvent, col_name: string) => void;
};

const StyledTableCell = styled(TableCell)<{
	readonly width?: string;
	readonly vertical?: string;
	readonly $padding?: string;
	readonly $hideHrLines?: boolean;
	readonly $stickyHeader?: boolean;
	readonly $showBoxShadow?: boolean;
	readonly $showBorderBottom?: boolean;
}>`
	padding: ${(props) => props.$padding || '10px'};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding: ${(props) => props.$padding || '0px 0px 0px 10px'};
	}

	${(props) => props.width && 'width: ' + props.width};
	${(props) => props.vertical && 'vertical-align: ' + props.vertical};
	${(props) => props.$hideHrLines && 'border: 0'};
	${(props) =>
		props.$stickyHeader &&
		`
			background: white;
			position: sticky;
			top: 0;
		`};
	${(props) => props.$stickyHeader && props.$showBoxShadow && 'box-shadow: 1px 1px rgba(224, 224, 224, 1)'};
	${(props) => props.$showBorderBottom && `border-bottom: 1px solid ${Colors.lightGrey}`};
`;

const StyledTableRow = styled(TableRow)<{ readonly $stickyHeader: boolean }>`
	${(props) =>
		props.$stickyHeader &&
		`
			background: white;
			position: sticky;
			top: 0;
			box-shadow: 1px 1px rgba(224, 224, 224, 1);
		`};
`;

const StyledTableHead = styled(TableHead)<{ readonly $stickyHeader: boolean }>`
	${(props) =>
		props.$stickyHeader &&
		`
		background: white;
		position: sticky;
		top: 0;
		z-index: 1;
	`};
`;

const TableRowWithHover = styled(TableRow)<{ readonly $markOnHover: boolean }>`
	${(props) =>
		props.$markOnHover &&
		`
		&:hover {
			background: ${Colors.docsGrey};
		}
	`};
`;

const StyledTableSortableLabel = styled(function StyledTableSortableLabel({
	pointerCursor,
	isSortedBy,
	hoverIconColor,
	...props
}) {
	return <TableSortLabel {...props} />;
})`
	cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
	${(props) =>
		!props.isSortedBy
			? `
					&:hover {
						background-color: rgba(217, 217, 217, 0.22);
						border-radius: 4px;
					}
          &:hover path {
						fill: ${props.hoverIconColor};
					}
				`
			: ''}
	padding: 3px 4px;
	line-height: 1;
`;

const StyledSortedColumn = ({ column, columnOrderedBy, headerColor, headerType }) => {
	const isSortedBy = column.sortable ? columnOrderedBy === column.name : true;

	let textColor = headerColor;
	if (column.sortable) {
		if (isSortedBy) {
			textColor = 'primary';
		} else {
			textColor = 'textGrey';
		}
	}

	const cursor = isSortedBy || !column.isDataExist ? 'default' : 'pointer';

	return (
		<CoreText type={headerType} color={textColor} style={column.headerStyle} cursor={cursor}>
			{column.name}
		</CoreText>
	);
};

const CoreTable = ({
	rows,
	columns,
	showHeader = true,
	$hideHrLines = false,
	stickyHeader = false,
	headerId = undefined,
	headerColor = 'secondary',
	headerType = 'captionRegular',
	markOnHover = false,
	$showBoxShadow = true,
	$showBorderBottom = false,
	columnOrderedBy = undefined,
	handleSortRequest = undefined,
	handleOnOpenInfoTooltipEvent = function noop(): void {},
}: CoreTableProps) => {
	const fixedHeaderContent = () => {
		return showHeader ? (
			<StyledTableRow $stickyHeader={stickyHeader}>
				{columns.map((column, index) => {
					const handleSort = (): void => {
						if (handleSortRequest && columnOrderedBy !== column.name) {
							handleSortRequest(column.name);
						}
					};

					const isSortedBy = column.sortable ? columnOrderedBy === column.name : true;

					const handleOnOpenInfoTooltip = (e): void => handleOnOpenInfoTooltipEvent(e, column.name);

					let iconColor: string;

					if (column.sortable) {
						if (isSortedBy) {
							iconColor = Colors.primary;
						} else {
							iconColor = Colors.lightGrey;
						}
					}

					const cursor = isSortedBy || !column.isDataExist ? 'default' : 'pointer';

					let coreColumnText = StyledSortedColumn({ column, columnOrderedBy, headerColor, headerType });

					if (column.sortable) {
						if (column.isDataExist) {
							coreColumnText = (
								<StyledTableSortableLabel
									onClick={handleSort}
									hoverIconColor={Colors.primary}
									// eslint-disable-next-line react/no-unstable-nested-components -- Too complex to refactor
									IconComponent={() => (
										<CoreExpanderIcon
											iconStyle={{ cursor, padding: '0 0 0 6px' }}
											iconWidth='11'
											iconHeight='8'
											onExpanderClick={handleSort}
											iconColor={iconColor}
											isNewIcon={true}
										/>
									)}
									pointerCursor={cursor === 'pointer'}
									direction='desc'
									active={isSortedBy}
									isSortedBy={isSortedBy}
								>
									{coreColumnText}
								</StyledTableSortableLabel>
							);

							if (isSortedBy) {
								coreColumnText = (
									<CoreTooltip
										title={`Sorted by ${column.name}`}
										$maxWidth={column.informationTooltipMaxWidth}
										placement='top'
									>
										{coreColumnText}
									</CoreTooltip>
								);
							}
						} else {
							coreColumnText = (
								<CoreTooltip title={column.notAvailableTooltipMessage} placement='top'>
									{coreColumnText}
								</CoreTooltip>
							);
						}
					}

					let informationIcon;

					if (column.columnDescription) {
						informationIcon = (
							<InformationIcon
								tooltipTitle={column.columnDescription}
								style={{ padding: '0 0 0 3px' }}
								handleOnOpen={handleOnOpenInfoTooltip}
							/>
						);
					}

					return (
						<StyledTableCell
							width={column.width}
							key={index}
							$stickyHeader={stickyHeader}
							id={headerId}
							$showBoxShadow={$showBoxShadow}
							$showBorderBottom={$showBorderBottom}
						>
							{coreColumnText}
							{informationIcon}
						</StyledTableCell>
					);
				})}
			</StyledTableRow>
		) : null;
	};

	return (
		<StylesProvider injectFirst>
			<TableContainer>
				<Table style={{ borderCollapse: stickyHeader ? 'separate' : 'collapse' }}>
					<StyledTableHead $stickyHeader={stickyHeader}>{fixedHeaderContent()}</StyledTableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRowWithHover key={index} $markOnHover={markOnHover}>
								{columns.map((column, innerIndex) => (
									<StyledTableCell
										width={column.width}
										$padding={column.padding}
										vertical={column.vertical}
										key={innerIndex}
										$hideHrLines={$hideHrLines}
									>
										{column.renderer(row)}
									</StyledTableCell>
								))}
							</TableRowWithHover>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</StylesProvider>
	);
};

export default CoreTable;
