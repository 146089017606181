import config from '../config';
import { type AuthStore, getOrCreateAuthStore } from '../stores/authStore';
import {
	createAxiosInstance,
	getErrorHandler,
	getSuccessHandler,
	type WithAxiosServer,
	type SessionsApi,
} from './createAxiosInstance';
import type { AxiosInstance } from 'axios';

const baseURL = config.ApiUrl;

class ApiSessionsServer implements WithAxiosServer, SessionsApi {
	server: AxiosInstance;

	authStore: AuthStore;

	constructor() {
		this.server = createAxiosInstance(baseURL);
		this.authStore = getOrCreateAuthStore();
	}

	getToken = (): typeof this.authStore.getToken => {
		return this.authStore.getToken;
	};

	refreshSession = async (): ReturnType<typeof this.authStore.refreshToken> => {
		return this.authStore.refreshToken();
	};

	resetSession = async (): ReturnType<typeof this.authStore.logout> => this.authStore.logout();

	changePassword = async (accessToken: string, oldPassword: string, newPassword: string) => {
		return this.server.patch(
			'changePassword',
			{ accessToken, oldPassword, newPassword },
			{
				headers: { token: accessToken, 'content-type': 'application/json' },
			},
		);
	};
}

let maybeApiSessionsServer: ApiSessionsServer | null = null;

function createApiSessionsServer(): ApiSessionsServer {
	const apiSessionsServer = new ApiSessionsServer();

	apiSessionsServer.server.interceptors.response.use(
		getSuccessHandler(),
		getErrorHandler(apiSessionsServer.server, apiSessionsServer),
	);

	return apiSessionsServer;
}

export function getOrCreateApiSessionsServer(): ApiSessionsServer {
	if (maybeApiSessionsServer === null) {
		maybeApiSessionsServer = createApiSessionsServer();
	}
	return maybeApiSessionsServer;
}
