export default function ApiDocIcon() {
	return (
		<svg width='24' height='24' viewBox='3 1 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M20.2797 6.26406L15.2359 1.22031C15.0953 1.07969 14.9055 1 14.7062 1H4.75C4.33516 1 4 1.33516 4 1.75V21.25C4 21.6648 4.33516 22 4.75 22H19.75C20.1648 22 20.5 21.6648 20.5 21.25V6.79609C20.5 6.59687 20.4203 6.40469 20.2797 6.26406ZM18.7703 7.14062H14.3594V2.72969L18.7703 7.14062ZM18.8125 20.3125H5.6875V2.6875H12.7656V7.75C12.7656 8.01107 12.8693 8.26145 13.0539 8.44606C13.2385 8.63066 13.4889 8.73438 13.75 8.73438H18.8125V20.3125Z'
				fill='black'
				fillOpacity='0.88'
			/>
			<path
				d='M8.82973 15H8L9.26426 11H10.2621L11.5246 15H10.6948L9.77748 11.9141H9.74887L8.82973 15ZM8.77787 13.4277H10.7377V14.0879H8.77787V13.4277Z'
				fill='black'
				fillOpacity='0.88'
			/>
			<path
				d='M11.9654 15V11H13.4102C13.688 11 13.9246 11.0579 14.1201 11.1738C14.3157 11.2884 14.4647 11.4479 14.5672 11.6523C14.6709 11.8555 14.7228 12.0898 14.7228 12.3555C14.7228 12.6211 14.6703 12.8555 14.5654 13.0586C14.4605 13.2617 14.3085 13.4199 14.1094 13.5332C13.9115 13.6465 13.6719 13.7031 13.3906 13.7031H12.4696V13.0254H13.2654C13.4144 13.0254 13.5372 12.9974 13.6338 12.9414C13.7315 12.8841 13.8042 12.8053 13.8519 12.7051C13.9008 12.6035 13.9252 12.487 13.9252 12.3555C13.9252 12.2227 13.9008 12.1068 13.8519 12.0078C13.8042 11.9076 13.7315 11.8301 13.6338 11.7754C13.536 11.7194 13.412 11.6914 13.2618 11.6914H12.7396V15H11.9654Z'
				fill='black'
				fillOpacity='0.88'
			/>
			<path d='M16 11V15H15.2257V11H16Z' fill='black' fillOpacity='0.88' />
		</svg>
	);
}
