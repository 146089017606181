import { type NavinaJwt, useAuthStore } from '../stores/authStore';
import type { emrNames } from '../types';
import JwtDecode from 'jwt-decode';

export default function useEMRName(): emrNames {
	const authStore = useAuthStore();

	let emrName;

	if (authStore && authStore.getToken) {
		const tokenInfo = JwtDecode<NavinaJwt>(authStore.getToken);
		if (tokenInfo.emrName) {
			emrName = tokenInfo.emrName;

			if (emrName) {
				emrName = emrName.toLowerCase() as emrNames;
			}
		}
	}

	return emrName;
}
