type ThirdPartyServicesStatus = {
	LogRocket: boolean;
	LiveChat: boolean;
	ConsoleLog: boolean;
	Appcues: boolean;
	Datadog: boolean;
};

// This function is duplicated in the index.html file
export function shallSilence(): ThirdPartyServicesStatus {
	const silencedFeatures: ThirdPartyServicesStatus = {
		LogRocket: false,
		LiveChat: false,
		ConsoleLog: false,
		Appcues: false,
		Datadog: false,
	};

	const url = new URLSearchParams(window.location.search);

	const urlParams = new Proxy(url, {
		get(searchParams, prop: string) {
			if (prop.endsWith('[]')) {
				return searchParams.getAll(prop.slice(0, -2));
			}
			return searchParams.get(prop.toString());
		},
	});

	const isOverlay = window.location.href.toLocaleLowerCase().includes('/overlay/');

	let hasSilenceFeaturesInUrl = false;
	// Import the flags from the URL search param to the silencedFeatures object
	const silencedFeaturesParam = urlParams['silence[]'] as string[];

	if (isOverlay && silencedFeaturesParam) {
		Object.keys(silencedFeatures).forEach((key): void => {
			if (silencedFeaturesParam.includes(key)) {
				hasSilenceFeaturesInUrl = true;
				silencedFeatures[key] = true;
			}
		});
	}

	if (!hasSilenceFeaturesInUrl && sessionStorage.getItem('silenced_features')) {
		const cache = JSON.parse(sessionStorage.getItem('silenced_features'));
		return cache;
	}

	const shouldStoreSilencedFeatures = !urlParams['code'];
	// Came from SSO
	if (
		urlParams['code'] || // Came from SSO
		(isOverlay && urlParams['username'] && urlParams['username'].includes('mpug_user_nvn(')) // Using Overlay + came from data source 16
	) {
		Object.keys(silencedFeatures).forEach((key): void => {
			silencedFeatures[key] = true;
		});
	}

	if (shouldStoreSilencedFeatures) {
		sessionStorage.setItem('silenced_features', JSON.stringify(silencedFeatures));
	}

	return silencedFeatures;
}
