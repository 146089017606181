import { getOrCreateAnalytics } from '../../utils/analytics';

function debounce<Params extends any[]>(func: (...args: Params) => any, timeout: number): (...args: Params) => void {
	let timer: number | null = null;
	return (...args: Params): void => {
		timer && window.clearTimeout(timer);
		timer = window.setTimeout((): void => {
			func(...args);
		}, timeout);
	};
}

class SlownessTask {
	private timers: Record<string, number> = {};

	private lock = false;

	timeLog = (name: string): void => {
		const now = new Date().getTime();

		if (!this.timers[name] && !this.lock) {
			this.timers[name] = now;
		}
	};

	timeEnd = (name: string): void => {
		this.timeLog(name);
		this.lock = true;
	};

	inlineSubmit = (analyticsObject: ReturnType<typeof getOrCreateAnalytics>): void => {
		if (this.lock) {
			const firstItemLocalStorageId = 'SLOWNESS_index_html_starts';
			const firstTimer = localStorage.getItem(firstItemLocalStorageId);
			localStorage.removeItem(firstItemLocalStorageId);

			if (firstTimer) {
				this.timers['index.html loaded'] = parseInt(firstTimer, 10);

				const sortedTimers = Object.entries(this.timers).sort((a, b): number => a[1] - b[1]);

				const sortedSeconds: Record<string, number> = {};
				for (let i = 1; i <= sortedTimers.length - 1; i += 1) {
					const currentTimers = sortedTimers[i];
					const previousTimers = sortedTimers[i - 1];
					if (currentTimers === undefined || previousTimers === undefined) {
						// eslint-disable-next-line no-continue -- This is for the type checking
						continue;
					}
					const [key, value] = currentTimers;
					const [prevKey, prevValue] = previousTimers;

					sortedSeconds[`[${i}] ${prevKey} - ${key}`] = (value - prevValue) / 1_000;
				}

				const lastSortedTimer = sortedTimers[sortedTimers.length - 1];
				const firstSortedTimer = sortedTimers[0];
				if (lastSortedTimer === undefined || firstSortedTimer === undefined) {
					return;
				}
				const totalTimeSeconds = (lastSortedTimer[1] - firstSortedTimer[1]) / 1_000;
				sortedSeconds['Total time'] = totalTimeSeconds;

				analyticsObject.track(getOrCreateAnalytics().idsNames.SlownessOptimization, {
					slownessOptimizationDetails: sortedSeconds,
					slownessOptimizationTotal: totalTimeSeconds,
					slownessOptimizationTimes: this.timers,
				});
			}
		}
	};

	submit = debounce(this.inlineSubmit, 1_000);
}

export const slownessMessure = new SlownessTask();
