import { colors } from './Colors';
import styled from 'styled-components';

export default styled.div<{ readonly $isSelected: boolean; readonly size: 's' | 'm' | 'l' }>`
	background-color: ${(props) => (props.$isSelected ? colors.accentLightGreen : colors.white)};
	border-radius: 20px;
	width: ${(props) => (props.size === 's' ? 50 : props.size === 'm' ? 110 : 160)}px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	margin-right: 5px;

	&,
	* {
		cursor: pointer;
	}
`;
