import ViewOnlyMode from '../../base-ui/css/ViewOnlyMode';
import ContactUs from '../../components/contact-us';
import { checkIfHasPermission } from '../../components/user-permissions/HasPermission';
import { featureFlag } from '../../config/FeatureFlag';
import type { NavinaLoginStep } from '../../pages/auth/steps/NavinaLoginStepEnum';
import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { useAddonStore } from '../../stores/addonStore';
import { useAuthStore } from '../../stores/authStore';
import type { TabId, TabsViewTabName } from '../../types';
import { getOrCreateAddonCommunicator, OverlayProtocolTypes } from '../../utils/addon/addon-communicator';
import { getOrCreateAnalytics } from '../../utils/analytics';
import { inIframe, loggedInViaSSo } from '../../utils/common';
import { NEXT_URL_QUERY_PARAMS, SSO_URL_QUERY_PARAMS } from '../../utils/consts';
import { getOrCreateLogger } from '../../utils/logger';
import { useNavinaData } from '../../utils/useNavinaData';
import { useUrlChanged } from '../useUrlChanged';
import type { NavinaRoute } from './RouteType';
import { observer } from 'mobx-react-lite';
import QueryString from 'query-string';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface ProtectedRouteProps extends NavinaRoute {
	ContactUsEnabled?: boolean;
	isSummaryView?: boolean;
	overlayPageName?: TabsViewTabName;
	defaultStepId?: NavinaLoginStep;
}

const EmptyDiv = () => <div />;

const ProtectedRoute = ({
	layout: Layout,
	component: Component,
	location,
	ContactUsEnabled = false,
	isSummaryView = false,
	...rest
}: ProtectedRouteProps) => {
	useUrlChanged();

	const authStore = useAuthStore();
	const navina = useNavinaData();
	const overlayOnlyView: boolean = useFeatureFlag('configuration_OverlayOnlyView', undefined);
	const frontendTabsConfiguration: TabId[] | undefined = useFeatureFlag('configuration_FrontendTabs', undefined);

	const [loggedIn, setLoggedIn] = useState(authStore?.isLoggedIn);
	let queryString = QueryString.parse(location.search);
	const ssoUrl = queryString[SSO_URL_QUERY_PARAMS] as string;

	useEffect((): void => {
		if (authStore) {
			setLoggedIn(authStore.isLoggedIn);
		}
	}, [authStore, authStore.isLoggedIn]);

	let shouldChangeToOverlay = false;

	const addonStore = useAddonStore();

	const analytics = getOrCreateAnalytics();

	if (!navina.isOverlay && !location.pathname.includes('/overlay/')) {
		if (overlayOnlyView && isSummaryView) {
			shouldChangeToOverlay = true;
			window.location.pathname = `overlay${location.pathname}`;
		}

		if (addonStore.reportReadyToBeSent) {
			const { installed, version, emr, apiPingable, ...extraAnalytics } = addonStore.toJson();
			analytics.track(analytics.idsNames.AddonHealthMonitorReport, {
				Addon_Installed: installed,
				Addon_Version: version,
				Addon_Emr: emr,
				Addon_CanPingAPI: apiPingable,
				...extraAnalytics,
			});
		}
	}

	if (loggedIn === true) {
		if (localStorage.getItem('hadError')) {
			localStorage.removeItem('hadError');
		}

		const { flagsAreInitialized } = featureFlag();
		const isReady = flagsAreInitialized && !shouldChangeToOverlay;
		if (!isReady) {
			return <Route>{Layout ? () => <Layout component={EmptyDiv} /> : <></>}</Route>;
		}

		const isViewOnly = checkIfHasPermission(authStore, 'view_only', false);

		return (
			<Route>
				{(props) => {
					const layout = Layout ? (
						<Layout component={Component} {...rest} {...props} allowedTabs={frontendTabsConfiguration} />
					) : (
						<Component {...rest} {...props} allowedTabs={frontendTabsConfiguration} />
					);

					return (
						<>
							{layout}
							{isViewOnly && <ViewOnlyMode />}
							{ContactUsEnabled && <ContactUs />}
						</>
					);
				}}
			</Route>
		);
	}

	if (ssoUrl && !loggedInViaSSo()) {
		const { [SSO_URL_QUERY_PARAMS]: removedParam, ...restQueryString } = queryString;
		queryString = restQueryString;

		const nextUrl = `${window.location.pathname}?${QueryString.stringify(queryString)}`;
		getOrCreateLogger().info('starting sso process', { ssoUrl, nextUrl });
		localStorage.setItem(NEXT_URL_QUERY_PARAMS, nextUrl);
		localStorage.setItem('ssoUrl', ssoUrl);
		const isInIframe = inIframe();

		if (isInIframe) {
			getOrCreateAddonCommunicator().sendToAddon(OverlayProtocolTypes['addon-change-frame-src'], { url: ssoUrl });
		} else {
			window.location.href = ssoUrl;
			return null;
		}
	}

	const nextUrl = `/login${location.pathname}${location.search}`;
	return <Redirect to={nextUrl} />;
};

export default observer(ProtectedRoute);
