import type { TabId } from '../types';
import {
	getOrCreateAddonCommunicator,
	OpenSummaryProtocolTypes,
	OverlayProtocolTypes,
} from './addon/addon-communicator';
import { type ReactNode, createContext, useContext, useMemo, useState } from 'react';

type NavinaScreenLayout = 'OVERLAY' | 'FULLSCREEN';

type NavinaData = {
	isOverlay: boolean;
	isFullScreen: boolean;
	screenLayout: NavinaScreenLayout;
	pathname: string;
	isQualityTool: boolean;
	isPostVisit: boolean;
	setPostVisit: (value: boolean) => void;
	activeTab: TabId | undefined;
	setActiveTab: (newActiveTab: TabId) => void;
	sendMessageToAddon: (eventType: OverlayProtocolTypes | OpenSummaryProtocolTypes, params?: any) => void;
};

const screenLayout = getScreenLayout();

const NavinaContext = createContext<NavinaData>({
	isOverlay: screenLayout === 'OVERLAY', // Shall be deprecated, replaced by screenLayout
	isFullScreen: screenLayout === 'FULLSCREEN', // Shall be deprecated, replaced by screenLayout
	screenLayout,
	sendMessageToAddon,
	pathname: window.location.pathname,
	activeTab: undefined,
	setActiveTab: function noop(): void {},
	isPostVisit: false,
	setPostVisit: function noop(): void {},
	isQualityTool: false,
});

export function useNavinaData(): NavinaData {
	return useContext(NavinaContext);
}

export function NavinaDataProvider({ children }: { children: ReactNode }) {
	const [isPostVisit, setIsPostVisit] = useState(false);
	const [activeTab, setActiveTab] = useState<TabId | undefined>(undefined);

	const screenLayout = useMemo<NavinaScreenLayout>(getScreenLayout, []);

	const contextValue = useMemo<NavinaData>(
		() => ({
			isPostVisit,
			setPostVisit: setIsPostVisit,
			activeTab,
			setActiveTab,
			pathname: window.location.pathname,
			isOverlay: screenLayout === 'OVERLAY', // Shall be deprecated, replaced by screenLayout
			isFullScreen: screenLayout === 'FULLSCREEN', // Shall be deprecated, replaced by screenLayout
			screenLayout: screenLayout,
			sendMessageToAddon: sendMessageToAddon,
			isQualityTool: false,
		}),
		[isPostVisit, activeTab, screenLayout],
	);

	return <NavinaContext.Provider value={contextValue}>{children}</NavinaContext.Provider>;
}

function sendMessageToAddon(eventType: OverlayProtocolTypes | OpenSummaryProtocolTypes, params?: any): void {
	getOrCreateAddonCommunicator().sendToAddon(eventType, params);
}

function getScreenLayout(): NavinaScreenLayout {
	if (window.location.pathname.toLocaleLowerCase().includes('/overlay/')) {
		return 'OVERLAY';
	}
	return 'FULLSCREEN';
}
