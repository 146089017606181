import { getOrCreateApiGateway } from '../server/apiGateway';
import type { UserClinicsHierarchyType } from '../types';
import { getOrCreateAnalytics } from '../utils/analytics';
import { getOrCreateLogger } from '../utils/logger';
import { LabelSorter } from '../utils/strings';
import { type AuthStore } from './authStore';
import NavinaCache from './cache';
import type { CMSHccVersions, HccVersions } from '@dxcapture/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { observable, action, configure, autorun } from 'mobx';
import { useContext, createContext } from 'react';

configure({ enforceActions: 'always' });

class QuicksightStore {
	clinicIdCacheKey = 'dashboardSelectedClinicId';
	marketIdCacheKey = 'dashboardSelectedMarketId';
	orgIdCacheKey = 'dashboardSelectedOrgId';
	hccVersionCacheKey = 'dashboardSelectedHccVersion';
	areFiltersInCache = false;
	username = '';
	clinicsList = [];
	@observable uiClinicsList = [];
	marketsList = [];
	@observable uiMarketsList = [];
	orgsList = [];
	@observable uiOrgsList = [];

	userClinicsHierarchySource: UserClinicsHierarchyType = {
		clinics: [],
		markets: [],
		organizations: [],
	};

	@observable selectedClinicIds: number[] | undefined = undefined;
	@observable selectedMarketIds: number[] | undefined = undefined;
	@observable selectedOrgId: number | undefined = undefined;
	@observable selectedHccVersion: HccVersions = 'v24';
	@observable loading = false;
	@observable isErrorState = false;
	@observable errorType = '';
	@observable dashboardObj: any = undefined;
	@observable dashboardTimeSpend: any = undefined;
	@observable isNoData = false;
	@observable currentTabName = 'default';

	handleExportEvent = (e): void => {
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.DashboardClick, {
			actionId: 'Export Format',
			FormatType: e.modalName,
			TabName: this.currentTabName,
			hccVersion: this.selectedHccVersion,
		});
	};

	getFilterUsedAnalyticsProperties = () => ({
		OrgFilterUsed: !!this.selectedOrgId,
		MarketFilterUsed: !!this.selectedMarketIds && this.selectedMarketIds.length > 0,
		GroupFilterUsed: !!this.selectedClinicIds && this.selectedClinicIds.length > 0,
	});

	constructor(authStore: AuthStore | undefined, shouldLoad: boolean) {
		if (!authStore) {
			getOrCreateLogger().error('no authStore, scheduleStore initialization failed');
			return;
		}

		this.username = authStore.values.email;

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			if (!shouldLoad) {
				console.log('Should not load dashboard store');
				return;
			}

			if (this.username !== authStore.values.email || !this.userClinicsHierarchySource || !this.clinicsList?.length) {
				getOrCreateApiGateway()
					.getUserClinicsHierarchy()
					.then((response): void => {
						this.setClinicsHierarchy(response.data);
						this.userClinicsHierarchySource = response.data;
						this.username = authStore.values.email;
					});
			}
		});

		autorun((): void => {
			if (!authStore.getToken) {
				console.log('no token, user not yet authenticated');
				return;
			}

			this.areFiltersInCache = false;

			if (NavinaCache.exist(this.clinicIdCacheKey)) {
				const ids: number[] = NavinaCache.getArray(this.clinicIdCacheKey).map(Number);

				if (ids && ids.length > 0) {
					this.areFiltersInCache = true;
				}

				this.setSelectedClinicId(ids);
			}
			if (NavinaCache.exist(this.marketIdCacheKey)) {
				const ids: number[] = NavinaCache.getArray(this.marketIdCacheKey).map(Number);

				if (ids && ids.length > 0) {
					this.areFiltersInCache = true;
				}

				this.setSelectedMarketId(ids);
				this.updateClinicsVisibilityBySelectedMarkets(this.selectedMarketIds);
			}
			if (NavinaCache.exist(this.orgIdCacheKey)) {
				const id = parseInt(NavinaCache.get(this.orgIdCacheKey), 10);

				if (id) {
					this.areFiltersInCache = true;
				}

				this.setSelectedOrgId(id);
				this.updateHierarchyVisibilityBySelectedOrgs(this.selectedOrgId);
			}

			if (NavinaCache.exist(this.hccVersionCacheKey)) {
				const hccVersion: HccVersions = NavinaCache.get(this.hccVersionCacheKey) as CMSHccVersions;
				this.setSelectedHccVersion(hccVersion);
			}
		});
	}

	@action setClinicsHierarchy = (clinicsHierarchy: UserClinicsHierarchyType): void => {
		// Make sure the current selected Clinic / Department / Provider are withing our permission boundaries
		if (this.selectedClinicIds) {
			if (!clinicsHierarchy.clinics.find((clinic): boolean => this.selectedClinicIds.includes(clinic.id))) {
				this.setSelectedClinicId(undefined, true);
			}
		} else if (clinicsHierarchy?.clinics?.length === 1) {
			this.setSelectedClinicId([clinicsHierarchy.clinics[0].id], true);
		}

		// Clinics:
		this.clinicsList = clinicsHierarchy.clinics;
		this.uiClinicsList = clinicsHierarchy.clinics
			.map((clinic) => ({ value: clinic.id, label: clinic.name, disabled: false }))
			.sort(LabelSorter);

		// Markets:
		this.marketsList = clinicsHierarchy.markets;
		this.uiMarketsList = clinicsHierarchy.markets
			.map((market) => ({ value: market.id, label: market.name, disabled: false }))
			.sort(LabelSorter);

		this.updateClinicsVisibilityBySelectedMarkets(this.selectedMarketIds);

		// Organizations:
		this.orgsList = clinicsHierarchy.organizations;
		this.uiOrgsList = clinicsHierarchy.organizations
			.map((org) => ({ value: org.id, label: org.name, disabled: false }))
			.sort(LabelSorter);

		this.updateHierarchyVisibilityBySelectedOrgs(this.selectedOrgId);
		this.loadDashboard();
	};

	updateCache = (): void => {
		NavinaCache.set(this.clinicIdCacheKey, this.selectedClinicIds);
		NavinaCache.set(this.hccVersionCacheKey, this.selectedHccVersion);
		NavinaCache.set(this.marketIdCacheKey, this.selectedMarketIds);
		NavinaCache.set(this.orgIdCacheKey, this.selectedOrgId);
	};

	@action loadDashboard = (): void => {
		this.setLoading(true);

		if (this.clinicsList.length === 0) {
			// data from backend is not ready yet so filters will not appear yet.
			return;
		}

		if (!this.selectedClinicIds || this.selectedClinicIds.length === 0) {
			this.setNoData(true);
			this.setLoading(false);
			return;
		}

		this.setNoData(false);

		// mapping selected clinic ids to dictionary of key = id, value = name
		const clinics = Object.fromEntries(
			this.clinicsList
				.filter((item): boolean => this.selectedClinicIds.includes(item.id))
				.map((item) => [item.id, item.name]),
		);

		const analytics = getOrCreateAnalytics();

		getOrCreateApiGateway()
			.getQuicksightEmbedURL({ clinics: clinics, hccVersion: this.selectedHccVersion })
			.then((ret): void => {
				const embedURL = ret.data.message;
				if (embedURL) {
					const options = {
						url: embedURL,
						container: document.getElementById('qs_dashboard_embedded'),
						className: 'dashboard-iframe',
					};

					if (this.dashboardObj) {
						// removing existing iframes
						document.getElementById('qs_dashboard_embedded').replaceChildren();
					}

					const dashboard = QuickSightEmbedding.embedDashboard(options);
					this.setDashboardObj(dashboard);

					dashboard.on('selectedSheetChange', (e): void => {
						// Time spent in the previous tab
						analytics.track(analytics.idsNames.TabUnload, {
							actionId: 'Tab Unload',
							TabName: this.currentTabName,
							hccVersion: this.selectedHccVersion,
							TimeSpend: Date.now() - this.dashboardTimeSpend,
						});

						this.setDashboardTimeSpend(Date.now());
						this.setCurrentTabName(e.selectedSheet?.name);

						analytics.track(analytics.idsNames.DashboardClick, {
							actionId: 'Tab Click',
							TabName: e.selectedSheet?.name,
							hccVersion: this.selectedHccVersion,
							...this.getFilterUsedAnalyticsProperties(),
						});
					});

					dashboard.on('load', (): void => {
						this.setIsErrorState(false);
						this.setLoading(false);
						this.setDashboardTimeSpend(Date.now());

						dashboard.getSheets((res): void => {
							this.setCurrentTabName(res.sheets[0]?.name);

							analytics.track(analytics.idsNames.DashboardClick, {
								actionId: 'Tab Click',
								TabName: this.currentTabName,
								hccVersion: this.selectedHccVersion,
								...this.getFilterUsedAnalyticsProperties(),
							});
						});
					});

					dashboard.on('SHOW_MODAL_EVENT', this.handleExportEvent);
				} else {
					this.setLoading(false);
					this.setIsErrorState(false);
				}

				NavinaCache.remove(this.hccVersionCacheKey);
			})
			.catch((error): void => {
				getOrCreateLogger().error('Error getting QuickSight embed URL', error);
				this.setLoading(false);
				this.setIsErrorState(true);
				this.setErrorType(error);
			});
	};

	@action setLoading = (loading: typeof this.loading): void => {
		this.loading = loading;
	};

	@action setNoData = (noData: typeof this.isNoData): void => {
		this.isNoData = noData;
	};

	@action setIsErrorState = (isErrorState: typeof this.isErrorState): void => {
		this.isErrorState = isErrorState;
	};

	@action setErrorType = (errorType: typeof this.errorType): void => {
		this.errorType = errorType;
	};

	@action setDashboardObj = (obj: typeof this.dashboardObj): void => {
		this.dashboardObj = obj;
	};

	@action setDashboardTimeSpend = (timeSpent: typeof this.dashboardTimeSpend): void => {
		this.dashboardTimeSpend = timeSpent;
	};

	@action setCurrentTabName = (tabName: typeof this.currentTabName): void => {
		this.currentTabName = tabName;

		if (this.dashboardObj) {
			this.dashboardObj.off('SHOW_MODAL_EVENT');
			this.dashboardObj.on('SHOW_MODAL_EVENT', this.handleExportEvent);
		}
	};

	@action setSelectedClinicId = (selectedClinicId: typeof this.selectedClinicIds, manuallyUpdate = false): void => {
		if (this.selectedClinicIds !== selectedClinicId) {
			this.selectedClinicIds = selectedClinicId;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	@action setSelectedMarketId = (selectedMarketId: typeof this.selectedMarketIds, manuallyUpdate = false): void => {
		if (this.selectedMarketIds !== selectedMarketId) {
			this.selectedMarketIds = selectedMarketId;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	@action setSelectedOrgId = (selectedOrgId: typeof this.selectedOrgId, manuallyUpdate = false): void => {
		if (this.selectedOrgId !== selectedOrgId) {
			this.selectedOrgId = selectedOrgId;

			if (manuallyUpdate) {
				this.updateCache();
			}
		}
	};

	@action setSelectedHccVersion = (selectedHccVersion: typeof this.selectedHccVersion): void => {
		if (this.selectedHccVersion !== selectedHccVersion) {
			this.selectedHccVersion = selectedHccVersion;
			this.updateCache();
		}
	};

	@action updateHierarchyVisibilityBySelectedOrgs = (selectedOrg: number, forceSelectChildren = false) => {
		if (!selectedOrg) {
			this.uiMarketsList = this.marketsList
				.map((market) => ({ value: market.id, label: market.name, disabled: false }))
				.sort(LabelSorter);

			this.updateClinicsVisibilityBySelectedMarkets(this.selectedMarketIds);
		} else {
			const orgMarkets = [];

			this.uiMarketsList = this.marketsList
				.map((market) => {
					const isPartOfOrg = selectedOrg === market.orgId;
					if (isPartOfOrg) {
						orgMarkets.push(market.id);
					}
					return { value: market.id, label: market.name, disabled: !isPartOfOrg };
				})
				.sort(LabelSorter);

			this.updateClinicsVisibilityBySelectedMarkets(orgMarkets, forceSelectChildren);
			if (forceSelectChildren) {
				this.setSelectedMarketId(orgMarkets, true);
			}
		}
	};

	@action updateClinicsVisibilityBySelectedMarkets = (selectedMarkets: number[], forceSelectChildren = false): void => {
		if (!selectedMarkets || selectedMarkets.length === 0) {
			this.uiClinicsList = this.clinicsList
				.map((clinic) => ({ value: clinic.id, label: clinic.name, disabled: false }))
				.sort(LabelSorter);
		} else {
			const marketsClinics = [];

			this.uiClinicsList = this.clinicsList
				.map((clinic) => {
					const isPartOfMarket = selectedMarkets.includes(clinic.marketId);
					if (isPartOfMarket) {
						marketsClinics.push(clinic.id);
					}
					return { value: clinic.id, label: clinic.name, disabled: !isPartOfMarket };
				})
				.sort(LabelSorter);

			if (forceSelectChildren) {
				this.setSelectedClinicId(marketsClinics, true);
			}
		}
	};
}

export const QuicksightStoreContext = createContext<QuicksightStore>(null);
export const useQuicksightStore = (): QuicksightStore => useContext(QuicksightStoreContext);

export default QuicksightStore;
