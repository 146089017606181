import { getOrCreateAnalytics } from '../utils/analytics';
import CoreExpanderIcon from './CoreExpanderIcon';
import CoreText from './CoreText';
import { HideOnSmallScreen, HideOnLargeScreen } from './Resolutions';
import { useState, useCallback, type ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ readonly $isReverse: boolean }>`
	display: grid;
	grid-template: ${(props) => (props.$isReverse ? 'auto auto / auto min-content' : 'auto auto / auto 1fr')};
	align-items: center;
`;

const ExpandibleContainer = styled.div<{ readonly $isReverse: boolean }>`
	display: flex;
	flex-direction: column;
	grid-row: 2;
	grid-column: ${(props) => (props.$isReverse ? '1 / span 2' : 2)};
`;

const TitleContainer = styled.div`
	width: 100%;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
`;

const TitleWithPostfixContainer = styled.div<{ readonly $isReverse: boolean }>`
	grid-row: 1;
	grid-column: ${(props) => (props.$isReverse ? 1 : 2)};
	display: inline-flex;
	align-items: center;
`;

type CoreExpanderProps = {
	defaultIsExpanded?: boolean;
	title: string;
	teaser?: string;
	smallResTeaser?: string;
	teaserMaxWidth?: string;
	children: ReactNode;
	analyticEventName?: string;
	iconStyle?: any;
	titleElement?: ReactNode;
	postfix?: ReactNode;
	isReverse?: boolean;
	extraAnalytics?: { [property: string]: any };
};

export default function CoreExpander({
	defaultIsExpanded = false,
	title,
	titleElement,
	teaser,
	smallResTeaser,
	teaserMaxWidth,
	postfix,
	isReverse = false,
	iconStyle,
	children,
	analyticEventName,
	extraAnalytics = {},
}: CoreExpanderProps) {
	const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

	const onExpanderClick = useCallback((): void => {
		const eventName = analyticEventName || 'Expander clicked';
		getOrCreateAnalytics().track(eventName, { action: isExpanded ? 'close' : 'open', title: title, ...extraAnalytics });
		setIsExpanded(!isExpanded);
	}, [setIsExpanded, isExpanded, analyticEventName, extraAnalytics, title]);

	return (
		<Container $isReverse={isReverse}>
			<CoreExpanderIcon
				isExpanded={isExpanded}
				iconStyle={iconStyle}
				onExpanderClick={onExpanderClick}
				isReverse={isReverse}
			/>
			<TitleWithPostfixContainer $isReverse={isReverse}>
				<TitleContainer onClick={onExpanderClick}>
					{titleElement || (
						<CoreText type='body1Bold' cursor='pointer'>
							{title}
						</CoreText>
					)}
					{postfix}
					{teaser && (
						<>
							{smallResTeaser ? (
								<>
									<HideOnSmallScreen>
										<CoreText
											type='body1'
											cursor='pointer'
											maxWidth={teaserMaxWidth}
											$margin='0 0 0 7px'
											whiteSpace='nowrap'
											text={`(${teaser}`}
										/>
									</HideOnSmallScreen>
									<HideOnLargeScreen>
										<CoreText
											type='body1'
											cursor='pointer'
											maxWidth={teaserMaxWidth}
											$margin='0 0 0 7px'
											whiteSpace='nowrap'
											text={`(${teaser}`}
										/>
									</HideOnLargeScreen>
								</>
							) : (
								<CoreText
									type='body1'
									cursor='pointer'
									maxWidth={teaserMaxWidth}
									$margin='0 0 0 7px'
									whiteSpace='nowrap'
									text={`(${teaser}`}
								/>
							)}
							<CoreText type='body1' cursor='pointer' whiteSpace='nowrap' text=')' />
						</>
					)}
				</TitleContainer>
			</TitleWithPostfixContainer>
			{isExpanded && <ExpandibleContainer $isReverse={isReverse}>{children}</ExpandibleContainer>}
		</Container>
	);
}
