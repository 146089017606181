import { CoreConfiguration } from './NavinaConfiguration';

// From Auth0 documentation: https://auth0.com/docs/get-started/authentication-and-authorization-flow/implicit-flow-with-form-post/mitigate-replay-attacks-when-using-the-implicit-flow#generate-a-cryptographically-random-nonce
function generateRandomString(length: number): string {
	const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';
	let result = '';
	let remainingLength = length; // Create a separate variable for the loop condition

	while (remainingLength > 0) {
		const byteArray = new Uint8Array(16);
		const randomValues = window.crypto.getRandomValues(byteArray);

		for (let i = 0; i < randomValues.length && remainingLength > 0; i++) {
			const randomValue = randomValues[i] ?? 0;

			if (randomValue < charset.length) {
				result += charset[randomValue];
				remainingLength--;
			}
		}
	}

	return result;
}

export const CommonConfiguration = {
	CognitoUserPoolId: 'us-east-1_bQgzoBbEs',
	CognitoClientId: '7a5utpstds7gqs6ccp3e1mi9n2',
	CognitoLocalStorageKey: 'cognito-user-auth-data',
	NumberOfPatientsInPage: 20,
	MixpanelKey: '95a552856231c623f7982db07c2f0a2f',
	AlertTime: 2500,
	MaxPdfDocumentWidth: window.innerWidth * 0.5,
	LaunchDarklyKey: '61b1a2be01464c0d2a15e1e6',
	Auth0Nonce: generateRandomString(16),
	NavinaAuth0BaseURL: `https://navina.us.auth0.com/`,
	Auth0LoginRedirect: `${window.location.origin}/login/`,
} as const satisfies CoreConfiguration;
