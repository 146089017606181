import { colors } from './Colors';
import ReactSelect from 'react-select';
import styled from 'styled-components';

const SelectWrapper = styled.div<{ readonly width: string; readonly disabled: boolean }>`
	width: ${(props) => props.width};

	${(props) =>
		props.disabled &&
		`
			filter: contrast(0.7);
			opacity: 0.5;
    `}
`;

const StyledReactSelect = styled(ReactSelect)`
	& .Select__control {
		border-radius: 7px;
		background: #fff;
		padding: 0px 7px;
		border: 0;
	}

	& .Select__control.Select__control--is-focused {
		box-shadow: 0 0 0 1px ${colors.primary};
	}

	& .Select__single-value,
	.Select__option,
	.Select__value-container--is-multi,
	.Select__placeholder {
		color: ${colors.primary};
	}

	& .Select__indicator-separator {
		display: none;
	}

	& .Select__option {
		background-color: ${colors.white};
	}

	& .Select__option:hover,
	& .Select__option.Select__option--is-selected:hover {
		filter: brightness(90%);
	}

	& .Select__option.Select__option--is-selected,
	.Select__option:active {
		background-color: ${colors.accentLightGreen};
	}

	& .Select__option.Select__option--is-focused {
		background-color: none;
	}

	& .Select__multi-value {
		background-color: ${colors.white};
		border: 1px solid ${colors.primary};
	}

	& .Select__multi-value .Select__multi-value__label {
		color: ${colors.primary};
	}

	& .Select__value-container--is-multi {
		text-overflow: ellipsis;
		max-width: 90%;
		white-space: nowrap;
		overflow: hidden;
		display: initial;
	}

	& .Select__value-container--is-multi > div {
		display: inline-flex;
	}
`;

export type OptionType = { label: string; value: any };

type SelectType = {
	options: OptionType[];
	onChange: (event: OptionType[] | OptionType) => void;
	value: any;
	defaultValue?: OptionType;
	hideCondition: boolean;
	width?: string;
	isMulti?: boolean;
	isClearable?: boolean;
	placeholder?: string;
	disabled?: boolean;
	register?: any;
};

const MultiValueContainer = ({ selectProps, data }) => {
	const label = data.label;
	const allSelected = selectProps.value;
	const index = allSelected.findIndex((selected): boolean => selected.label === label);
	const isLastSelected = index === allSelected.length - 1;
	const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
	const val = `${label}${labelSuffix}`;
	return <span>{val}</span>;
};

const CoreSelectbox = ({
	options,
	onChange,
	value,
	defaultValue,
	hideCondition,
	width = '200px',
	isMulti = false,
	isClearable = false,
	placeholder,
	disabled = false,
	register,
}: SelectType) => {
	if (defaultValue) {
		options.unshift(defaultValue);
		value = value || defaultValue.value;
	}

	let displayOnHover = '';
	// try to find the selected value in the options
	if (Array.isArray(value)) {
		value = options.filter((opt): boolean => value.includes(opt.value));
		displayOnHover = value.map((v) => v.label).join(', ');
	} else {
		value = options.find((opt): boolean => opt.value === value);
	}

	return (
		<div title={displayOnHover}>
			<SelectWrapper width={width} disabled={disabled} style={{ display: hideCondition && 'none' }}>
				<StyledReactSelect
					ref={register}
					value={value}
					onChange={onChange}
					options={options}
					classNamePrefix='Select'
					isMulti={isMulti}
					closeMenuOnSelect={!isMulti}
					hideSelectedOptions={false}
					isClearable={(defaultValue && defaultValue.value !== value && isClearable) || isMulti}
					isSearchable={true}
					placeholder={placeholder}
					isDisabled={disabled}
					components={{ MultiValueContainer }}
				/>
			</SelectWrapper>
		</div>
	);
};

export default CoreSelectbox;
