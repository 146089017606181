import { CoreSimpleTable, CoreTable, type CoreTableColumnType } from '.';
import CoreMedFeature from './CoreMedFeature';
import { type ComponentType, forwardRef, type ReactNode } from 'react';

type CoreMedFeatureTabularProps = {
	name: string;
	tooltip?: string;
	Icon: ComponentType;
	data: any[];
	columns: CoreTableColumnType[] | string[];
	showHeader?: boolean;
	columnToRowFieldNameMapper?: (columnName: string) => string;
	emptyFeatureString?: string;
	fullListButton?: ComponentType;
	inAppExplanation?: ReactNode;
	subtitle?: ReactNode;
};

export default forwardRef<HTMLDivElement, CoreMedFeatureTabularProps>(function CoreMedFeatureTabular(
	{
		Icon,
		name,
		data,
		columns,
		columnToRowFieldNameMapper,
		showHeader,
		tooltip,
		emptyFeatureString,
		fullListButton,
		inAppExplanation,
		subtitle,
		...other
	},
	ref,
) {
	if (columns.length === 0) {
		return null;
	}

	const tableType = typeof columns[0] === 'string' ? 'simple' : 'custom';

	return (
		<CoreMedFeature
			ref={ref}
			Icon={Icon}
			name={name}
			isEmpty={!data || data.length === 0}
			tooltip={tooltip}
			emptyFeatureString={emptyFeatureString}
			fullListButton={fullListButton}
			inAppExplanation={inAppExplanation}
			subtitle={subtitle}
		>
			{tableType === 'simple' ? (
				<CoreSimpleTable
					rows={data}
					columns={columns as string[]}
					columnToRowFieldNameMapper={columnToRowFieldNameMapper}
				/>
			) : (
				<CoreTable rows={data} columns={columns as CoreTableColumnType[]} showHeader={showHeader} />
			)}
		</CoreMedFeature>
	);
});
