import colors from '../../base-ui/Colors';
import CoreText from '../../base-ui/CoreText';
import { ReactComponent as Icon } from '../../base-ui/icons/ContactUsIcon.svg';
import { featureFlag } from '../../config/FeatureFlag';
import { useAuthStore } from '../../stores/authStore';
import { getOrCreateAnalytics } from '../../utils/analytics';
import { shallSilence } from '../../utils/shallSilence';
import { type IZESettings, Positions, setZESettings, TicketForms, hideZE, activateZE } from './utils';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ContactUsButton = styled.button`
	position: fixed;
	bottom: 0;
	right: 100px;
	width: 255px;
	height: 40px;
	box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.25);
	background: ${colors.primary};
	border-radius: 8px 8px 0 0;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;
`;

const ContactUs = () => {
	const [enabled, setEnabled] = useState(false);
	const authStore = useAuthStore();
	const contactUsRef = useRef(null);

	useEffect((): VoidFunction => {
		const handleClick = (event: MouseEvent): void => {
			if (contactUsRef.current && !contactUsRef.current.contains(event.target)) {
				hideZE();
			}
		};
		// Bind the event listener
		document.addEventListener('mousedown', handleClick);
		return (): void => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClick);
		};
	}, [contactUsRef]);

	useEffect((): VoidFunction => {
		let shouldUpdate = true;
		const zendeskInitialized = !shallSilence().LiveChat;

		const hideContactUs = (): void => {
			if (featureFlag().client === undefined) {
				console.error(`FeatureFlag client is undefined can't get data for showChatButton`);
				setEnabled(zendeskInitialized);
			}

			featureFlag()
				.client.waitForInitialization()
				.then((): void => {
					if (shouldUpdate) {
						const showChatButton = featureFlag().getFeatureValue('ShowChatButton', true);
						const isPostVisitIframe = window.location.href.toLocaleLowerCase().includes('/postvisit');

						if (!showChatButton || isPostVisitIframe) {
							console.log(`Hiding support widget as configured in the feature flags or is post visit URL,
                                     showChatButton: ${showChatButton} isPostVisitIframe ${isPostVisitIframe}`);
							setEnabled(false);
						} else {
							console.log(
								`Not hiding support chat showChatButton: ${showChatButton} isPostVisitIframe ${isPostVisitIframe}`,
							);
							setEnabled(zendeskInitialized);
						}
					}
				});
		};

		let timeout: number | null = null;
		if (authStore.isLoggedIn) {
			//can't get value of feature flags before logged in
			timeout = window.setTimeout(hideContactUs, featureFlag().client === undefined ? 2_000 : 0);
		} else {
			setEnabled(zendeskInitialized);
		}

		return (): void => {
			shouldUpdate = false;
			if (timeout) {
				window.clearTimeout(timeout);
			}
		};
	}, [authStore.isLoggedIn]);

	const setSettings = (): void => {
		const emailValue = localStorage.getItem('navinaEmail') || authStore?.getEmail;

		const settings: IZESettings = {
			horizontalPosition: Positions.RIGHT,
			verticalPosition: Positions.BOTTOM,
			offsetX: 40,
			offsetY: -15,
			formTitle: 'Contact Us',
			attachments: false,
			fieldsPrefill: [
				authStore?.userfullName && { id: 'name', value: authStore.userfullName },
				{ id: 'email', value: emailValue },
			],
			ticketForms: [TicketForms.CONTACT_US],
		};

		setZESettings(settings);
	};

	const handleClick = (): void => {
		let src = '';
		if (window.location.pathname.includes('/quality')) {
			src = 'quality';
		} else if (window.location.pathname.includes('/qs')) {
			src = 'dashboard';
		} else if (window.location.pathname.includes('/files')) {
			src = 'file-manager';
		} else {
			src = 'patient-portrait';
		}

		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.ContactUsClicked, { SourcePage: src });
		setSettings();
		activateZE();
	};

	return (
		enabled && (
			<ContactUsButton ref={contactUsRef} onClick={handleClick}>
				<CoreText type='title3Bold' color='accentLightGreen' cursor='pointer' text='Contact Us' />
				<Icon />
			</ContactUsButton>
		)
	);
};

export default ContactUs;
