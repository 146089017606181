// This component is used to render a colon that is invisible to the user,
// but is still selectable by the user for copy/paste purposes. Example:
//
//
// Multi Vitamin 	1 x 1/d	10/2018
// Telmisartan 40 Mg Tablet 	1 qd	03/2023
//
//
// Will be:
//
// Multi Vitamin: 	1 x 1/d	10/2018
// Telmisartan 40 Mg Tablet: 	1 qd	03/2023

const CoreInvisibleColon = () => {
	return <span style={{ color: 'transparent', pointerEvents: 'none' }}>:&nbsp;</span>;
};
export default CoreInvisibleColon;
