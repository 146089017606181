import colors from '../Colors';

export default function CheckIcon({
	height = '8px',
	width = '9px',
	color = colors.secondary,
}: {
	height?: string;
	width?: string;
	color?: string;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.14286 1L2.59214 5.28571L1 3.35714'
				stroke={color}
				strokeMiterlimit='10'
				style={{ transition: 'stroke 0.2s ease-in-out' }}
			/>
		</svg>
	);
}
