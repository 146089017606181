import ErrorBoundary from './ErrorBoundary';
import { NotFoundMessage } from './GeneralMessage';
import config from './config';
import changeFavIconColor from './favicon';
import './main.scss';
import { NavinaLoginStep } from './pages/auth/steps/NavinaLoginStepEnum';
import AddTemplate from './pages/quality-files-ingestion-tool/AddTemplate';
import { AnyoneRoute, ExternalRedirectRoute, GuestOnlyRoute, ProtectedRoute } from './react-router/auth';
import { AuthLayout, EmptyLayout, SpinnerLayout, SummaryLayout } from './react-router/layouts';
import './theme/antd-tokens/light.scss';
import { GlobalStyle } from './theme/globalStyle';
import { slownessMessure } from './utilities/performances/SlownessTask';
import { toKebabCase } from './utilities/styling/NamingFormatter';
import AlertTemplate from './utils/alertTemplate';
import history from './utils/history';
import { NavinaDataProvider } from './utils/useNavinaData';
import { theme } from 'antd';
import 'normalize.css';
import { lazy } from 'react';
import { Provider as AlertsProvider, positions } from 'react-alert';
import { Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

const EpicLogin = lazy(() => import(/* webpackChunkName: "epic-login" */ './pages/epic/login'));
const VeradigmLogin = lazy(() => import(/* webpackChunkName: "veradigm-login" */ './pages/veradigm/login'));
const Logout = lazy(() => import(/* webpackChunkName: "logout" */ './pages/auth/Logout'));
const LoginForm = lazy(() => import(/* webpackChunkName: "login-form" */ './pages/auth/LoginForm'));

const SummaryPage = lazy(() => import(/* webpackChunkName: "summary-page" */ './pages/summary'));
const PatientsList = lazy(() => import(/* webpackChunkName: "patients-list" */ './pages/patients-list'));
const AddonMonitor = lazy(() => import(/* webpackChunkName: "addon-monitor" */ './pages/addon-monitor'));
const DocViewerPage = lazy(() => import(/* webpackChunkName: "doc-viewer" */ './pages/doc-viewer'));
const Quicksight = lazy(() => import(/* webpackChunkName: "quicksight" */ './pages/quicksight'));
const PostVisit = lazy(() => import(/* webpackChunkName: "post-visit" */ './pages/post-visit'));
const AntdDemo = lazy(() => import(/* webpackChunkName: "antd-demo" */ './pages/antd-demo'));

const Quality = lazy(() => import(/* webpackChunkName: "quality" */ './pages/quality/care-team/PageContainer'));
const FileIngestionToolPage = lazy(() =>
	import(/* webpackChunkName: "file-ingestion-tool-page" */ './pages/quality-files-ingestion-tool/PageContainer').then(
		(module) => ({
			default: module.FileIngestionToolPage,
		}),
	),
);

const FilesManager = lazy(() => import(/* webpackChunkName: "files-manager" */ './pages/file-manager/PageContainer'));

const FITTemplatesPage = lazy(() =>
	import(/* webpackChunkName: "fit-templates-page" */ './pages/quality-fit-templates/PageContainer').then((module) => ({
		default: module.FITTemplatesPage,
	})),
);

const getCssVars = (): string => {
	return Object.keys(theme.useToken().token)
		.map((token): string => {
			const tokenValue = theme.useToken().token[token];
			const maybePixel = !Number.isNaN(parseInt(tokenValue, 10)) ? 'px' : '';

			return `--antd-${toKebabCase(token)}: ${tokenValue}${maybePixel};`;
		})
		.join('\n');
};

const EmptyDiv = () => <div />;

export function App() {
	slownessMessure.timeLog('React has started');

	changeFavIconColor();
	const { token } = theme.useToken();
	return (
		<ErrorBoundary>
			<ThemeProvider theme={{ antd: token }}>
				<AlertsProvider template={AlertTemplate} timeout={config.AlertTime} position={positions.TOP_CENTER}>
					<ToastContainer position='top-center' autoClose={5_000} hideProgressBar={true} />
					<GlobalStyle rootVariables={getCssVars()} />
					<Router history={history}>
						<NavinaDataProvider>
							<Switch>
								<GuestOnlyRoute exact path='/sso' layout={AuthLayout} component={LoginForm} />
								<GuestOnlyRoute path='/sso/:nextPath+' layout={AuthLayout} component={LoginForm} />
								<AnyoneRoute path='/epic/login' layout={SpinnerLayout} component={EpicLogin} spinningForever={true} />
								<AnyoneRoute
									path='/overlay/epic/login'
									layout={SpinnerLayout}
									component={EpicLogin}
									spinningForever={true}
								/>
								<AnyoneRoute path='/veradigm/launch' layout={SpinnerLayout} component={VeradigmLogin} />
								<GuestOnlyRoute path='/login/:nextPath+' layout={SpinnerLayout} component={LoginForm} />
								<GuestOnlyRoute exact path='/login' layout={SpinnerLayout} component={LoginForm} />
								<GuestOnlyRoute exact path='/overlay/login' layout={SpinnerLayout} component={LoginForm} />
								<ProtectedRoute
									exact
									path='/auth/changePassword'
									defaultStepId={NavinaLoginStep.ChangePassword}
									layout={AuthLayout}
									component={LoginForm}
								/>
								<GuestOnlyRoute exact path='/authenticatedUrl' layout={AuthLayout} component={LoginForm} />
								{/* https://github.com/aws-amplify/amplify-console/issues/97 */}
								<GuestOnlyRoute exact path='/authenticatedUrl/' layout={AuthLayout} component={LoginForm} />
								<ProtectedRoute exact path='/logout' layout={SpinnerLayout} component={Logout} />
								<ProtectedRoute exact path='/' layout={SummaryLayout} component={PatientsList} ContactUsEnabled />
								<ProtectedRoute
									exact
									path='/errorCheck'
									layout={SummaryLayout}
									component={() => {
										throw Error('Test error');
									}}
								/>
								<AnyoneRoute exact path='/addon' layout={AuthLayout} component={AddonMonitor} />
								<ExternalRedirectRoute
									layout={EmptyLayout}
									component={EmptyDiv}
									exact
									path='/athena'
									url='https://chrome.google.com/webstore/detail/navina-burnout-research-t/plhceajggdihcnffppedpfjpflpmdakh?hl=en-GB&authuser=2'
								/>
								<ExternalRedirectRoute
									layout={EmptyLayout}
									component={EmptyDiv}
									exact
									path='/ecw'
									url='https://chrome.google.com/webstore/detail/navina-addon/clkcfpclllbaegehjacadbpejiocgkii?hl=en-GB&authuser=2'
								/>
								<ProtectedRoute layout={EmptyLayout} exact path='/qs' component={Quicksight} />
								<ProtectedRoute layout={EmptyLayout} exact path='/postVisit' component={PostVisit} />
								<ProtectedRoute layout={EmptyLayout} exact path='/quality' component={Quality} ContactUsEnabled />
								<ProtectedRoute layout={EmptyLayout} exact path='/quality-satisfaction-rate' component={Quality} />
								<ProtectedRoute layout={EmptyLayout} exact path='/files' component={FilesManager} ContactUsEnabled />
								<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit' component={FileIngestionToolPage} />
								<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit-templates' component={FITTemplatesPage} />
								<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit/add-template' component={AddTemplate} />
								<ProtectedRoute layout={EmptyLayout} exact path='/antd-demo' component={AntdDemo} />
								<ProtectedRoute layout={EmptyLayout} exact path='/:summaryId' isSummaryView component={SummaryPage} />
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/:summaryId'
									isSummaryView
									component={SummaryPage}
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/hcc/:summaryId'
									isSummaryView
									component={SummaryPage}
									overlayPageName='hcc'
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/clinical/:summaryId'
									isSummaryView
									component={SummaryPage}
									overlayPageName='portrait'
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/portrait/:summaryId'
									isSummaryView
									component={SummaryPage}
									overlayPageName='portrait'
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/documents/:summaryId'
									isSummaryView
									component={SummaryPage}
									overlayPageName='documents'
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/overlay/alerts/:summaryId'
									isSummaryView
									component={SummaryPage}
									overlayPageName='alerts'
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/doc/:documentId'
									isSummaryView
									component={DocViewerPage}
								/>
								<ProtectedRoute
									layout={EmptyLayout}
									exact
									path='/document/:documentId'
									isSummaryView
									component={DocViewerPage}
								/>
								<AnyoneRoute layout={EmptyLayout} component={NotFoundMessage} />
							</Switch>
						</NavinaDataProvider>
					</Router>
				</AlertsProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
}
