import colors from './Colors';
import { useCallback, forwardRef, type ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const InnerContainer = styled.div<{ readonly width?: string; readonly $padding?: string }>`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	width: ${(props) => props.width || '40%'};
	border-radius: 10px;
	padding: 12px;
`;

type CorePopupProps = {
	onClose: VoidFunction;
	children?: ReactNode;
	backgroundColor?: string;
	width?: string;
	$padding?: string;
};

export default forwardRef<HTMLDivElement, CorePopupProps>(function CorePopup(
	{ onClose, $padding, width, backgroundColor = colors.background, children },
	ref,
) {
	const onClickOnChild = useCallback((event): void => event.stopPropagation(), []);

	return (
		<Container onClick={onClose} ref={ref} tabIndex={-1}>
			<InnerContainer width={width} $padding={$padding} style={{ backgroundColor }} onClick={onClickOnChild}>
				{children}
			</InnerContainer>
		</Container>
	);
});
