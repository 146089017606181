class Appcues {
	identify = (navinaUserId, metadata): void => {
		if (
			'Appcues' in window &&
			(typeof window.Appcues === 'object' || typeof window.Appcues === 'function') &&
			'identify' in window.Appcues &&
			typeof window.Appcues.identify === 'function'
		) {
			window.Appcues.identify(navinaUserId, metadata);
		}
	};

	track = (name, props = {}): void => {
		if (
			'Appcues' in window &&
			(typeof window.Appcues === 'object' || typeof window.Appcues === 'function') &&
			'track' in window.Appcues &&
			typeof window.Appcues.track === 'function'
		) {
			window.Appcues.track(name, props);
		}
	};
}

let maybeAppCues: Appcues | null = null;

function createAppcues(): Appcues {
	return new Appcues();
}

export function getOrCreateAppcues(): Appcues {
	if (maybeAppCues === null) {
		maybeAppCues = createAppcues();
	}

	return maybeAppCues;
}
