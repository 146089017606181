import styled from 'styled-components';

const ShadowSvg = styled.svg`
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
`;

export default function AcceptIcon({
	width = '25',
	height = '24',
	isPressed = false,
}: {
	readonly width?: string;
	readonly height?: string;
	readonly isPressed?: boolean;
}) {
	return (
		<ShadowSvg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			{isPressed ? (
				<rect
					x='0.75'
					y='0.75'
					width='23.2619'
					height='21.6111'
					rx='10.8056'
					fill='#ADA7D7'
					stroke='#ADA7D7'
					strokeWidth='1.5'
				/>
			) : (
				<rect
					x='0.75'
					y='0.75'
					width='23.2619'
					height='21.6111'
					rx='10.8056'
					fill='white'
					stroke='#ADA7D7'
					strokeWidth='1.5'
				/>
			)}
			<path
				d='M15.4469 9.07935L11.0508 14.0317L9.07959 11.8032'
				stroke='#53479E'
				strokeWidth='1.5'
				strokeMiterlimit='10'
			/>
		</ShadowSvg>
	);
}
