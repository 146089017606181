import { CoreText } from '../../base-ui';
import Logo from '../../base-ui/graphics/FullLogo';
import AuthBackground from '../../base-ui/graphics/auth/AuthBackground.svg';
import DecorationIllustration from '../../base-ui/graphics/auth/DecorationIllustration.svg';
import { NavinaLayout } from './LayoutType';
import { Box } from '@mui/material';
import { Suspense } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const PageContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;

	background: url('${AuthBackground}') no-repeat center center fixed;
	background-size: cover;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const ContentContainer = styled.div`
	overflow: hidden;
	background: rgba(255, 255, 255, 0.7);
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3);
	border-radius: 8px;

	width: 800px;
	max-width: 90%;

	@media (min-width: 601px) {
		min-height: 500px;
	}

	height: 500px;

	display: flex;
	flex-wrap: nowrap;

	animation: ${fadeIn} 0.3s ease-in;

	& > * {
		flex: 1 1 0px;
		display: flex;
		height: 100%;
	}

	flex-direction: row;

	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

const FormBlock = styled.div`
	text-align: center;
	width: 100%;
	padding: 20px;
	word-break: break-word;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-x: hidden;

	> * {
		max-width: 100%;
	}

	flex-direction: column;
`;

const DecorationBlock = styled.div`
	padding: 0;
	background: #322b5f;
	background-image: url(${DecorationIllustration});
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width: 768px) {
		max-height: 15%;
	}
`;

const LogoWrapper = styled.div`
	margin-bottom: 30px;
`;

const AuthLayout = ({ component: Component, ...props }: NavinaLayout) => (
	<PageContainer>
		<ContentContainer>
			<Box position='relative'>
				<FormBlock>
					<LogoWrapper>
						<Logo height={28} />
					</LogoWrapper>
					<Suspense fallback={<div />}>
						<Component {...props} />
					</Suspense>
				</FormBlock>
				{localStorage.getItem('ssoUrl') && (
					<Box position='absolute' marginBottom={3} bottom={0} width='100%' textAlign='center'>
						<CoreText type='body2'>
							Don't have an account?{' '}
							<CoreText type='body2' color='accentGreen' cursor='pointer'>
								<a href='mailto:support@navina.ai' target='_blank' rel='noopener noreferrer'>
									Contact support
								</a>
							</CoreText>
						</CoreText>
					</Box>
				)}
			</Box>
			<DecorationBlock />
		</ContentContainer>
	</PageContainer>
);

export default AuthLayout;
