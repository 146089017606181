import { getOrCreateAnalytics } from '../utils/analytics';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import Axios, { type AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';

export interface WithAxiosServer {
	readonly server: AxiosInstance;
}

export interface SessionsApi {
	readonly refreshSession: () => Promise<CognitoUserSession>;
	readonly resetSession: VoidFunction;
	readonly getToken: () => string;
}

const throwStatusError = (status: number, err: Error): never => {
	// eslint-disable-next-line @typescript-eslint/no-throw-literal -- Why like this?
	throw {
		name: status,
		message: `${status} Error occurred`,
		innerError: err,
	};
};

export function getErrorHandler(server: AxiosInstance, sessionsApi: SessionsApi) {
	const analytics = getOrCreateAnalytics();

	return async (error: AxiosError) => {
		if (error.response) {
			analytics.track(analytics.idsNames.ErrorHTTPRequest, {
				status: error.response.status,
				url: error.response.config.url,
				data: error.response.config.data,
				message: error.response.data.message,
			});

			switch (error.response.status) {
				case 401:
				case 403: {
					const errorResponse = error.response;

					if (errorResponse.config && !errorResponse.config['__isRetryRequest']) {
						console.log('received 401/403, will try to refresh session and retry request');
						let response: CognitoUserSession = null;

						try {
							response = await sessionsApi.refreshSession();
							analytics.track(analytics.idsNames.ErrorHTTPRequestTokenRefreshed);
						} catch (error) {
							// Couldn't get new token, better log off
							console.error("Couldn't refresh token, logging off", error);
							sessionsApi.resetSession();
							break;
						}

						// If the token was refreshed successfully, try to run it again (with flag):
						if (response) {
							console.log('get fresh token, try again');
							errorResponse.config['__isRetryRequest'] = true;

							// Set the fresh token to the old request before resending
							errorResponse.config.headers['token'] = sessionsApi.getToken();

							analytics.track(analytics.idsNames.ErrorHTTPRequestReloadRequest);

							return server(errorResponse.config);
						}
					} else {
						throwStatusError(error.response.status, error);
					}
					break;
				}
				case 404:
					break;
				case 422:
					throwStatusError(422, error);
					break;
				case 500:
					throwStatusError(500, error);
					break;
				case 503:
					throwStatusError(503, error);
					break;
				default:
					throwStatusError(error.response.status, error);
					break;
			}
		}

		return Promise.reject(error);
	};
}

export function getSuccessHandler() {
	return function replaceKeysWithCamelCase(response: AxiosResponse): typeof response {
		response.data = camelcaseKeys(response.data, { deep: true, exclude: [RegExp('.* .*'), RegExp('.*-.*-.*-.*')] });
		return response;
	};
}

export function createAxiosInstance(baseURL: string): AxiosInstance {
	return Axios.create({ baseURL });
}
