export default function ScheduleIcon() {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19 8.5V6C19 5.44772 18.5523 5 18 5H6C5.44772 5 5 5.44772 5 6V8.5M19 8.5V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V8.5M19 8.5H5'
				stroke='#ADA7D7'
				strokeWidth='1.5'
			/>
			<rect x='8' y='12' width='4' height='4' fill='#78F5CE' />
		</svg>
	);
}
