import { CoreText, CoreContainer, CoreSmallButton } from '../../../base-ui';
import colors from '../../../base-ui/Colors';
import { UserIcon } from '../../../base-ui/icons';
import { useAuthStore } from '../../../stores/authStore';
import { getOrCreateAnalytics } from '../../../utils/analytics';
import useEMRName from '../../../utils/useEMRName';
import { useNavinaData } from '../../../utils/useNavinaData';
import UserSettings from './UserSettings';
import { observer } from 'mobx-react-lite';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const SignOutButton = styled(Link)`
	border-radius: 20px;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 13px 12px 13px;
	margin-left: 25px;
	cursor: pointer;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none !important;
`;

const PopupContainer = styled(CoreContainer)`
	position: absolute;
	z-index: 3;
	top: 50px;
	right: 0;
	will-change: transform;
	width: fit-content;
	border-radius: 15px;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
`;

const HorizontalFlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
`;

const ProfileDataContainer = styled.div<{ readonly $isShowingSettings: boolean }>`
	border-radius: 15px 15px 0 0;
	box-shadow: ${(props) => (props.$isShowingSettings ? '0px 1px 2px rgba(0, 0, 0, 0.25)' : 'initial')};
	display: flex;
	flex-direction: column;
	padding: 30px 22px 30px 22px;
	justify-content: space-between;
`;

const SettingsContainer = styled.div`
	border-radius: 0 0 15px 15px;
	padding: 30px 22px 20px 22px;
`;

const defaultFloatingMenuStyle = {
	opacity: 0,
	visibility: 'hidden',
	pointerEvents: 'none',
} as const;

const floatingMenuTransitionStyles = {
	entering: { opacity: 1, visibility: 'auto', pointerEvents: 'initial' },
	entered: { opacity: 1, visibility: 'auto', pointerEvents: 'initial' },
	exiting: { opacity: 0, visibility: 'auto', pointerEvents: 'none' },
	exited: { opacity: 0, visibility: 'auto', pointerEvents: 'none' },
} as const;

const UserProfileButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	position: relative;
`;

export default observer(function UserMenuPopup() {
	const containerRef = useRef<HTMLDivElement>();

	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

	const analytics = getOrCreateAnalytics();

	const userMenuOpenClick = useCallback((): void => {
		analytics.track(analytics.idsNames.SummaryClick, {
			actionId: 'AvatarClick',
			action: isUserMenuOpen ? 'Close' : 'Open',
		});

		setIsUserMenuOpen(!isUserMenuOpen);
	}, [isUserMenuOpen, setIsUserMenuOpen]);

	const onCloseUserMenu = useCallback((): void => {
		if (isUserMenuOpen) {
			analytics.track(analytics.idsNames.SummaryClick, { actionId: 'AvatarClick', action: 'CloseClickOutside' });
			setIsUserMenuOpen(false);
		}
	}, [setIsUserMenuOpen, isUserMenuOpen]);

	const authStore = useAuthStore();
	const email = authStore.getEmail;

	const signOutHandler = useCallback((): void => {
		authStore.logout();
	}, [authStore]);

	useEffect((): VoidFunction => {
		const handleClickOutside = (e): void => {
			if (containerRef?.current?.contains(e.target)) {
				return;
			}
			onCloseUserMenu();
		};

		document.addEventListener('mousedown', handleClickOutside);

		return (): void => document.removeEventListener('mousedown', handleClickOutside);
	}, [containerRef, onCloseUserMenu]);

	const emrName = useEMRName();
	const { isOverlay } = useNavinaData();

	const isShowingSettings = (emrName === 'athena' || authStore.admin) && !isOverlay;

	return (
		<UserProfileButtonContainer data-tip data-for='user_menu_tooltip' ref={containerRef}>
			<CoreSmallButton
				isPressed={isUserMenuOpen}
				Icon={UserIcon}
				color={isUserMenuOpen ? colors.primary : colors.tertiary}
				onClick={userMenuOpenClick}
				id='userMenuAvatar'
				style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			/>
			<Transition transitionName='userMenuTransition' in={isUserMenuOpen} timeout={500}>
				{(state) => (
					<PopupContainer
						key='MenuContainer'
						style={{ ...defaultFloatingMenuStyle, ...floatingMenuTransitionStyles[state] }}
					>
						<ProfileDataContainer $isShowingSettings={isShowingSettings}>
							<CoreText type='body1BoldUnderline'>User Name</CoreText>
							<HorizontalFlexContainer>
								<CoreText type='body1Medium'>{email}</CoreText>
								{(!authStore.getIsSAML || authStore.isAdmin) && (
									<SignOutButton onClick={signOutHandler} to='/'>
										<CoreText type='captionHeader' text='Sign out' whiteSpace='nowrap' />
									</SignOutButton>
								)}
							</HorizontalFlexContainer>
						</ProfileDataContainer>
						{/* This is a hack to recreate the UserSettings on each popup */}
						{state !== 'exited' && isShowingSettings && (
							<SettingsContainer data-actionable>
								<UserSettings />
							</SettingsContainer>
						)}
					</PopupContainer>
				)}
			</Transition>
		</UserProfileButtonContainer>
	);
});
