import { PageContainerWithoutBackground } from '../../react-router/PageContainerWithoutBackground';
import { NavinaLayout } from './LayoutType';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Suspense } from 'react';


const SpinnerLayout = ({ component: Component, design, spinningForever = false, ...props }: NavinaLayout) => {
	console.log('SpinnerLayout', { Component, design, spinningForever, props });
	return (
		<PageContainerWithoutBackground>
			{spinningForever ? (
				<Suspense fallback={null}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
					<Component {...props} />
				</Suspense>
			) : (
				<Suspense fallback={<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />}>
					<Component {...props} />
				</Suspense>
			)}
		</PageContainerWithoutBackground>
	);
};

export default SpinnerLayout;
