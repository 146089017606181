import config, { configurations } from '../config';
import { IS_PRODUCTION } from '../config/Environment';
import type { ExpressPushResult } from '../modules/push2emr/push2emr-express/types';
import type { DiagnosesPushRequest, DiagnosesPushRequestEpic } from '../modules/push2emr/types';
import type { Action } from '../pages/summary/med-features/push-to-emr/types';
import { type AuthStore, getOrCreateAuthStore } from '../stores/authStore';
import type {
	AddonReportData,
	emrNames,
	EmrOnlineSyncResponse,
	QuicksightEmbedSettings,
	ScheduleFilter,
} from '../types';
import { getOrCreateAnalytics } from '../utils/analytics';
import { NEXT_URL_QUERY_PARAMS, UNITY_ENCOUNTER_ID } from '../utils/consts';
import { getOrCreateApiSessionsServer } from './apiSessions';
import { createAxiosInstance, getErrorHandler, getSuccessHandler, type WithAxiosServer } from './createAxiosInstance';
import type { CorePushResult, GetSummaryNotesResponse } from '@dxcapture/core';
import Axios, {
	type AxiosInstance,
	type AxiosRequestConfig,
	type AxiosResponse,
	type CancelToken,
	type CancelTokenSource,
} from 'axios';
import camelcaseKeys from 'camelcase-keys';
import jwtDecode from 'jwt-decode';

const CancellationTokenCreator = Axios.CancelToken;

const BASE_URL = config.ApiUrl;

const QA_BRANCH = (process.env.REACT_APP_AWS_BRANCH || '').startsWith('qa/') ? process.env.REACT_APP_AWS_BRANCH : '';

const TOKEN_EXPIRATION_BUFFER_DURATION_SECONDS = 60;

const WITH_QA_BRANCH_AND_MAYBE_CACHE = { qaBranch: QA_BRANCH, useCache: IS_PRODUCTION } as const;

type AuthHeaders = {
	token?: string;
	authorizationToken?: string;
	type?: string;
};

type AuthInfo = {
	headers?: AuthHeaders;
	path: string;
	isValid: boolean;
	isRefresh?: boolean;
};

export class ApiGatewayServer implements WithAxiosServer {
	server: AxiosInstance;
	localServer: AxiosInstance;
	authStore: AuthStore;

	constructor() {
		this.server = createAxiosInstance(BASE_URL);
		this.localServer = createAxiosInstance(configurations['local'].ApiUrl);
		this.authStore = getOrCreateAuthStore();
	}

	protected getAuthInfo = (path: string, token: string): AuthInfo => {
		if (!token || token === undefined) {
			return { path, isValid: false };
		}

		if (this.authStore.getIsSAML === true) {
			const tokenPayload = jwtDecode<{ exp: number }>(token);
			const { exp: tokenExpirationUnixTimestamp } = tokenPayload;

			const tokenExpirationTimeInMilliseconds = tokenExpirationUnixTimestamp * 1000;
			const bufferDurationInMilliseconds = TOKEN_EXPIRATION_BUFFER_DURATION_SECONDS * 1000;

			const currentTimeInMilliseconds = Date.now();
			const isTokenExpired =
				tokenExpirationTimeInMilliseconds - bufferDurationInMilliseconds < currentTimeInMilliseconds;

			if (isTokenExpired) {
				console.log('Token about to expire, redirecting to Auth0 to get new token', {
					path,
					currentUrl: window.location.href,
				});
				// We need double encoding since it is decoded twice before used in the url
				const nextUrl = encodeURIComponent(encodeURIComponent(`${window.location.pathname}${window.location.search}`));

				console.log('Redirecting to Auth0 to get new token', { nextUrl, inputPath: path });

				window.location.href = config.NavinaAuth0AuthURL(
					`${config.Auth0LoginRedirect}?${NEXT_URL_QUERY_PARAMS}=${nextUrl}`,
				);

				return { path, isRefresh: true, isValid: false };
			}

			return {
				headers: {
					authorizationToken: `Bearer ${token}`,
					type: 'TOKEN',
				},
				path: `v2/${path}`,
				isValid: true,
			};
		}

		return { headers: { token }, path, isValid: true };
	};

	protected camelCaseResponse = <T extends { [key: string]: any }>(jsonObject: T): T => {
		return camelcaseKeys<T>(jsonObject, {
			deep: true,
			exclude: [RegExp('.* .*'), RegExp('.*-.*-.*-.*')],
		});
	};

	protected baseRequest = async <TInput extends { [key: string]: any }, TOutput>(
		path: string,
		data?: TInput,
		cancelToken?: CancelTokenSource,
	): Promise<null | AxiosResponse<TOutput>> => {
		const authInfo = this.getAuthInfo(path, this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			const config: AxiosRequestConfig = {
				headers: {
					'content-type': 'application/json',
					...authInfo.headers,
				},
				...(cancelToken && { cancelToken: cancelToken.token }),
			};

			const requestPromise: Promise<AxiosResponse<TOutput>> = this.server.post(
				authInfo.path,
				JSON.stringify(data),
				config,
			);

			const awaitedResponse = await requestPromise;

			awaitedResponse.data = this.camelCaseResponse<TOutput>(awaitedResponse.data);

			return Promise.resolve(awaitedResponse);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	getFileURL = async (fileId: string, overrideEnv?: string): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getFileURL', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ id: fileId, overrideEnv }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	downloadFile = async (fileUrl: string) => Axios.get(fileUrl);

	getClinicsDepartmentsProviders = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getClinicsDepartmentsProviders', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{},
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);
		}
	};

	getUserClinicsHierarchy = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getUserClinicsHierarchy', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{},
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}
	};

	getSummariesListScheduleCancelToken: CancelTokenSource;

	getSummariesListSchedule = async (
		startDate: number,
		endDate: number,
		scheduleFilter: ScheduleFilter,
		patientLookup?: string,
	): ReturnType<typeof this._getSummariesList> => {
		if (this.getSummariesListScheduleCancelToken) {
			this.getSummariesListScheduleCancelToken.cancel('NewerRequestArrived');
		}

		this.getSummariesListScheduleCancelToken = CancellationTokenCreator.source();

		return this._getSummariesList(
			startDate,
			endDate,
			scheduleFilter,
			this.getSummariesListScheduleCancelToken.token,
			patientLookup,
		);
	};

	getSummariesListCurrentDayCancelToken: CancelTokenSource;

	getSummariesListCurrentDay = async (
		startDate: number,
		endDate: number,
		scheduleFilter: ScheduleFilter,
		patientLookup?: string,
	): ReturnType<typeof this._getSummariesList> => {
		if (this.getSummariesListCurrentDayCancelToken) {
			this.getSummariesListCurrentDayCancelToken.cancel('NewerRequestArrived');
		}

		this.getSummariesListCurrentDayCancelToken = CancellationTokenCreator.source();

		return this._getSummariesList(
			startDate,
			endDate,
			scheduleFilter,
			this.getSummariesListCurrentDayCancelToken.token,
			patientLookup,
		);
	};

	_getSummariesList = async (
		startDate: number,
		endDate: number,
		scheduleFilter: ScheduleFilter,
		cancelToken: CancelToken,
		patientLookup?: string,
	): Promise<null | AxiosResponse> => {
		const params = { startDate, endDate, ...scheduleFilter, patientLookup } as const;

		const analytics = getOrCreateAnalytics();

		const summaries = this.getSummariesFromSessionStorage(JSON.stringify(params));

		if (summaries) {
			analytics.track(analytics.idsNames.SummariesListCacheChecked, { hit: true });
			return Promise.resolve(summaries);
		}

		analytics.track(analytics.idsNames.SummariesListCacheChecked, { hit: false });

		const authInfo = this.getAuthInfo('getPermittedZones', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			try {
				const response = await this.server.post(authInfo.path, JSON.stringify({ ...params, qaBranch: QA_BRANCH }), {
					headers: { 'content-type': 'application/json', ...authInfo.headers },
					cancelToken: cancelToken,
				});

				this.storeSummariesToSessionStorage(JSON.stringify(params), response);

				return response;
			} catch (err) {
				if (err.message !== 'NewerRequestArrived') {
					throw err.innerError;
				}
			}
		}

		console.warn('_getSummariesList, token is null, resetting authStore');
		return Promise.resolve(null);
	};

	getDxCaptureData = async (summaryId: string) => {
		const authInfo = this.getAuthInfo('getDxCaptureData', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve({ refresh: true });
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				JSON.stringify({
					...WITH_QA_BRANCH_AND_MAYBE_CACHE,
					sid: summaryId,
				}),
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}

		console.warn('getDxCaptureData, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	getAppointmentDetails = async (summaryId: string) => {
		const authInfo = this.getAuthInfo('getAppointmentDetails', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve({ refresh: true });
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ ...WITH_QA_BRANCH_AND_MAYBE_CACHE, sid: summaryId }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('getAppointmentDetails, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	getSummariesFromSessionStorage = (filter: string) => {
		const summariesListValue = sessionStorage.getItem('summaries_list');

		if (summariesListValue) {
			const summariesList = JSON.parse(summariesListValue);

			if (summariesList.filter === filter && new Date(summariesList.ttl) > new Date()) {
				return summariesList.summaries;
			}
		}
	};

	storeSummariesToSessionStorage = (filter: string, summaries: unknown): void => {
		const oneHourInMilliseconds = 60 * 60 * 1000;
		const now = Date.now();
		const ttl = new Date(now + oneHourInMilliseconds);
		const value = { ttl, filter, summaries };
		sessionStorage.setItem('summaries_list', JSON.stringify(value));
	};

	getSummaryByID = async (summaryId: string) => {
		const authInfo = this.getAuthInfo('getSummaryByID', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve({ refresh: true });
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ ...WITH_QA_BRANCH_AND_MAYBE_CACHE, id: summaryId }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('GetSummaryById, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	getSummaryNotifications = async (summaryId: string): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getSummaryNotifications', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ id: summaryId }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('GetSummaryNotifications, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	getUserMetadataV2 = async (
		idToken: string,
		dataSourceId?: string,
		emrEncounterId?: string,
		unityPatientId?: string,
	) => {
		return Axios.post(
			config.ApiUrl + 'v2/getUserMetadata',
			{ emrEncounterId, dataSourceId, unityPatientId },
			{
				headers: {
					authorizationToken: `Bearer ${idToken}`,
					type: 'TOKEN',
					'content-type': 'application/json',
				},
			},
		);
	};

	getEpicTokenAndAppContext = async (input: {
		code: string;
		connectionName: string;
		dataSourceId: string;
		redirectUri?: string;
	}): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getEpicTokenAndAppContext', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify(input), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('GetSummaryNotifications, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	sendFeedback = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('submitFeedback', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	searchInOpenSearchCancelToken: CancelTokenSource;

	searchInDocs = async (searchTerm: string, NID: string, dataSourceId: number): Promise<null | AxiosResponse> => {
		this.searchInOpenSearchCancelToken = CancellationTokenCreator.source();

		const authInfo = this.getAuthInfo('searchInOpenSearch', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{ searchTerm, NID, dataSourceId },
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
					cancelToken: this.searchInOpenSearchCancelToken.token,
				},
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	sendSummaryBugReport = async (report: {
		sid: string;
		feature: string;
		priority?: string;
		shortDescription: string;
		details?: string;
	}): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('submitBugReport', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post<{ result: { succeed: boolean; itemUrl?: string; message?: string } }>(
				authInfo.path,
				report,
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	pushToEmrPmdToPl = async (extraData: any) => {
		const authInfo = this.getAuthInfo('pushToEmrPmdToPl', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		try {
			if (authInfo.isValid) {
				const res = await this.server.post<ExpressPushResult[]>(authInfo.path, extraData, {
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				});

				return res.data;
			}
		} catch (err) {
			console.error('An Error occurred in push sockets', err);
			return Promise.resolve(null);
		}

		this.authStore.reset();
		console.log('!!! should push : ', extraData);
		return Promise.resolve(null);
	};

	getEncounterStatus = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getEncounterStatus', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	updateSummaryInsightStatus = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('updateSummaryInsightStatus', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	updateUserConfig = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('updateUserConfig', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{ config: extraData },
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	getUserConfig = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getUserConfig', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, null, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	addonHealthPing = async (): Promise<AxiosResponse> => {
		return this.server.post('addonHealthPing', {}, { headers: { 'content-type': 'application/json' } });
	};

	addonHealthSubmitReport = async (extraData: AddonReportData): Promise<AxiosResponse> => {
		return this.server.post('addonHealthSubmitReport', extraData, {
			headers: { 'content-type': 'application/json' },
		});
	};

	passwordResetStartedReport = async (username: string, is180DaysReset = false): Promise<AxiosResponse> => {
		return this.server.post(
			'userResetPasswordAttempt',
			{ username, is180DaysReset },
			{ headers: { 'content-type': 'application/json' } },
		);
	};

	runPipeline = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('runPipeline', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	getICDData = async (searchText = '') => {
		const authInfo = this.getAuthInfo('getICDData', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getICDData, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		console.log(`search: server search with prefix ${searchText}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ searchText }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	getNoteTemplates = async (dataSource: number) => {
		const authInfo = this.getAuthInfo('getNoteTemplates', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getNoteTemplates, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ dataSource }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	getSnomedFromICD = async (icdCode = '') => {
		const authInfo = this.getAuthInfo('getSnomedFromICD', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getSnomedFromICD, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		console.log(`getSnomedToICD for ICD code ${icdCode}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ icdCode }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	getOnlineSyncIcds = async (sid: string, emrName: emrNames): Promise<null | string[] | EmrOnlineSyncResponse> => {
		switch (emrName) {
			case 'epic':
				return this.getICDsFromFhirApiBySID(sid);
			case 'veradigm':
				return this.getICDsFromUnityApiBySID(sid);
			default:
				return Promise.resolve(null);
		}
	};

	getICDsFromFhirApiBySID = async (sid: string): Promise<null | string[] | EmrOnlineSyncResponse> => {
		const authInfo = this.getAuthInfo('getEpicICDFromSID', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getEpicICDFromSID, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		console.log(`getEpicICDFromSID for SID ${sid}`);
		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid }), {
				headers: {
					'content-type': 'application/json',
					authorizationToken: `Bearer ${authInfo.headers['token']}`,
					type: 'TOKEN',
					...authInfo.headers,
				},
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return null;
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return null;
		}
	};

	getICDsFromUnityApiBySID = async (sid: string): Promise<null | string[] | EmrOnlineSyncResponse> => {
		const authInfo = this.getAuthInfo('getICDsFromUnityApi', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getICDsFromUnityApi, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}
		const unityEncounterId = localStorage.getItem(UNITY_ENCOUNTER_ID);
		if (!unityEncounterId) {
			console.warn(`missing unityEncounterId skipping getICDsFromUnityApi call for SID ${sid}`);
			return null;
		}
		console.log(`getICDsFromUnityApi for SID ${sid}, unityEncounterId ${unityEncounterId}`);
		try {
			const response = await this.server.post(
				authInfo.path,
				JSON.stringify({ sid, unity_encounter_id: unityEncounterId }),
				{
					headers: {
						'content-type': 'application/json',
						authorizationToken: `Bearer ${authInfo.headers['token']}`,
						type: 'TOKEN',
						...authInfo.headers,
					},
				},
			);

			if (!response || !response?.data?.message) {
				console.warn('Error:', response);
				return null;
			}

			return response.data.message;
		} catch (err) {
			console.warn('Error:', err);
			return null;
		}
	};

	addSummaryNote = async (sid: string, note: string) => {
		const authInfo = this.getAuthInfo('addSummaryNote', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryNote, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		const response = await this.server.post<unknown[]>(authInfo.path, JSON.stringify({ sid, note }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	getSummaryNotes = async (sid: string): Promise<GetSummaryNotesResponse | null> => {
		const authInfo = this.getAuthInfo('getSummaryNotes', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getSummaryNotes, token is null, resetting authStore');
			this.authStore.reset();
			Promise.resolve(null);
		}

		const response = await this.server.post<GetSummaryNotesResponse>(authInfo.path, JSON.stringify({ sid }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	apiPush = async (
		payload: DiagnosesPushRequest | DiagnosesPushRequestEpic,
		suffix = '',
	): Promise<CorePushResult[] | null> => {
		const authInfo = this.getAuthInfo(`push2emrApi${suffix}`, this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('apiPush, token is null, resetting authStore');
			this.authStore.reset();
			Promise.resolve(null);
		}

		const response = await this.server.post<CorePushResult[]>(authInfo.path, JSON.stringify({ ...payload }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	addSummaryInsightActions = async (
		nid: string,
		sid: string,
		actions: Action[],
		shouldScrub: boolean,
		isPostVisit = false,
		postVisitUpdatedIcds: string[] | undefined = undefined,
	): Promise<undefined | null | never[]> => {
		const authInfo = this.getAuthInfo('addSummaryInsightActions', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryInsightActions, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([] as never[]);
		}

		console.log(`addSummaryInsightActions for sid code ${sid}`, { actions, shouldScrub });

		try {
			const response = await this.server.post(
				authInfo.path,
				JSON.stringify({ nid, sid, shouldScrub, actions, isPostVisit, postVisitUpdatedIcds }),
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				throw Error(response.statusText);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
			throw err;
		}
	};

	updatePostVisitViewed = async (
		sid: string,
		isDoneByProvider = false,
	): Promise<boolean | undefined | null | never[]> => {
		const authInfo = this.getAuthInfo('updatePostVisitViewed', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updatePostVisitViewed, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([] as never[]);
		}

		console.log(`updatePostVisitViewed for sid code ${sid}`);
		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid, isDoneByProvider }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				return false;
			}

			return true;
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	addSummaryInsightsAgilonNonDxActions = async (
		nid: string,
		sid: string,
		agilonSuggestionId: string,
		navinaSuggestionId: string,
		userSelection: string,
		updatedAt: string,
	): Promise<null | undefined> => {
		const authInfo = this.getAuthInfo('addSummaryInsightsAgilonNonDxActions', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryInsightsAgilonNonDxActions, token is null, resetting authStore');
			this.authStore.reset();
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				{
					nid,
					sid,
					agilonSuggestionId,
					navinaSuggestionId,
					status: userSelection,
					updatedAt,
				},
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	updateSummaryInsightsNote = async (
		nid: string,
		sid: string,
		snomed: string,
		icdCode: string,
		note: string,
		noteDate: string,
		shouldPush: boolean,
		isPostVisit: boolean,
	): Promise<null | undefined> => {
		const authInfo = this.getAuthInfo('updateSummaryInsightsNotes', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updateSummaryInsightsNotes, token is null, resetting authStore');
			this.authStore.reset();
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				{
					nid: nid,
					sid: sid,
					id: icdCode,
					snomedCode: snomed,
					note: note,
					icdCode: icdCode,
					updatedAt: noteDate,
					noteDate: noteDate,
					shouldPush,
					isPostVisit,
				},
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	getReadyPostVisitsCancelToken: CancelTokenSource;

	getReadyPostVisits = async () => {
		if (this.getReadyPostVisitsCancelToken) {
			this.getReadyPostVisitsCancelToken.cancel('NewerRequestArrived');
		}

		this.getReadyPostVisitsCancelToken = CancellationTokenCreator.source();

		const authInfo = this.getAuthInfo('getReadyPostVisits', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getReadyPostVisits, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({}), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
				cancelToken: this.getReadyPostVisitsCancelToken.token,
			});

			if (!response || !response?.data) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	getQuicksightEmbedURL = async (quicksightSettings: QuicksightEmbedSettings) => {
		const authInfo = this.getAuthInfo('getQuicksightEmbedUrl', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.reject(Error('Needs Refresh'));
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ ...quicksightSettings }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.reject(Error('Not valid'));
	};

	updateSummaryUpdateViewed = async (
		sid: string,
		summaryUpdateId: string,
		isRead: boolean,
	): Promise<null | boolean | never[]> => {
		const authInfo = this.getAuthInfo('updateSummaryUpdateViewed', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updateSummaryUpdateViewed, token is null, resetting authStore');
			this.authStore.reset();

			return Promise.resolve([] as never[]);
		}

		console.log(`updateSummaryUpdateViewed for sid code ${sid}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid, summaryUpdateId, isRead }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				return false;
			}

			return true;
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	submitInAppQaResults = async (
		docId: string,
		isTitleCorrect: boolean,
		taggingLoinc: string,
		description: string,
		level1: string,
		level2: string,
		level3: string,
		notes: string,
	): Promise<null | never[] | Error> => {
		const authInfo = this.getAuthInfo('submitInAppQaResults', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('submitInAppQaResults, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([] as never[]);
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				JSON.stringify({ docId, isTitleCorrect, taggingLoinc, description, level1, level2, level3, notes }),
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				throw Error(response.statusText);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
			throw err;
		}
	};

	getInAppQaLoincsHierarchy = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getInAppQaLoincsHierarchy', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getInAppQaLoincsHierarchy, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post(authInfo.path, JSON.stringify({}), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};
}

let maybeApiGatewayServer: ApiGatewayServer | null = null;

function createApiGatewayServer(): ApiGatewayServer {
	const apiGatewayServer = new ApiGatewayServer();

	apiGatewayServer.server.interceptors.response.use(
		getSuccessHandler(),
		getErrorHandler(apiGatewayServer.server, getOrCreateApiSessionsServer()),
	);

	return apiGatewayServer;
}

export function getOrCreateApiGateway(): ApiGatewayServer {
	if (maybeApiGatewayServer === null) {
		maybeApiGatewayServer = createApiGatewayServer();
	}
	return maybeApiGatewayServer;
}
