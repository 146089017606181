import { getOrCreateQualityServerApi } from '../quality/apiGateway';
import { notification } from 'antd';
import { useEffect } from 'react';

const AddTemplate = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const json = queryParams.get('json') || '';
	const payer = queryParams.get('payer') || '';

	useEffect((): void => {
		getOrCreateQualityServerApi()
			.saveFileTemplate({ raw_json_str: json, payer: payer })
			.then((response): void => {
				if (response.data.result === 'success') {
					notification['success']({
						message: 'New file template saved, redirecting...',
						placement: 'bottomRight',
						duration: 6,
					});

					window.setTimeout((): void => {
						window.location.href = '/quality-fit-templates';
					}, 6_000);
				}
			})
			.catch((error): void => {
				console.error('Error saving file template', error);
				notification['error']({
					message: 'Failed saving new file template',
					placement: 'bottomRight',
					duration: 100,
				});
			});
	}, [json, payer]);

	return (
		<div>
			<p>Adding template...</p>
		</div>
	);
};

export default AddTemplate;
