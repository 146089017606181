import { colors } from '../base-ui/Colors';
import styled from 'styled-components';

const AlertContainer = styled.div`
	background-color: ${colors.dark};
	color: white;
	padding: 10px;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
	width: 300px;
	box-sizing: border-box;
`;

const AlertTemplate = ({ message }) => <AlertContainer>{message}</AlertContainer>;

export default AlertTemplate;
