import { AUTH_0_TOKEN, USER_METADATA } from './consts';

export function inIframe(): boolean {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export function loggedInViaSSo(): string | null {
	return (
		localStorage.getItem(USER_METADATA) && localStorage.getItem('emrUsername') && localStorage.getItem(AUTH_0_TOKEN)
	);
}
function getCurrentTimeUnix(): string {
	return new Date().getTime().toString();
}

export function getParamFromUrl(param: string): string | null {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(param);
}

export function addCurrentTimeUnixToLocalStorage(key: string): void {
	localStorage.setItem(key, getCurrentTimeUnix());
}
