import config from '../config';
import type { LogLevel } from '../utils/logger';
import { getOrCreateApiSessionsServer } from './apiSessions';
import { createAxiosInstance, getErrorHandler, getSuccessHandler, type WithAxiosServer } from './createAxiosInstance';
import type { AxiosInstance } from 'axios';

const baseURL = config.ApiUrl;

class ApiTraceServer implements WithAxiosServer {
	server: AxiosInstance;

	constructor() {
		this.server = createAxiosInstance(baseURL);
	}

	log = async (logLevel: LogLevel, message: any, username: string, extras: any = {}) => {
		const body = JSON.stringify({ message, extras, username, logLevel });
		return this.server.post('log', body, {
			headers: { 'content-type': 'application/json' },
		});
	};

	logError = async (eventType: string, error: any, info: any) => {
		const body = JSON.stringify({ event_type: eventType, error, info });
		return this.server.post('log', body, {
			headers: { 'content-type': 'application/json' },
		});
	};
}

let maybeApiTraceServer: ApiTraceServer | null = null;

function createApiTraceServer(): ApiTraceServer {
	const apiTraceServer = new ApiTraceServer();

	apiTraceServer.server.interceptors.response.use(
		getSuccessHandler(),
		getErrorHandler(apiTraceServer.server, getOrCreateApiSessionsServer()),
	);

	return apiTraceServer;
}

export function getOrCreateApiTraceServer(): ApiTraceServer {
	if (maybeApiTraceServer === null) {
		maybeApiTraceServer = createApiTraceServer();
	}

	return maybeApiTraceServer;
}
