import type { PushContext } from './modules/push2emr/types';
import type { AnnotationLineGroup } from './pages/summary/med-features/lab-trend-view/lab-graph/annotations-types';
import type {
	Diagnosis as PushToEmrDiagnosis,
	DiagnosisStatus,
	DiagnosisStatus as PushToEmrDiagnosisStatus,
	HCCs,
	SourceData,
	SourceType,
	SourceType as PushToEmrDiagnosisSourceType,
} from './pages/summary/med-features/push-to-emr/types';
import type { DiagnosisAction, HccVersions } from '@dxcapture/core';

export type AddonReportData = {
	installed: boolean;
	version?: number;
	emr?: string;
	apiPingable: boolean;
	lastDateButtonAppeared?: string;
	lastDateButtonAppearedEnabled?: boolean;
	addonEnabledByUserSetting?: boolean;
	addonEnabledByUserSettingsUpdateDate?: string;
	[id: string]: any;
};

export type AddonButtonClickBehaviorTypes = 'tab' | 'window';

type PreviousEncounter = {
	date: string;
	reasonForVisit: string;
	providers: { lastName: string; firstName: string }[];
};

export type ProblemGraphData = {
	title: string;
	data: { date: string; observationValue?: string | number; isNormal?: boolean };
};

type FileData = {
	path: string;
	numOfPages: number;
	name: string;
	date: string;
	bottomLine: string;
};

export type ProblemVitalsType = {
	observationValue: string;
	date: string;
	title: string;
};

export type ProblemDocList = {
	[name: string]: ProblemDocType[];
};

export type ProblemType = {
	title: string;
	indicateSmoker: boolean;
	indicateAlcoholism: boolean;
	icd: string;
	snomed: string;
	medications?: string[];
	medsExacerbate?: string[];
	immunizationsReccomendations?: string[];
	vitals?: ProblemVitalsType[];
	files?: FileData[];
	other?: string[];
	graphsData: ProblemGraphData[];
	docs?: ProblemDocList;
	groupedProblems?: { name: string; snomedCode: string }[];
};

type IcdDataType = {
	code: string;
	hccWeight: number;
	hccGroup?: string;
	description: string;
};

type EncountersMissingDiagnosesType = {
	data: { encounterDate: string; encounterId: number };
	snomedCode: string;
	snomedName: string;
	icdCode: string[];
	icdData: IcdDataType[];
	rankScore: number;
};

type ObservationsMissingDiagnosesType = {
	icdCode: string[];
	snomed: { code: string; description: string };
	icdData: IcdDataType[];
	data: {
		[name: string]: any;
	};
};

type MsoRecommendationBaseType = {
	suggestionType: 'Suspect' | 'Chronic';
	providerFirstName: string;
	providerLastName: string;
	date: string;
	memo: string;
	msoName: string;
};

export type AgilonNonDxType = MsoRecommendationBaseType & {
	navinaSuggestionsId: string;
	agilonSuggestionId: string;
	code: string;
	lastModified: string;
};

export type AgilonNonDxUserSelectionType = 'agree' | 'disagree' | 'ignore';

export type AgilonNonDxStatusDataType = {
	agilonSuggestionId: string;
	createdAt: string;
	navinaSuggestionId: string;
	status: AgilonNonDxUserSelectionType;
	updatedAt: string;
	updatedBy?: string;
};

type ConsultMissingDiagnosesType = {
	fileId: string;
	fileName: string;
	fileDate: string;
	fileLoinc: string;
	fileLoincName: string;
	fileLabel: string;
	lineContentOccuranceInDoc: number;
	icd: string;
	snomedCode: string;
	description: string;
	modelScore: number;
	icd10CodeLineContent: string;
};

type RafGapType = {
	icd_code: string[];
	snomed_code?: string;
	data: { modificationDate: string };
	icdData: IcdDataType[];
	snomedCode: string;
	operators?: InferredPmdLabOperatorType[];
	hccGroup: string;
	hccWeight: number;
	billable: boolean;
	billableDescendants: BillableDescendant[];
};

type InferredPmdLabOperatorDataType = {
	value: string;
	operator: string;
	date: string;
	source: string;
	fileId?: string;
	fileName?: string;
};

type InferredPmdMedicationOperatorDataType = {
	drugName: string;
	dosage: string;
	operator: string;
	date: string;
	source: string;
};

type InferredPmdLabOperatorType = {
	name: string;
	result: boolean;
	data: InferredPmdLabOperatorDataType[];
};

type InferredPmdMedicationOperatorType = {
	name: string;
	result: boolean;
	data: InferredPmdMedicationOperatorDataType[];
};

type InferredMissingDiagnosesType = {
	operators: InferredPmdLabOperatorType[] | InferredPmdMedicationOperatorType[];
	icdCode: string;
	hccWeight: number;
	icdData: IcdDataType[];
	plInclusion?: {
		snomedCode: string;
		description: string;
	}[];
};

export type NoteData = {
	text: string;
	authorId?: string;
	authorName: string;
	authorType: 'provider' | 'coder';
	shouldPush: boolean;
	noteDate: string;
	insertionDate: unknown;
	addedInPostVisit?: boolean;
};

export type DxFromEmr = {
	icd10: {
		code: string;
		description: string;
	};
	snomed: {
		code: string;
		description: string;
	};
};

type ConsolidatedMissingDiagnosesType = {
	icdCode: string;
	icdDescription: string;
	hccWeight: number;
	hccGroup: string;
	snomedDescription: string;
	reasons: {
		consult?: ConsultMissingDiagnosesType;
		inferred?: ObservationsMissingDiagnosesType | InferredMissingDiagnosesType;
		encounter?: EncountersMissingDiagnosesType;
	};
	icdData: DocIcdData[];
	sourceData?: SourceData;
	date?: Date;
	billable?: boolean;
	billableDescendants?: BillableDescendant[];
};

export type RecommendedDiagnosis = {
	icdCode: string;
	icdDescription: string;
	hccWeight: number;
	hccGroup: string;
	snomedCode: string;
	snomedDescription: string;
	sourceData?: SourceData;
	diagnosisSources: DiagnosisSources[];
	icdData: DocIcdData[];
	date: Date;
	billable?: boolean;
	billableDescendants?: BillableDescendant[];
	isPrimary: boolean;
	addressedGroup?: DiagnosisAddressedGroup;
	noteData?: NoteData;
	companionOrder: number;
	primaryDx: string;
	companionDxs: string[];
	isNavinaCoupledSuggestion: boolean;
	isCoupledSuggestion: boolean;
	companionAddressedGroup: DiagnosisAddressedGroup;
	dxId: string;
	addedInPostVisit?: boolean;
};

type SuggestedDiagnoses = {
	dxSuggestions: RecommendedDiagnosis[];
	isValid: boolean;
	actionsHistory?: any[];
};

export type DiagnosisSourceType =
	| 'pl_gap'
	| 'raf_gap'
	| 'imaging_inferred'
	| 'hie_encounters'
	| 'external_suggestion'
	| 'ner_from_consults'
	| 'claims'
	| 'claim_cms'
	| 'consults'
	| 'agilon'
	| 'inferred'
	| 'manually_from_document';

type DocumentMetadata = {
	name: string;
	id: string;
	date: string | null;
	searchTerm?: string;
	searchTermOccurrence?: number;
};

type DiagnosisSourceRow = {
	key: string;
	value: string | null;
	contentType?: 'text/plain' | 'text/html' | 'text/markdown';
	label?: string;
	document?: DocumentMetadata;
};

enum InferredReasons {
	PhyscialExam = 'Physical Exam',
	Medication = 'rx',
	PHQ = 'PHQ',
	Others = 'Others',
}

interface InferredDiagnosesSourceRow extends DiagnosisSourceRow {
	subtype: InferredReasons;
}

type DiagnosisSourceTable = {
	title: string;
	rows: DiagnosisSourceRow[];
	date?: string | null;
}[];

export type DiagnosisSources = {
	sourceName: string;
	date?: string | null;
	type: DiagnosisSourceType;
	document?: DocumentMetadata;
	data: DiagnosisSourceTable;
	extraData?: DiagnosisSourceRow[];
};

export type SortValues = 'Recapture first' | 'Suspect first' | 'Condition';

export type ConfidenceLabel =
	| 'Recapture'
	| 'Suspect'
	| 'Suspect: Condition documented elsewhere'
	| 'Suspect: Meets diagnostic criteria'
	| 'Suspect: Clinical correlation needed'
	| null;

export type ConfidenceLabelHoverText =
	| 'Condition previously documented in the EHR'
	| 'Requires clinical validation'
	| 'Condition is not documented in the problem list or previous encounters in this EHR'
	| 'See Sources for more information'
	| 'Condition suspected, clinical correlation required'
	| '';

type confidenceLabelMap = {
	[key in ConfidenceLabel]: ConfidenceLabelHoverText;
};

export const confidenceLabelToolTipDict: confidenceLabelMap = {
	Recapture: 'Condition previously documented in the EHR',
	Suspect: 'Requires clinical validation',
	'Suspect: Condition documented elsewhere':
		'Condition is not documented in the problem list or previous encounters in this EHR',
	'Suspect: Meets diagnostic criteria': 'See Sources for more information',
	'Suspect: Clinical correlation needed': 'Condition suspected, clinical correlation required',
};

export const InAppExplanationIconTexts = {
	ReaddressDiagnosesInAppExplanation: 'Diagnoses that have been suggested by the coder to be readdressed',
	NewHCCInAppExplanation: 'Diagnoses from HCC groups that have not yet been addressed this year',
	NewHCCMidYearInAppExplanation: 'Diagnoses from HCC groups that have not yet been addressed this year',
	ExistingHCCInAppExplanation: 'Diagnoses from HCC groups that have been addressed this year',
	ExistingHCCMidYearInAppExplanation: 'Diagnoses from HCC groups that have been addressed this year',
	ProviderRejectedInAppExplanation: 'Diagnoses that had been previously rejected by the provider',
	AgilonNotesInAppExplanation: 'Clinical and care recommendations for the provider to agree or disagree with',
	NoHCCInAppExplanation: 'Non-HCC Diagnosis suggestions',
	CartProviderInAppExplanation:
		'All the selected diagnoses for this encounter. Clicking Add to Encounter will send to the EMR',
	CartCoderInAppExplanation:
		'All the selected diagnoses for this encounter. Clicking "Finish Scrub" will send the diagnosis suggestions to the provider',
};

export type SuggestedDiagnosis = {
	dxId: string;
	icdCode: string;
	icdDescription: string;
	dxDescription?: string;
	hccs: HCCs;
	snomedCode: string;
	snomedDescription: string;
	sourceData: SourceData;
	source?: string;
	diagnosisSources: DiagnosisSources[];
	suggestionDateTime: string;
	billable: boolean;
	billableDescendants: BillableDescendant[];
	diagnosisOptionalIcds?: OptionalIcd[];
	chosenOptionalIcd: OptionalIcd;
	isOptionalIcd: boolean;
	isChosenOptionalIcd: boolean;
	isBillableSubDx: boolean;
	addressedGroup: DiagnosisAddressedGroup | null;
	noteData: NoteData | null;
	addedInPostVisit: boolean;
	type: SourceType;
	isSyncedFromEmr: boolean;
	showInCart: boolean;
	showInInsights: boolean;
	isValid: boolean;
	overrideFilter: string | null;
	invalidOverride: boolean;
	actionsHistory: any[];
	status: DiagnosisStatus | null;
	statusUpdatedAt: string | null;
	statusUpdatedBy: string | null;
	actionUsername: string;
	statusUpdatedTimestamp: number;
	statusAddedTimestamp: number;
	captionInfo?: string | null;
	lastCoderStatus: DiagnosisStatus | null;
	lastCoderStatusUpdatedAt: string | null;
	lastProviderStatus: DiagnosisStatus | null;
	lastProviderStatusUpdatedAt: string | null;
	isNavinaCoupledSuggestion: boolean;
	isCoupledSuggestion: boolean;
	companionOrder: number;
	isPrimary: boolean;
	primaryDx: string | null;
	companionDxs: string[] | null;
	companionAddressedGroup: DiagnosisAddressedGroup | null;
	isCoupledSuggestionCart: boolean;
	isPrimaryCart: boolean | null;
	companionOrderCart: number | null;
	companionDxsCart: string[] | null;
	primaryDxCart: string | null;
	companionAddressedGroupCart: DiagnosisAddressedGroup | null;
	numberOfSources: number;
	// Carry forward types
	isManualFromPreviousAppointments?: boolean;
	isFromRescheduledAppointment?: boolean;
	rescheduledAppointmentStartTimeUnix?: number;
	lastProviderStatusUpdatedByName: string | null;
	lastCoderStatusUpdatedByName: string | null;

	isCoderStatusFromCurrentSid?: boolean;
	isProviderStatusFromCurrentSid?: boolean;

	diagnosisAction: DiagnosisAction | 'NO_ACTION';

	greyedOutReason?: string;
	companionId: string | null;
	confidenceLabel: ConfidenceLabel;
};

export interface BillableDescendant {
	billable: boolean;
	icdCode: string;
	icdDescription: string;
	hccs: HCCs;
}

export interface OptionalIcd {
	icdCode: string;
	icdDescription: string;
	hccs: HCCs;
	hasIcd?: boolean;
	snomedCode: string;
	addressedGroup?: DiagnosisAddressedGroup;
}

type MissingDiagnosesType = {
	// This is the old structure of pmd, not supported anymore (25/02/21, Ron)
	// encounters: EncountersMissingDiagnosesType[];
	// observations: ObservationsMissingDiagnosesType[];
	previousYears: RafGapType[];
	encountersConfig: { threshold: number };
	consultsConfig: { threshold: number };
	consolidatedPmd: { [snomedCode: string]: ConsolidatedMissingDiagnosesType };
	hccRecommendations: SuggestedDiagnoses[];
};

export type RafDiagnosis = {
	icdCode?: string;
	description: string;
	hccs: HCCs;
	date?: string;
	snomedCode?: string;
	hccGroup?: string;
	hccWeight?: number;
};

export type DiagnosisAddressedGroup =
	| 'NewHCCGroup'
	| 'AddressedHCCGroup'
	| 'ReAddressed'
	| 'RejectedByProvider'
	| 'NoHcc'
	| 'none';

type RafType = {
	raf?: number;

	rafUnion?: number;
	updatedRaf?: number;
	previousYearRaf?: number;
	elig?: 'CND' | 'CNA';
	currentYearDiagnoses?: RafDiagnosis[];
	previousYearDiagnoses?: RafDiagnosis[];
	rafCalculationPeriod?: 'half-year' | 'year' | null;
	currentRafV28?: number;
	currentRafV24?: number;
};

export type DemographicsType = {
	patientEmrId: string;
	dataSource: number;
	multiplePatientIds: string[];
	age: number;
	appointmentEmrDepartmentId: number;
	martialStatus: string;
	occupation: string;
	sex: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	race: string;
	insurances?: string;
	insuranceType?: string;
};

export type LabDataType = {
	value: string;
	originalValue: string;
	low: number;
	high: number;
	rangeType: string;
	date: string;
	units: string;
	isNormal: boolean;
	producerId?: string;
	source?: LabSource;
	sourceDetails?: string;
	history?: any[];
};

export type LabType = {
	data: LabDataType;
	date?: string;
	name: string;
	type: string;
	child: LabType;
	docName?: string;
	docId?: string;
	displayName?: string;
	source?: LabSource;
	sourceDetails?: string;
	originalValue?: number;
};

export enum LabSource {
	HIE = 'HIE',
	OCR = 'OCR',
	REDOX_EVENT = 'redox-event',
}

export enum DocSourceType {
	HIE = 'HIE',
	EHR = 'EHR',
}

interface TrendLab {
	date: string;
	observationValue: null | number;
	originalValue: null | string;
	observationNote: null | string;
	source: LabSource;
	sourceDetails: null | string;
}

export type TrendViewLab = {
	annotationLineGroups: AnnotationLineGroup[];
	label: string;
	yAxisMin?: number;
	yAxisMax?: number;
	maxThresholdLine?: number;
	minThresholdLine?: number;
	units: string;
	history: TrendLab[];
};

export type ImmunizationReccomendation = {
	name: string;
	reason: string;
};

export type MedicalAlert = {
	name: string;
	text: string;
	reason: string;
};

export type StatusProps = {
	crawlerStatus?: boolean;
	etlStatus?: boolean;
	automatedSummaryStatus?: boolean;
	modified?: Date;
};

export type DisplayDoc = {
	name: string;
	primaryName: string;
	secondaryName: string;
	predictionCodes: string[];
	date: string;
	emrInsertionDate: string;
	clinicalDocDate: string;
	id: string;
	loinc?: string;
	classifiedLabel?: string;
	classifiedLoinc?: string;
	classifierTags?: string[];
	classifierName?: string;
	codeOrigin?: string;
	icdsData?: DocIcdData[];
	source: DocSource;
	textHighlight?: string[];
	primaryNameHighlight?: string;
	secondaryNameHighlight?: string;
	icdDescriptionHighlights?: string[];
	icdCodeHighlights?: string[];
	impression?: string;
	hash?: string;
	fileUrlPayload?: FileUrlPayload;
};

export type DocIcdData = {
	description: string;
	icd10Code: string;
	icd10CodeLineContent: string;
	icd10CodeLine: number;
	snomed?: string;
	hccs: HCCs;
	hccWeight: number;
	hccGroup: string;
};

export type DocSource = {
	name: string;
	type: string;
};

export type DiagnosisOverviewHieSource = {
	fileName: string;
	source: DocSource;
};

export type DocsByDISystemGroup = {
	[type: string]: DisplayDoc[];
};

export type ProblemListRawItem = {
	name: string;
	snomedCode: string;
};

export type Medication = {
	name: string;
	dosage: string;
	frequency: string;
	medicationDate: string;
	daysLeft: number | null;
	atcLevel2?: string;
	atcLevel5?: string;
	displayInMedicationsList?: boolean;
	rxcui?: string;
};

export type PreventivesType = {
	bmi: unknown;
	bp: unknown;
	breastCancerScreening: unknown;
	pap: unknown;
	boneDensity: unknown;
	ascvdData: {
		value?: number;
		missingElements?: string[];
		outOfRangeElements?: string[];
		status?: 'success' | 'age_failure' | 'dx_failure' | 'other_failure';
	};
	// Deprecated, kept for backward compatibility of old summaries
	colonoscopy: {
		value: { procedure?: string; pathology?: string; note?: string };
		date?: any;
		isFromSurgicalHistory: boolean;
		source?: string;
	};
	colonCancerScreening: {
		value: { procedure?: string; pathology?: string; note?: string } | string;
		date?: any;
		isFromSurgicalHistory?: boolean;
		isColonoscopy?: boolean;
		source?: string;
	};
};

export type PatientData = {
	canSeePush2Emr: boolean;
	timezone: string;
	clinicName: string;
	allDocs: DisplayDoc[];
	imagingAndTests: DocsByDISystemGroup;
	modified: Date;
	providerName: string;
	emrName: emrNames;
	id: string;
	sid: string;
	nid: string;
	date: string;
	startTime: string;
	startTimeUnix: number;
	endTimeUnix: number;
	reasonForVisit: string;
	allergies: string[];
	demographics: DemographicsType;
	raf?: RafType;
	previousEncounter: PreviousEncounter;
	lastWellnessEncounter?: PreviousEncounter;
	socialHistory: {
		isSmoker: boolean;
		isFormerSmoker: boolean;
		alcoholScreening?: boolean;
		numberOfChildren?: number;
	};
	preventive: PreventivesType;
	problemList: ProblemType[];
	problemListRaw: string[] | ProblemListRawItem[];
	medications: Medication[];
	immunizationsRecommendations: ImmunizationReccomendation[];
	labs: LabType[];
	labsToShow: LabType[];
	familyHistory: any;
	surgicalHistory: any;
	missingDiagnoses: MissingDiagnosesType;
	agilonNonDxSuggestions: AgilonNonDxType[];
	hospitalization: NoteType[];
	medicalAlerts: MedicalAlert[];
	visitType: string;
	dataSourceUrl?: string;
	dataSourceId?: number;
	emrDepartmentId: string;
	emrAppointmentId: string;
	summaryInsightStatus: SummaryInsightStatus[];
	summaryInsightActions: SummaryInsightAction[];
	summaryInsightNotes: SummaryInsightNote[];
	summaryInsightAgilonNonDxActions: AgilonNonDxStatusDataType[];
	lastPostVisitTime: string;
	lastScrubTime: string;
	lastVisitScrubTime: string;
	scrubberName: string;
	pushToEmrId?: string;
	pushContext: PushContext;
	summarySuggestions: SuggestedDiagnosis[][];
	nonRelevantDxs: string[];
} & StatusProps;

type SummaryInsightNote = {
	id: string;
	icdCode: string;
	sid: string;
	note: string;
	updatedBy: string;
	updatedByFullName: string;
	role: 'provider' | 'coder';
	updatedAt: string;
	shouldPush: boolean;
	noteDate: string;
	insertionDate: string;
	addedInPostVisit: boolean;
};

export type SummaryInsightStatus = {
	id: string;
	navinaUserId: string;
	sid: string;
	status: string;
	type: string;
};

type SummaryInsightAction = {
	id: string;
	sid: string;
	snomedCode: string;
	icdCode: string;
	metadata: PushToEmrDiagnosis;
	status: PushToEmrDiagnosisStatus;
	type: PushToEmrDiagnosisSourceType;
	updatedAt: string;
	updatedBy: string;
};

export type SummaryRecord = {
	id: string;
	nid: string;
	startTime: number;
	endTime: number;
	startTimeUnix: number;
	endTimeUnix: number;
	reasonForVisit: string;
	patientEmrId: string;
	previousEncounter?: PreviousEncounter;
	modified: Date;
	timezone: string;
	clinicName: string;
	demographics?: DemographicsType;
	providerName: string;
	visitType: string;
	summaryStatusErrorMessage: string;
	lastScrubTime: string;
} & StatusProps;

export type ProblemDocType = {
	id: string;
	loinc: string;
	name: string;
	primaryName: string;
	secondaryName: string;
	date?: string;
};

export type SurgicalHistoryEntry = {
	surgeryName: string;
	surgeryDate: string;
	freeText: string;
	addedToEmrDate: string;
	addedToEmrBy: string;
};

export type NoteType = {
	name: string;
	docName: string;
	id: string;
	date: string;
	description?: string;
	source?: string;
	hash?: string;
};

export type FileUrlPayload = {
	url: string;
	isCopyable: boolean;
	filename: string;
	docSource: DocSource;
};

export type OpenSearchHit = {
	highlight: {
		text?: string[];
		primaryName?: string[];
		secondaryName?: string[];
		icdsDataDescription?: string[];
		icdsDataIcd10Code?: string[];
	};
	id: string;
	index: string;
	score: number;
	source: {
		codeOrigin: string;
		date: string;
		emrFileId: string;
		emrName: string;
		emrPatientId: string;
		icdsData: any;
		id: string;
		indexingDate: string;
		loinc: string;
		metadata: string;
		name: string;
		nid: string;
		numOfPages: string;
		predictionCodes: string[];
		primaryName: string;
		secondaryName: string;
		text: string;
	};
	type: string;
};

export type emrNames = 'ecw' | 'athena' | 'epic' | 'veradigm';
export type TabsViewTabName = 'hcc' | 'portrait' | 'documents' | 'alerts' | 'quality';

export type ScheduleFilter = {
	selectedClinicId: number;
	selectedDepartmentId: number;
	selectedProviderIds: number[];
	selectedInsuranceFilterGroups: number[];
};

export type QuicksightEmbedSettings = {
	clinics: Object;
	hccVersion: HccVersions;
};

export type UserClinicsHierarchyType = {
	clinics: { id: number; name: string; marketId: number }[];
	markets: { id: number; name: string; orgId: number }[];
	organizations: { id: number; name: string }[];
};

export type TabId = 'dxInsights' | 'clinicalSummary' | 'clinicalDocuments' | 'clinicalAlerts';

export type EmrOnlineSyncResponse = {
	icd10Codes: DxFromEmr[];
};

export enum identityProviders {
	Auth0 = 'auth0',
	Navnia = 'navina',
}

export type PickOptional<T extends Object> = { [Key in keyof T as {} extends Pick<T, Key> ? Key : never]-?: T[Key] };
