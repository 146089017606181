import colors from './Colors';
import CoreText, { type CoreTextProps } from './CoreText';
import styled from 'styled-components';

interface CoreLabelType extends Omit<CoreTextProps, 'color' | 'type'> {
	text: string;
	color?: string;
	size?: 's' | 'm';
}

const Container = styled.div<{ readonly size: 's' | 'm'; readonly color: string }>`
	background-color: ${(props) => props.color || colors.label};
	border-radius: 10px;
	height: ${(props) => (props.size === 's' ? 16 : 19)}px;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: min-content;
	padding: 2px 6px 2px 6px;
	line-height: ${(props) => (props.size === 's' ? 10 : 14)}px;
`;

export default function CoreLabel({ text, size = 'm', color, ...other }: CoreLabelType) {
	return (
		<Container size={size} color={color}>
			<CoreText whiteSpace='nowrap' type={size === 's' ? 'captionRegular' : 'captionAllDocs'} text={text} {...other} />
		</Container>
	);
}
