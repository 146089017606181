import config from '../config';
import { initialize, type LDFlagValue, type LDClient, type LDContext } from 'launchdarkly-js-client-sdk';

class FeatureFlag {
	client: LDClient | null = null;
	flagsAreInitialized = false;

	init = (id: string, email: string, custom: LDContext['custom'], onFlagsAreInitialized?: VoidFunction): void => {
		const customClone: LDContext['custom'] = { ...custom };

		if (!customClone) {
			delete customClone.first_name;
			delete customClone.last_name;
		}

		const user: LDContext = { key: id, email, custom: customClone };

		this.client = initialize(config.LaunchDarklyKey, user, { bootstrap: 'localStorage' });
		this.client.on('initialized', (): void => {
			this.flagsAreInitialized = true;
			onFlagsAreInitialized?.();
		});
	};

	getFeatureValue = <T extends LDFlagValue>(featureName: string, defaultValue: T): T => {
		if (!this.client) {
			throw Error('FeatureFlag client is not initialized');
		}

		return this.client.variation(featureName, defaultValue);
	};
}

let maybeFeatureFlag: FeatureFlag | null = null;

function createFeatureFlag(): FeatureFlag {
	return new FeatureFlag();
}

function getOrCreateFeatureFlag(): FeatureFlag {
	if (maybeFeatureFlag === null) {
		maybeFeatureFlag = createFeatureFlag();
	}
	return maybeFeatureFlag;
}

export const featureFlag = getOrCreateFeatureFlag;
export const initFeatureFlag = featureFlag().init;
