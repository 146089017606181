export default function SurgicalHistoryIcon() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0)'>
				<rect x='6' y='19' width='2' height='12' fill='#78F5CE' />
				<path
					d='M5 19C5 18.4477 5.44772 18 6 18H8V30C8 30.5523 7.55228 31 7 31H6C5.44772 31 5 30.5523 5 30V19Z'
					stroke='#322B5F'
					strokeWidth='2'
				/>
				<path
					d='M5 6C5 4.13616 6.27477 2.57006 8 2.12602V18H7.5V14.5C7.5 12.9145 6.44574 11.5752 5 11.1449V6Z'
					stroke='#322B5F'
					strokeWidth='2'
				/>
				<path d='M13 14L19 20V8L13 14Z' fill='#78F5CE' />
				<circle cx='16' cy='28' r='3' stroke='#322B5F' strokeWidth='2' />
				<path d='M16 25L16 1' stroke='#322B5F' strokeWidth='2' />
				<circle
					cx='25.4351'
					cy='23.5061'
					r='3'
					transform='rotate(45 25.4351 23.5061)'
					stroke='#322B5F'
					strokeWidth='2'
				/>
				<path d='M23.314 25.6274L12.0003 14.3137L23.314 3' stroke='#322B5F' strokeWidth='2' />
			</g>
			<defs>
				<clipPath id='clip0'>
					<path d='M0 0H32V32H0V0Z' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
