type TrashCanIconProps = {
	color?: string;
	cursor?: string;
};

export default function TrashCanIcon({ color = '#322B5F', cursor = 'inherited' }: TrashCanIconProps) {
	return (
		<svg cursor={cursor} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect width='24' height='24' fill='white' />
			<path d='M17 6L14 3H10L7 6H17Z' fill='#7AF5CE' stroke={color} strokeWidth='1.5' />
			<path d='M14 16V10' stroke={color} strokeWidth='1.5' />
			<path d='M10 16V10' stroke={color} strokeWidth='1.5' />
			<mask id='path-4-inside-1_128_9241' fill='white'>
				<rect x='5' y='5' width='14' height='16' rx='1' />
			</mask>
			<rect
				x='5'
				y='5'
				width='14'
				height='16'
				rx='1'
				stroke='#322B5F'
				strokeWidth='3'
				strokeLinejoin='round'
				mask='url(#path-4-inside-1_128_9241)'
			/>
			<path d='M4 6L20 6' stroke={color} strokeWidth='1.5' strokeLinejoin='round' />
		</svg>
	);
}
