import { CoreText, CoreTable } from './';
import { useCallback, useMemo } from 'react';

type CoreSimpleTableProps = {
	columns: string[];
	rows: any[];
	columnToRowFieldNameMapper?: (columnName: string) => string;
	showHeader?: boolean;
};

export default function CoreSimpleTable({
	rows,
	columns,
	columnToRowFieldNameMapper,
	showHeader,
}: CoreSimpleTableProps) {
	const simpleRenderer = useCallback(
		// eslint-disable-next-line react/no-unstable-nested-components -- Too complex to refactor
		(columnName) => (row) => {
			return (
				<CoreText type='body1'>
					{row[columnToRowFieldNameMapper ? columnToRowFieldNameMapper(columnName) : columnName]}
				</CoreText>
			);
		},
		[columnToRowFieldNameMapper],
	);

	const columnsWithRenderer = useMemo(
		() => columns.map((name) => ({ name, renderer: simpleRenderer(name) })),
		[columns, simpleRenderer],
	);

	return <CoreTable rows={rows} columns={columnsWithRenderer} showHeader={showHeader} />;
}
