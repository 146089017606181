export default function MedicationsIcon() {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0' maskUnits='userSpaceOnUse' x='11' y='4' width='19' height='28'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M30 4H11V23H13.5C15.9853 23 18 25.0147 18 27.5C18 29.9853 15.9853 32 13.5 32H30V4Z'
					fill='#C4C4C4'
				/>
			</mask>
			<g mask='url(#mask0)'>
				<rect x='21' y='19' width='7' height='9' fill='#78F5CE' />
				<path
					d='M16.6099 13.7869L17.0667 13.4914V12.9474V10H23.9333V12.9474V13.4914L24.3901 13.7869L27.5433 15.8274C27.8281 16.0117 28 16.3278 28 16.667V31H13V16.667C13 16.3278 13.1719 16.0117 13.4567 15.8274L16.6099 13.7869Z'
					stroke='#322B5F'
					strokeWidth='2'
				/>
				<mask id='path-5-inside-1' fill='white'>
					<rect x='14' y='4' width='13' height='7' rx='0.5' />
				</mask>
				<rect
					x='14'
					y='4'
					width='13'
					height='7'
					rx='0.5'
					stroke='#322B5F'
					strokeWidth='4'
					mask='url(#path-5-inside-1)'
				/>
			</g>
			<rect x='3' y='24' width='6' height='6' fill='#78F5CE' />
			<rect x='2' y='24' width='15' height='7' rx='3.5' stroke='#322B5F' strokeWidth='2' />
		</svg>
	);
}
