import { NavinaRoute } from './RouteType';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';

interface RedirectorProps {
	readonly url: string;
}

const Redirector = ({ url }: RedirectorProps) => {
	useEffect(() => {
		window.location.href = url;
	}, []);

	return null;
};

interface ExternalRedirectRouteProps extends NavinaRoute, RedirectorProps {}

const ExternalRedirectRoute = ({ url, ...rest }: ExternalRedirectRouteProps) => {
	return <Route render={(props) => <Redirector url={url} {...rest} {...props} />} />;
};

export default observer(ExternalRedirectRoute);
