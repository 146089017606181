export default function InsightsTabIcon({
	width = '26',
	height = '28',
	greenDotOn = true,
}: {
	width?: string;
	height?: string;
	greenDotOn?: boolean;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_i_2782_45775)'>
				{greenDotOn && (
					<path
						d='M11.6216 10.1632C10.7733 11.827 8.2051 11.9656 7.02701 11.827C6.67358 10.5792 9.85442 5.58777 12.6818 6.00366C12.6818 6.69692 12.4698 8.49938 11.6216 10.1632Z'
						fill='#78F5CE'
					/>
				)}
				<path
					d='M9.98663 18.629C9.87665 17.6483 9.46435 17.0837 8.62725 16.1837C8.0799 15.5991 7.65434 14.9097 7.37578 14.1563C7.09721 13.4029 6.9713 12.6007 7.00548 11.7973C7.07649 10.308 7.68652 8.89686 8.72001 7.83099C9.75346 6.76515 11.1386 6.11879 12.613 6.01437C13.4336 5.95695 14.2571 6.07204 15.0313 6.35237C15.8056 6.63269 16.5137 7.07214 17.1109 7.64287C17.7066 8.20892 18.1814 8.89177 18.5062 9.64947C18.8311 10.4072 18.9991 11.2239 19 12.0494C19.001 13.5775 18.4267 15.0489 17.3933 16.1661C16.5187 17.1165 16.1188 17.6533 16.0189 18.629M9.98663 18.629C9.65798 18.6981 9.36274 18.8789 9.1503 19.1411C8.93784 19.4034 8.82097 19.7312 8.81921 20.0698C8.81746 20.4085 8.93092 20.7376 9.14066 21.0021C9.3504 21.2665 9.64374 21.4504 9.97161 21.523M9.98663 18.629H16.0189M9.97161 21.523C9.79144 21.7161 9.66601 21.9546 9.60851 22.2134C9.55102 22.4723 9.56354 22.742 9.64469 22.9943C9.72592 23.2466 9.87294 23.4722 10.0702 23.6475C10.2675 23.8228 10.508 23.9415 10.7663 23.9909M9.97161 21.523H16.0339M10.7663 23.9909C10.8248 24.5482 11.0856 25.0639 11.4985 25.4387C11.9115 25.8135 12.4473 26.0209 13.0027 26.0209C13.5583 26.0209 14.0941 25.8135 14.507 25.4387C14.9199 25.0639 15.1808 24.5482 15.2392 23.9909M10.7663 23.9909H15.2392M15.2392 23.9909C15.4976 23.9415 15.7379 23.8228 15.9353 23.6475C16.1325 23.4722 16.2796 23.2466 16.3608 22.9943C16.442 22.742 16.4545 22.4723 16.3971 22.2134C16.3395 21.9546 16.2141 21.7161 16.0339 21.523M16.0339 21.523C16.3618 21.4504 16.6551 21.2665 16.8649 21.0021C17.0745 20.7376 17.1881 20.4085 17.1863 20.0698C17.1845 19.7312 17.0676 19.4034 16.8552 19.1411C16.6427 18.8789 16.3475 18.6981 16.0189 18.629'
					stroke='#322B5F'
					strokeMiterlimit='10'
				/>
				<path
					d='M13.3872 1.54545C13.3872 1.24421 13.1567 1 12.8724 1C12.588 1 12.3575 1.24421 12.3575 1.54545V3.72723C12.3575 4.02848 12.588 4.27268 12.8724 4.27268C13.1567 4.27268 13.3872 4.02848 13.3872 3.72723V1.54545Z'
					fill='#322B5F'
				/>
				<path
					d='M5.3873 4.65838C5.18624 4.44537 4.86027 4.44537 4.65921 4.65838C4.45815 4.87139 4.45815 5.21675 4.65921 5.42976L6.11538 6.97251C6.31644 7.18552 6.64242 7.18552 6.84347 6.97251C7.04453 6.7595 7.04453 6.41414 6.84347 6.20113L5.3873 4.65838Z'
					fill='#322B5F'
				/>
				<path
					d='M21.0832 5.4299C21.2842 5.21688 21.2842 4.87153 21.0832 4.65852C20.8821 4.44551 20.5561 4.44551 20.3551 4.65852L18.8989 6.20127C18.6978 6.41428 18.6978 6.75964 18.8989 6.97265C19.1 7.18566 19.4259 7.18566 19.627 6.97265L21.0832 5.4299Z'
					fill='#322B5F'
				/>
				<path
					d='M24.4852 13.5455C24.7695 13.5455 25 13.3013 25 13C25 12.6988 24.7695 12.4546 24.4852 12.4546H22.4258C22.1415 12.4546 21.911 12.6988 21.911 13C21.911 13.3013 22.1415 13.5455 22.4258 13.5455H24.4852Z'
					fill='#322B5F'
				/>
				<path
					d='M1.51484 12.4543C1.2305 12.4543 1 12.6985 1 12.9997C1 13.301 1.2305 13.5452 1.51484 13.5452H3.57418C3.85851 13.5452 4.08901 13.301 4.08901 12.9997C4.08901 12.6985 3.85851 12.4543 3.57418 12.4543H1.51484Z'
					fill='#322B5F'
				/>
			</g>
			<defs>
				<filter
					id='filter0_i_2782_45775'
					x='1'
					y='1'
					width='24'
					height='26.0209'
					filterUnits='userSpaceOnUse'
					floodOpacity='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='0.5' />
					<feGaussianBlur stdDeviation='0.5' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
					<feBlend mode='normal' in2='shape' result='effect1_innerShadow_2782_45775' />
				</filter>
			</defs>
		</svg>
	);
}
