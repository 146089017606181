import { useUserConfigStore } from '../stores/userConfigStore';
import { useState } from 'react';

export enum SetUserConfigValueState {
	Initial,
	Loading,
	Success,
	Error,
}

/* 
    Input:
    key - the config key name
    defaultValue (optional) - the default value to be used at the first time this config is used

    Output:
    array containing the following:
    0 - the value from the config
    1 - set function, similar to useState
    2 - the state of the value, will change after setting between "Initial", "Loading", "Success" and "Error"
    3 - error message, if exists
*/
export default function useUserConfigValue<S>(
	key: string,
	defaultValue?: S,
): [S, (newValue: S) => void, SetUserConfigValueState, string] | undefined {
	const userConfigStore = useUserConfigStore();

	const [state, setState] = useState<SetUserConfigValueState>(SetUserConfigValueState.Initial);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const setFunction = (value: S) => {
		setState(SetUserConfigValueState.Loading);
		setErrorMessage(null);
		userConfigStore.setValue(key, value, (isSuccess, errorMessage) => {
			if (isSuccess) {
				setState(SetUserConfigValueState.Success);
			} else {
				setState(SetUserConfigValueState.Error);
				setErrorMessage(errorMessage);
			}
		});
	};

	let returnValue: S;
	if (key in userConfigStore.valuesDictionary) {
		returnValue = userConfigStore.valuesDictionary[key];
	} else {
		returnValue = defaultValue;
	}

	return [returnValue, setFunction, state, errorMessage];
}
