import { SingleExpanderIcon } from './icons';
import styled from 'styled-components';

const IconContainer = styled.div<{ readonly $isReverse?: boolean }>`
	grid-row: 1;
	grid-column: ${(props) => (props.$isReverse ? 2 : 1)};
	cursor: pointer;
`;

const RotatingContainer = styled.div<{ readonly $isExpanded?: boolean }>`
	transform: rotate(${(props) => (props.$isExpanded ? 180 : 0)}deg);
	transition: transform 100ms linear;
`;

type Props = {
	iconStyle?: any;
	iconColor?: string;
	isHoverPrimary?: boolean;
	isReverse?: boolean;
	onExpanderClick?: VoidFunction;
	isExpanded?: boolean;
	isNewIcon?: boolean;
	iconWidth?: string;
	iconHeight?: string;
};

const CoreExpanderIcon = ({
	iconStyle,
	iconColor,
	isReverse,
	onExpanderClick,
	isExpanded,
	isNewIcon,
	iconWidth,
	iconHeight,
}: Props) => {
	return (
		<IconContainer style={iconStyle} onClick={onExpanderClick} $isReverse={isReverse}>
			<RotatingContainer $isExpanded={isExpanded}>
				<SingleExpanderIcon width={iconWidth} height={iconHeight} color={iconColor} isNewIcon={isNewIcon} />
			</RotatingContainer>
		</IconContainer>
	);
};

export default CoreExpanderIcon;
