export default function AgilonIcon() {
	return (
		<svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_7561_79595)'>
				<path
					d='M5.02393 19.8873C5.37472 19.5253 5.99124 19.1075 6.80384 18.6878C7.69738 18.2263 8.77488 17.7891 9.86811 17.4405C10.9625 17.0916 12.0568 16.8365 12.9842 16.7314C13.4479 16.6789 13.8591 16.6652 14.2016 16.6922C14.5498 16.7196 14.7947 16.7866 14.9492 16.8706C15.1661 16.9886 15.2888 17.1243 15.3613 17.2613C15.436 17.4024 15.4739 17.5762 15.4712 17.7901C15.4656 18.2348 15.2876 18.7661 15.05 19.3114C14.952 19.5365 14.8496 19.7513 14.7519 19.9564C14.7336 19.9949 14.7154 20.0332 14.6974 20.071C14.5884 20.3007 14.4805 20.532 14.4131 20.7261C14.3796 20.8228 14.347 20.9351 14.3348 21.0487C14.3239 21.1506 14.3193 21.3398 14.4372 21.5167C14.5755 21.7243 14.79 21.7856 14.9482 21.7913C15.0906 21.7965 15.2293 21.7615 15.3446 21.7227C17.1177 21.1521 18.8881 20.573 20.6584 19.994C21.6661 19.6644 22.6737 19.3348 23.6817 19.0069C24.1514 18.855 24.5467 18.8568 24.8564 18.9592C25.1601 19.0597 25.4208 19.2693 25.6118 19.6112C25.8439 20.03 25.9031 20.4226 25.8354 20.7709C25.7674 21.1204 25.5625 21.4718 25.1846 21.7896L25.1846 21.7896L25.1839 21.7902L25.1812 21.7925C24.6379 22.2554 24.0988 22.7146 23.5508 23.1635C23.5505 23.1637 23.5502 23.164 23.55 23.1642L23.8668 23.551C23.0755 24.1936 22.3156 24.8738 21.5345 25.5732C20.6911 26.3282 19.8228 27.1056 18.8632 27.8818C18.3105 28.3345 17.6705 28.5117 16.9723 28.5117H7.13003H5.02393V28.0117M5.02393 19.8873V28.0117M5.02393 19.8873V28.0117M5.02393 19.8873V28.0117'
					stroke='#322B5F'
				/>
				<path d='M2.625 18.2212V28.5467' stroke='#322B5F' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M22 12.525C22.1152 12.519 22.2321 12.5528 22.3288 12.6266L24.616 14.3714C25.0035 14.6669 25.5423 14.289 25.3969 13.8237L24.4705 10.8607C24.4087 10.6631 24.4753 10.4477 24.6378 10.319L27.0225 8.4313C27.3962 8.13552 27.1848 7.53491 26.7086 7.53946L23.8125 7.56715C23.5924 7.56925 23.397 7.4271 23.3313 7.21706L22.4345 4.34844C22.3664 4.13078 22.1871 4.01496 22 4V12.525Z'
					fill='#78F5CE'
				/>
				<path
					d='M22.9852 6.99317L23.4638 6.84839L22.9852 6.99318C23.1135 7.41824 23.5068 7.70758 23.9509 7.70356L27.4455 7.67198L24.5897 9.89378L24.8965 10.2883L24.5897 9.89378C24.2588 10.1512 24.1234 10.5875 24.2505 10.9868L25.3678 14.4965L22.5704 12.3983C22.2097 12.1278 21.7124 12.1323 21.3563 12.4093L18.5947 14.5578L19.6526 11.0283C19.773 10.6268 19.6303 10.193 19.2951 9.94164L16.4023 7.77179L19.9135 7.74006C20.3416 7.73619 20.7199 7.46022 20.8544 7.0537L21.9781 3.65763L22.9852 6.99317Z'
					stroke='#322B5F'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7561_79595'>
					<rect width='28' height='29' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
