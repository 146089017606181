import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function PatientPortraitSvg() {
	return (
		<svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.3138 13.1448C11.6245 12.6265 12.3755 12.6265 12.6862 13.1448L14.1456 15.5795C14.2132 15.6924 14.3076 15.7868 14.4205 15.8544L16.8552 17.3138C17.3735 17.6245 17.3735 18.3755 16.8552 18.6862L14.4205 20.1456C14.3076 20.2132 14.2132 20.3076 14.1456 20.4205L12.6862 22.8552C12.3755 23.3735 11.6245 23.3735 11.3138 22.8552L9.85442 20.4205C9.78678 20.3076 9.69237 20.2132 9.57954 20.1456L7.14477 18.6862C6.62649 18.3755 6.62649 17.6245 7.14476 17.3138L9.57954 15.8544C9.69237 15.7868 9.78678 15.6924 9.85442 15.5795L11.3138 13.1448Z'
				fill='url(#paint0_linear_3393_108908)'
			/>
			<path
				opacity='0.17'
				d='M6 6L7.64049 8.35951L10 10L7.64049 11.6405L6 14L4.35951 11.6405L2 10L4.35951 8.35951L6 6Z'
				fill='url(#paint1_linear_3393_108908)'
			/>
			<path
				opacity='0.3'
				d='M19 7L20.2304 8.76963L22 10L20.2304 11.2304L19 13L17.7696 11.2304L16 10L17.7696 8.76963L19 7Z'
				fill='url(#paint2_linear_3393_108908)'
			/>
			<path d='M18 35V41H37V35C37 32.7909 35.2091 31 33 31H22C19.7909 31 18 32.7909 18 35Z' fill='#78F5CE' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.5 26C29.9853 26 32 23.9853 32 21.5C32 19.0147 29.9853 17 27.5 17C25.0147 17 23 19.0147 23 21.5C23 23.9853 25.0147 26 27.5 26ZM27.5 28C31.0899 28 34 25.0899 34 21.5C34 17.9101 31.0899 15 27.5 15C23.9101 15 21 17.9101 21 21.5C21 25.0899 23.9101 28 27.5 28ZM40 22H36V20H40C42.2091 20 44 21.7909 44 24V40H49V42H43H42H38H17H12H7V40H11V27.5C11 26.9477 11.4477 26.5 12 26.5C12.5523 26.5 13 26.9477 13 27.5V40H17V35C17 32.2386 19.2386 30 22 30H33C35.7614 30 38 32.2386 38 35V40H42V24C42 22.8954 41.1046 22 40 22ZM36 40V35C36 33.3431 34.6569 32 33 32H22C20.3431 32 19 33.3431 19 35V40H36Z'
				fill='#453B87'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_3393_108908'
					x1='11.6'
					y1='0.857142'
					x2='11.6'
					y2='24'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_3393_108908'
					x1='5.73333'
					y1='-1.42857'
					x2='5.73333'
					y2='14'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_3393_108908'
					x1='18.8'
					y1='1.42857'
					x2='18.8'
					y2='13'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#7C66CF' />
					<stop offset='1' stopColor='#9F8FFF' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default function PatientPortraitIcon(props: Partial<CustomIconComponentProps>) {
	return <Icon component={PatientPortraitSvg} {...props} />;
}
