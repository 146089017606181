export const EPIC_CLIENT_TOKEN_ENDPOINT = 'epicTokenEndpoint';
export const EPIC_CLIENT_AUTH_URL = 'epicAuthUrl';
export const EPIC_CLIENT_AUD = 'epicAud';
export const EPIC_CLIENT_LAUNCH_ISS = 'epicLaunchIss';
const EPIC_LOGIN_SCOPES_ENCODED = 'openid%20profile%20email';
const EPIC_LOGIN_SCOPES = 'launch openid fhirUser profile';
export const OLD_EPIC_CLIENT_ID_STAGING = 'abfa3dbd-37bd-4f03-b121-6426ebe37039';
export const OLD_EPIC_CLIENT_ID_PRODUCTION = '4cd5b80a-f393-4e52-b292-8cb7173149e7';
export const EPIC_CLIENT_ID_STAGING = '89805fd1-b57a-4c40-a940-44f687e179b9';
export const EPIC_CLIENT_ID_PRODUCTION = '912d1aea-3a76-483f-a334-e7b1e645fcf5';
export const OLD_EPIC_CONNECTION_NAMES = ['nems', 'nems-staging', 'noms-staging', 'navina-fhir-connection'];
