import { MAX_WIDTH_LOWER_WIDTH } from '..';
import styled from 'styled-components';

const StyledSvg = styled.svg`
	width: 24px;
	height: 24px;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		width: 15px;
		height: 15px;
	}
`;

export default function SearchIcon() {
	return (
		<StyledSvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='10' cy='10' r='5.25' stroke='#322B5F' strokeWidth='1.5' />
			<line x1='13.5303' y1='14.4697' x2='17.8906' y2='18.8299' stroke='#322B5F' strokeWidth='1.5' />
		</StyledSvg>
	);
}
