import { CoreLoader } from '.';
import colors from './Colors';
import { type ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';

interface CoreButtonProps
	extends Omit<ComponentProps<typeof StyledButton>, 'onMouseEnter'>,
		Pick<ComponentProps<'div'>, 'onMouseEnter'> {
	color?: string;
	flexDirection?: string;
	tooltip?: string;
	isLoading?: boolean;
	readonly fontWeight?: string;
	readonly fontSize?: string;
	readonly width?: string;
	readonly height?: string;
	readonly display?: string;
	readonly $margin?: string;
}

const StyledContainer = styled.div<{
	width?: string;
	disabled?: boolean;
	$margin?: string;
	display?: string;
	flexDirection?: string;
	height?: string;
	fontSize?: string;
	fontWeight?: string;
}>`
	font-weight: ${(props) => props.fontWeight || 'bold'};
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'init'};
	display: ${(props) => props.display || 'flex'};
	flex-direction: ${(props) => props.flexDirection || 'row'};
	align-items: center;
	justify-content: center;
	margin: ${(props) => props.$margin || 'initial'};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	font-size: ${(props) => props.fontSize || '14px'};
	line-height: 17px;
	position: relative;
`;

const StyledButton = styled.button<{
	color?: string;
	$backgroundColor?: string;
	$hoverBackgroundColor?: string;
	$borderRadius?: string;
	opacity?: string;
	boxShadow?: string;
	$border?: string;
	$padding?: string;
	disabled?: boolean;
}>`
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: ${(props) => props.$backgroundColor || colors.accentLightGreen};
	border-radius: ${(props) => props.$borderRadius || '8px'};
	color: ${(props) => props.color || colors.primary};
	opacity: ${(props) => (props.disabled ? props.opacity || '0.5' : props.opacity || '1')};
	box-shadow: ${(props) => props.boxShadow || 'initial'};
	border: ${(props) => props.$border || '0'};
	font-weight: inherit;
	width: 100%;
	height: 100%;
	display: inherit;
	flex-direction: inherit;
	align-items: center;
	justify-content: center;
	cursor: inherit;
	font-size: inherit;
	line-height: inherit;
	padding: ${(props) => props.$padding || 'none'};
	min-height: 30px;
`;

const LoaderContainer = styled.div`
	position: absolute;
`;

const VisibilityContainer = styled.div<{ readonly $isVisible: boolean }>`
	visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
`;

export default forwardRef<HTMLDivElement, CoreButtonProps>(function CoreButton(
	{ children, color, isLoading = false, disabled = false, width, onClick, onMouseEnter, ...other },
	ref,
) {
	return (
		<StyledContainer
			width={width}
			disabled={isLoading || disabled}
			onMouseEnter={onMouseEnter}
			data-actionable
			ref={ref}
		>
			<StyledButton disabled={isLoading || disabled} color={color} onClick={onClick} {...other}>
				{isLoading && (
					<LoaderContainer>
						<CoreLoader />
					</LoaderContainer>
				)}
				<VisibilityContainer $isVisible={!isLoading}>{children}</VisibilityContainer>
			</StyledButton>
		</StyledContainer>
	);
});
