export default function ProblemListIcon({
	width,
	height,
	greenDotOn = true,
}: {
	readonly width?: string;
	readonly height?: string;
	readonly greenDotOn?: boolean;
}) {
	return (
		<svg
			width={width || '26'}
			height={height || '27'}
			viewBox='0 0 26 27'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8 13V22.2889C8 24.3385 9.6615 26 11.7111 26V26C13.7606 26 15.4221 24.3385 15.4221 22.2889V16.2889C15.4221 14.4725 16.8946 13 18.7111 13V13C20.5275 13 22 14.4725 22 16.2889V20'
				stroke='#322B5F'
				strokeWidth='2'
			/>
			{greenDotOn && <circle cx='22' cy='23' r='4' fill='#78F5CE' />}
			<circle cx='22' cy='23' r='3' stroke='#322B5F' strokeWidth='2' />
			<path
				d='M5 2H2C1.44772 2 1 2.44771 1 3V6.5C1 10.0899 3.91015 13 7.5 13V13C11.0899 13 14 10.0899 14 6.5V2.36364C14 2.16281 13.8372 2 13.6364 2H12C10.8954 2 10 2.89543 10 4V4V0M5 2V4M5 2V0'
				stroke='#322B5F'
				strokeWidth='2'
			/>
		</svg>
	);
}
