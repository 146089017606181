import { CoreText } from '../../../base-ui';
import AddonClickBehaviorChooser from './AddonClickBehaviorChooser';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledCaptionText = styled(CoreText)`
	width: 100%;
	margin: 12px 0 12px 0;
`;

export default observer(function UserSettings() {
	return (
		<Container>
			<CoreText type='body1BoldUnderline'>Settings</CoreText>
			<StyledCaptionText type='captionHeader'>Set how Navina will be opened from the EMR</StyledCaptionText>
			<AddonClickBehaviorChooser />
		</Container>
	);
});
