import type { ComponentProps } from 'react';
import styled from 'styled-components';

const MarginElement = styled.div<{ readonly $marginBottom?: string; readonly $marginTop?: string }>`
	margin-bottom: ${(props) => props.$marginBottom || 'none'};
	margin-top: ${(props) => props.$marginTop || 'none'};
`;

interface CoreMarginProps extends ComponentProps<typeof MarginElement> {
	readonly bottom?: string;
	readonly top?: string;
	readonly both?: string;
}

export default function CoreMargin({ bottom, top, both, ...props }: CoreMarginProps) {
	if (both) {
		bottom = both;
		top = both;
	}
	return <MarginElement $marginBottom={bottom} $marginTop={top} {...props} />;
}
