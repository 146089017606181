const reactAppStage = process.env.REACT_APP_STAGE;

if (!reactAppStage) {
	throw Error('REACT_APP_STAGE is not defined or empty');
}

export const ENVIRONMENT_NAME = reactAppStage;

const PRODUCTION_ENVIRONMENT_NAME = 'production';
export const IS_PRODUCTION = ENVIRONMENT_NAME === PRODUCTION_ENVIRONMENT_NAME;

const STAGING_ENVIRONMENT_NAME = 'staging';
export const IS_STAGING = ENVIRONMENT_NAME === STAGING_ENVIRONMENT_NAME;

const DEVELOPMENT_ENVIRONMENT_NAME = 'development';
export const IS_DEVELOPMENT = ENVIRONMENT_NAME === DEVELOPMENT_ENVIRONMENT_NAME;
