export default function AscvdIcon() {
	return (
		<svg width='5' height='47' viewBox='0 0 5 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect y='35' width='11' height='5' transform='rotate(-90 0 35)' fill='#ADA7D7' />
			<rect y='23' width='11' height='5' transform='rotate(-90 0 23)' fill='#F69412' />
			<path
				d='M2.5 47C1.11929 47 -4.89256e-08 45.8807 -1.09278e-07 44.5L-4.80825e-07 36L5 36L5 44.5C5 45.8807 3.88071 47 2.5 47Z'
				fill='#E0E0E0'
			/>
			<path
				d='M0 11L-3.71547e-07 2.5C-4.319e-07 1.11929 1.11929 -4.89256e-08 2.5 -1.09278e-07C3.88071 -1.69631e-07 5 1.11929 5 2.5L5 11L0 11Z'
				fill='#F24E1E'
			/>
		</svg>
	);
}
