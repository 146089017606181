import { getOrCreateApiGateway } from '../server/apiGateway';
import { getOrCreateLogger } from '../utils/logger';
import { type AuthStore } from './authStore';
import NavinaCache from './cache';
import { action, autorun, observable, runInAction } from 'mobx';
import { createContext, useContext } from 'react';

const VALUES_DICTIONARY_LOCAL_STORAGE_KEY = 'UserConfig';

type ValuesDictionaryType = {
	[key: string]: any;
};

class UserConfigStore {
	@observable valuesDictionary: ValuesDictionaryType = {};

	constructor(authStore: AuthStore | undefined) {
		if (!authStore) {
			getOrCreateLogger().error('no authStore, scheduleStore initialization failed');
			return;
		}
		autorun((): void => {
			if (!authStore.getToken || window.location.pathname.includes('epic/login')) {
				console.log('no token, user not yet authenticated');
				return;
			}

			const userConfigStr = NavinaCache.get(VALUES_DICTIONARY_LOCAL_STORAGE_KEY);
			if (userConfigStr) {
				this.parseUserConfig(userConfigStr);
			}

			getOrCreateApiGateway()
				.getUserConfig()
				.then((userConfigResponse): void => {
					if (userConfigResponse?.data?.result) {
						runInAction((): void => {
							this.valuesDictionary = userConfigResponse?.data?.result;
							NavinaCache.set(VALUES_DICTIONARY_LOCAL_STORAGE_KEY, JSON.stringify(this.valuesDictionary));
						});
					} else {
						console.warn('Unable to retrieve user config', userConfigResponse);
					}
				})
				.catch((err): void => console.warn('Error retrieving user config', err));
		});
	}

	@action setValuesDictionary = (
		valuesDictionary: ValuesDictionaryType,
		callback?: (isSuccess: boolean, errorMessage?: string) => void,
	): void => {
		const oldValues = { ...this.valuesDictionary };

		this.valuesDictionary = valuesDictionary;

		NavinaCache.set(VALUES_DICTIONARY_LOCAL_STORAGE_KEY, JSON.stringify(valuesDictionary));

		getOrCreateApiGateway()
			.updateUserConfig(valuesDictionary)
			.then((response): void => {
				callback && callback(!!response?.data?.result);
			})
			.catch((error): void => {
				console.warn('Unable to set user config', error);
				callback(false, error.message || error);
				runInAction((): void => {
					this.valuesDictionary = oldValues; // revert
					NavinaCache.set(VALUES_DICTIONARY_LOCAL_STORAGE_KEY, JSON.stringify(oldValues));
				});
			});
	};

	@action setValue = (
		key: string,
		value: any,
		callback?: (isSuccess: boolean, errorMessage?: string) => void,
	): void => {
		const newValuesDictionary = { ...this.valuesDictionary };

		newValuesDictionary[key] = value;

		this.setValuesDictionary(newValuesDictionary, callback);
	};

	@action parseUserConfig = (userConfigStr: string): void => {
		try {
			if (userConfigStr) {
				this.valuesDictionary = JSON.parse(userConfigStr);
			}
		} catch (err) {
			console.error('Error parsing user config', userConfigStr);
		}
	};
}

export const UserConfigStoreContext = createContext<UserConfigStore>(null);
export const useUserConfigStore = (): UserConfigStore => useContext(UserConfigStoreContext);
export default UserConfigStore;
