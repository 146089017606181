import { type CoreTextType, MAX_WIDTH_LOWER_WIDTH, type TextColorType } from '.';
import CoreText from './CoreText';
import type { ComponentProps, ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div<{
	readonly $margin?: string;
	readonly $alignItems?: string;
	readonly lowResMargin?: string;
}>`
	display: flex;
	flex-direction: row;
	align-items: ${(props) => props.$alignItems};
	width: fit-content;
	margin: ${(props) => props.$margin || '20px 30px 20px 0'};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		margin: ${(props) => props.lowResMargin || '10px 30px 20px 0'};
	}
`;

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 0 0 30px;
`;

interface CoreTitleWithIconProps extends Omit<ComponentProps<typeof Container>, 'alignItems'> {
	Icon: ComponentType<{ readonly width?: string; readonly height?: string }>;
	$margin?: string;
	lowResMargin?: string;
	text?: string;
	subtitle?: ReactNode;
	children?: ReactNode;
	iconWidth?: string;
	iconHeight?: string;
	titleColor?: TextColorType;
	textType?: CoreTextType;
	$iconSize?: string;
}

export default function CoreTitleWithIcon({
	Icon,
	iconWidth,
	iconHeight,
	text,
	subtitle,
	children,
	titleColor = 'primary',
	textType = 'title3BoldUppercase',
	...other
}: CoreTitleWithIconProps) {
	return (
		<Container $alignItems={subtitle ? 'flex-start' : 'center'} {...other}>
			<Icon width={iconWidth} height={iconHeight} />
			<ColumnContainer>
				<CoreText type={textType} fsExclude={false} color={titleColor}>
					{text || children}
				</CoreText>
				{subtitle}
			</ColumnContainer>
		</Container>
	);
}
