export default function AscvdEmptyIcon() {
	return (
		<svg width='5' height='47' viewBox='0 0 5 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2.5 46.5C1.39543 46.5 0.5 45.6046 0.5 44.5L0.499998 2.5C0.499998 1.39543 1.39543 0.5 2.5 0.5C3.60457 0.5 4.5 1.39543 4.5 2.5L4.5 44.5C4.5 45.6046 3.60457 46.5 2.5 46.5Z'
				stroke='#ADA7D7'
			/>
		</svg>
	);
}
