import { CoreContainer, CoreTitleWithIcon, CoreText, MAX_WIDTH_LOWER_WIDTH } from '.';
import { Tooltip } from 'antd';
import { type ComponentType, forwardRef, type ReactNode } from 'react';
import styled from 'styled-components';

type CoreMedFeatureProps = {
	children: ReactNode;
	Icon: ComponentType;
	name: string;
	isEmpty: boolean;
	tooltip?: string;
	emptyFeatureString?: string;
	fullListButton?: ComponentType;
	inAppExplanation?: ReactNode;
	subtitle?: ReactNode;
};

const StyledDataContainer = styled.div`
	padding: 0 0 0 60px;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding: 0 20px 0 0;
	}
`;

const StyledCoreContainer = styled(CoreContainer)`
	margin: 10px;
	padding: 30px;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding: 10px 20px 20px 20px;
	}
`;

export default forwardRef<HTMLDivElement, CoreMedFeatureProps>(function CoreMedFeature(
	{
		children,
		Icon,
		name,
		isEmpty,
		tooltip: tooltipText,
		emptyFeatureString,
		fullListButton,
		inAppExplanation,
		subtitle,
		...other
	},
	ref,
) {
	return (
		<StyledCoreContainer ref={ref} inAppExplanation={subtitle ? null : inAppExplanation} {...other}>
			<>
				<Tooltip title={tooltipText || null}>
					<CoreTitleWithIcon Icon={Icon} subtitle={subtitle}>
						{name}
					</CoreTitleWithIcon>
				</Tooltip>
			</>
			{isEmpty ? (
				<CoreText $padding='0 0 0 61px' type='body1'>
					{emptyFeatureString || 'None on file'}
				</CoreText>
			) : (
				<StyledDataContainer>{children}</StyledDataContainer>
			)}
			{fullListButton || null}
		</StyledCoreContainer>
	);
});
