import styled from 'styled-components';

const ShadowSvg = styled.svg<{ readonly width?: string; readonly height?: string; readonly $isPressed?: boolean }>`
	filter: ${(props) => (props.$isPressed ? 'none' : 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))')};
`;

export default function RejectIcon({
	width = '25',
	height = '24',
	isPressed = false,
	stroke = true,
}: {
	width?: string;
	height?: string;
	isPressed?: boolean;
	stroke?: boolean;
}) {
	return (
		<ShadowSvg
			$isPressed={isPressed}
			width={width}
			height={height}
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			{isPressed ? (
				<>
					<rect
						x='0.75'
						y='0.75'
						width='23.2619'
						height='21.6111'
						rx='10.8056'
						fill='#C2BFBF'
						stroke='#C2BFBF'
						strokeWidth='1.5'
					/>
					<path d='M15.2698 14.4445L9.49206 8.66669' stroke='#828080' strokeWidth='1.5' />
					<path d='M9.49207 14.4445L15.2698 8.66669' stroke='#828080' strokeWidth='1.5' />
					<path
						d='M6.60315 5.77777V3.77777H4.60315V5.77777H6.60315ZM18.1587 5.77777H20.1587V3.77777H18.1587V5.77777ZM18.1587 17.3333V19.3333H20.1587V17.3333H18.1587ZM6.60315 17.3333H4.60315V19.3333H6.60315V17.3333ZM6.60315 7.77777H18.1587V3.77777H6.60315V7.77777ZM16.1587 5.77777V17.3333H20.1587V5.77777H16.1587ZM18.1587 15.3333H6.60315V19.3333H18.1587V15.3333ZM8.60315 17.3333V5.77777H4.60315V17.3333H8.60315Z'
						fill='#C2BFBF'
					/>
				</>
			) : (
				<>
					<rect
						x='0.75'
						y='0.75'
						width='23.2619'
						height='21.6111'
						rx='10.8056'
						fill='white'
						stroke='#ADA7D7'
						strokeWidth={stroke ? '1.5' : 0}
					/>
					<path d='M15.2699 14.4444L9.49212 8.66666' stroke='#53479E' strokeWidth='1.5' />
					<path d='M9.49207 14.4444L15.2698 8.66666' stroke='#53479E' strokeWidth='1.5' />
				</>
			)}
		</ShadowSvg>
	);
}
