export default function ClinicalAlertsTabIcon({
	width = '26',
	height = '28',
	greenDotOn = true,
}: {
	width?: string;
	height?: string;
	greenDotOn?: boolean;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.217 18.9912C12.217 18.9911 12.2179 18.9909 12.2195 18.9906L12.217 18.9912ZM16.5849 18.9158C17.3404 18.6844 18.0958 18.4531 18.851 18.223C19.4787 18.0331 19.8565 18.2158 20.052 18.543C20.1987 18.791 20.2233 18.9988 20.1884 19.1666C20.1528 19.3379 20.0406 19.537 19.7894 19.7346L19.7894 19.7346L19.7862 19.7371C19.786 19.7373 19.7859 19.7374 19.7857 19.7375C19.3782 20.0625 18.9759 20.3834 18.5672 20.6968C17.9624 21.1567 17.383 21.6424 16.8005 22.1307C16.7758 22.1513 16.7512 22.172 16.7266 22.1926C16.1155 22.7048 15.4963 23.2216 14.8164 23.7364L14.8164 23.7364L14.8135 23.7387C14.5272 23.9583 14.185 24.0571 13.7605 24.0571H6.37878H5.02419V18.7177C5.26999 18.5055 5.67646 18.2515 6.22326 17.987C6.8815 17.6687 7.67888 17.3656 8.49004 17.1234C9.30242 16.8809 10.1105 16.705 10.7905 16.6329C11.1305 16.5968 11.4263 16.5881 11.6678 16.6059C11.9164 16.6242 12.0695 16.668 12.15 16.7091L12.3904 16.2371L12.15 16.7091C12.2808 16.7757 12.3349 16.8413 12.3614 16.8882C12.3898 16.9384 12.4112 17.0113 12.4097 17.1248C12.4063 17.3755 12.2952 17.7043 12.1186 18.0838C12.0471 18.2373 11.9727 18.3836 11.8995 18.5276C11.8856 18.5549 11.8717 18.5822 11.8579 18.6093C11.7775 18.7679 11.6895 18.9438 11.6328 19.0967C11.6047 19.1724 11.5719 19.2745 11.5591 19.3857C11.5478 19.4845 11.5394 19.6976 11.6824 19.8985C11.8448 20.1268 12.0846 20.1817 12.2356 20.1869C12.3747 20.1916 12.5035 20.1599 12.5991 20.1298C13.9289 19.7291 15.2572 19.3223 16.5849 18.9158Z'
				stroke='#322B5F'
				strokeWidth='1.2'
			/>
			<path d='M3 17.4313V24.6818' stroke='#322B5F' strokeWidth='1.2' />
			{greenDotOn && <ellipse cx='16.7812' cy='9.09095' rx='3.9375' ry='3.81818' fill='#78F5CE' />}
			<ellipse cx='16.7812' cy='9.09091' rx='5.25' ry='5.09091' stroke='#322B5F' />
			<path d='M16.7812 10.3635L16.7813 11.6362' stroke='#322B5F' />
			<path d='M16.7812 6.54529L16.7813 9.72711' stroke='#322B5F' />
		</svg>
	);
}
