export default function AlertIcon({ width = 16, padding = '' }) {
	return (
		<svg
			style={padding ? { padding } : null}
			width={width}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='8' cy='8' r='7' fill='#F24E1E' />
			<path d='M8 10L8 12' stroke='white' strokeWidth='2' />
			<path d='M8 4L8 9' stroke='white' strokeWidth='2' />
		</svg>
	);
}
