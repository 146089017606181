export const NEXT_URL_QUERY_PARAMS = 'nextUrl';
export const SSO_URL_QUERY_PARAMS = 'ssoUrl';
export const SMART_ON_FHIR_LUNCH_TIME = 'SmartOnFhirLunchTime';
export const AUTH0_REDIRECT_BACK_TIME = 'Auth0redirectBackTime';
export const REDIRECTING_TO_AUTH0_TIME = 'redirectingToAuth0Time';
export const REDIRECTING_TO_EPIC_TIME = 'redirectingToEpicTime';
export const REQUEST_TIME_GET_USER_META_DATA = 'requestTimeGetUserMetaData';
export const RESPONSE_TIME_GET_USER_META_DATA = 'responseTimeGetUserMetaData';
export const REDIRECTING_TO_SUMMARY_TIME = 'redirectingToSummaryTime';
export const AUTH_0_TOKEN = 'auth0Token';
export const CREATE_VERADIGM_UNITY_JWT_TOKEN = 'createVeradigmUnityJWT';
const DOCUMENT_ID = 'nvn_appContext';
const ENCOUNTER_ID = 'nvn_encounter_id';
export const USER_METADATA = 'userMetadata';
export const UNITY_ENCOUNTER_ID = 'unityEncounterId';
export const AUTH_0_CONNECTION_NAME = 'nvnAuth0ConnectionName';
export const GET_EPIC_TOKEN_AND_APP_CONTEXT_REQUEST_TIME = 'getEpicTokenAndAppContextRequestTime';
export const GET_EPIC_TOKEN_AND_APP_CONTEXT_RESPONSE_TIME = 'getEpicTokenAndAppContextResponseTime';
export const EPIC_LOGIN_DOC_REDIRECT_TIME = 'nvn_opened_doc_time';
export const REQUEST_TO_AUTH0_TOKEN_TIME = 'nvnRequestToAuth0TokenTime';
export const RESPONSE_FROM_AUTH0_TOKEN_TIME = 'nvnResponseFromAuth0TokenTime';
