export enum NavinaLoginStep {
	PasswordLogin = 'PasswordLogin',
	ChangePasswordAlert = 'ChangePasswordAlert',
	ChangePassword = 'ChangePassword',
	ChangePasswordDone = 'ChangePasswordDone',
	RecoverPasswordSetEmail = 'RecoverPasswordSetEmail',
	RecoverPasswordSetEmailDone = 'RecoverPasswordSetEmailDone',
	PasswordResetForOldPassword = 'PasswordResetForOldPassword',
	RecoverPassword = 'RecoverPassword',
	RecoverPasswordDone = 'RecoverPasswordDone',
	TwoStepVerification = 'TwoStepVerification',
	ReadTermsAndConditions = 'ReadTermsAndConditions',
	InitializePassword = 'InitializePassword',
}
