export default function PlusIcon({ width, height }: { readonly width?: string; readonly height?: string }) {
	return (
		<svg
			width={width || '13'}
			height={height || '12'}
			viewBox='0 0 13 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.24033 0C5.71014 0 5.28033 0.429806 5.28033 0.959999V4.80001H0.96C0.429806 4.80001 0 5.22982 0 5.76001C0 6.29021 0.429805 6.72001 0.959998 6.72001H5.28033V11.04C5.28033 11.5702 5.71014 12 6.24033 12C6.77052 12 7.20033 11.5702 7.20033 11.04V6.72001H11.52C12.0502 6.72001 12.48 6.29021 12.48 5.76001C12.48 5.22982 12.0502 4.80001 11.52 4.80001H7.20033V0.96C7.20033 0.429806 6.77052 0 6.24033 0Z'
				fill='#322B5F'
			/>
		</svg>
	);
}
